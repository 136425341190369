import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get channel
   *
   * @param subDomain
   */
  getChannel(subDomain) {
    return AxiosService.callApiPublic().post('/public/channel', subDomain)
  },
  /**
   * Check if user exist with his email
   * @param email
   * @returns {*}
   */
  checkUserByEmail(email) {
    return AxiosService.callApiPublic().post('/public/check-user-mail', email)
  },
  /**
   * Get user form token (mail forgot password OR create by shop)
   *
   * @param token
   * @param id
   * @param data
   * @returns {*}
   */
  getPublicUser(token, id, data) {
    return AxiosService.callApiPublic().get('/public/user/' + token + '/' + id, data)
  },
  /**
   * Send mail for forgot password
   *
   * @returns {*}
   */
  sendForgotPassword(id) {
    return AxiosService.callApiPublic().get('/public/send-forgot-password/' + id)
  },
  /**
   * Update password
   *
   * @param token
   * @param id
   * @param data
   * @returns {*}
   */
  updatePassword(token, id, data) {
    return AxiosService.callApiPublic().post('/public/user/update-password/' + token + '/' + id, data)
  }
}

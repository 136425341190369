<template>
  <div class="page-company-show">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block app-box-shadow col-10 mx-auto bg-white">
        <div class="p-3 d-md-flex justify-content-between">
          <div class="w-100">
            <h5 class="w-100 mx-auto text-center mb-4 text-primary"><strong>{{ CompanyModule.company.name }}</strong></h5>
          </div>
          <div>
            <router-link
                class="btn btn-outline-primary mt-2 mt-md-0"
                :to="{ name: 'company-list' }"
            >
              Retour
            </router-link>
          </div>
        </div>
      </div>
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Nom & mail</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Nom</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.name }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Email</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.email }}</span>
          </div>
        </div>
      </div>
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Téléphone & localisation</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Nom</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.name }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Email</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.email }}</span>
          </div>
        </div>
      </div>
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Téléphone & localisation</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Téléphone</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.phone }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Adresse</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.address }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Complément d'adresse </strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.address2 }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Ville</strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.city }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Code postal </strong> : </span>
            <span class="ml-2">{{ CompanyModule.company.zipCode }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      dataPage: {
        title: 'Détails de l\'entreprise : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchCompany', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 300)
      })
  },
  computed: {
    ...mapState(['CompanyModule'])
  },
  components: {
  },
}
</script>

<style scoped>

</style>

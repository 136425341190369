<template>
  <div id="page-export">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon>
        {{ dataPage.title }}
      </h3>

      <div>
        <button class="btn btn-outline-danger mt-2" v-on:click="resetConf">
          Réinitialiser
        </button>
      </div>
    </div>

    <hr />

    {{/* BLOCK NAV TABS */}}
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 0) ? 'active' : ''" v-on:click="handleTabIndex(0)">
          <div class="mt-1 p-1">
            Configuration Export
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 1) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(1)">
          <div v-if="html.cat !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-flux">Rendu Visuel de l'export Statistiques (Flux)</div>
            <div class="">
              <button
                  class="btn btn-sm bg-tabs-flux ml-3"
                  v-on:click="resetConfExportFlux()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 2) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(2)">
          <div v-if="html.statsCa !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-stats">Rendu Visuel de l'export Statistiques (CA)</div>
            <div class="">
              <button
                  class="btn btn-sm bg-tabs-stats ml-3"
                  v-on:click="resetConfExportStatsCa()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
      </div>
    </nav>
    {{/* TAB CONTENT */}}
    <div class="tab-content" id="nav-tabContent">
      <b-card class="border-top-0" no-body>
        {{/* BLOCK CONFIG */}}
        <div class="fade" :class="(tabs.tabIndex === 0) ? 'show active' : ''" v-show="tabs.tabIndex === 0">
          <div class="config-tab">
            <b-alert v-if="configExport.showTabs" show variant="info" class="ml-4 mr-4 mt-4 mb-4">
              <h5 class="alert-heading">Changement de configuration</h5>
              <hr />
              <p>
                <b>Attention :</b> si vous changez la configuration,
                <br />
                Vous avez la possibilité de recharger chaque rendu visuel en cliquant sur leur boutton respectif,
                <br />
                présent dans les onglets au dessus.
                <button class="btn btn-sm btn-secondary ml-2">
                  <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
                </button>
              </p>

              <hr />
              <b>Sinon :</b> cliquez sur ce boutton pour recharger les rendus visuels
              <button class="btn btn-sm btn-primary ml-3" v-on:click="changeConfig">
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon> Recharger les rendu visuels ?
              </button>
            </b-alert>

            <div class="mt-3 row">
              <div class="col-12 col-md-4 col-lg-3 p-0 pl-3 mb-2 mx-auto">
                <div class="p-2">
                  <div class="">
                    <div>
                      <Datepicker
                          input-class="w-100 mb-2 text-center date-select"
                          id="date-start"
                          v-model="configExport.date"
                          :format="formatDatepicker"
                          :minimumView="'year'"
                          :maximumView="'year'"
                          :language="datepickerLanguage"
                          @input="configExport.date = fixDate($event)"
                          placeholder="Choisir une année"
                      >
                      </Datepicker>
                    </div>

                    {{/* CHOOSE OPTIONS */}}
                    <div v-if="configExport.date">
                      <hr class="mb-3"/>

                      <div>
                        <h5 class="text-center">
                          Voulez vous choisir une période spécifique pour le comparatif ?
                        </h5>
                      </div>
                      <div class="form-group">
                        <div class="d-flex justify-content-center mt-3">
                          <input class="mt-1" type="radio" :value="true" id="is-options-yes" v-model="configExport.options">
                          <label for="is-options-yes" class="ml-2">Oui</label>
                          <input class="mt-1 ml-4" type="radio" :value="false" id="is-options-no" v-model="configExport.options">
                          <label for="is-options-no" class="ml-2">Non</label>
                        </div>
                      </div>
                    </div>

                    {{/* CHOOSE COMPARATIVE DATE */}}
                    <div class="form-group" v-if="configExport.date && configExport.options === true">
                      <Datepicker
                          input-class="w-100 date-select"
                          id="date-comparative-date"
                          v-model="configExport.comparativeDate"
                          :format="formatDatepicker"
                          :minimumView="'year'"
                          :maximumView="'year'"
                          :language="datepickerLanguage"
                          @input="configExport.comparativeDate = fixDate($event)"
                          placeholder="Choisir une année"
                      >
                      </Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {{/* BLOCK FLUX */}}
        <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 1) ? 'show active' : ''" v-show="tabs.tabIndex === 1">
          <div class="text-center mt-2" v-if="html.flux === '' || tabs.isLoadingFlux">
            <div class="mt-5">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
          </div>
          <div v-else>
            <div class="response-api-html-export-flux" v-show="!tabs.isLoadingFlux" v-on:keyup="changeTempInput">
              <div v-html="html.flux">
              </div>
            </div>

            {{ /* APEX CHART */ }}
            <div class="d-flex justify-content-center flex-wrap mb-5 pl-5 pr-5" v-show="!tabs.isLoadingFlux">
              <div
                  v-for="(center, index) in CenterModule.centers"
                  :key="index"
              >
                <ApexChart
                    :id="'chart-flux-' + center.id"
                    :ref="'chartFlux'"
                    height="400"
                    width="700"
                    :options="(apexChartFlux[index]) ? apexChartFlux[index].options : []"
                    :series="(apexChartFlux[index]) ? apexChartFlux[index].series : []"
                    :key="center.id"
                ></ApexChart>
              </div>
            </div>

            <div class="response-api-html-export-flux-temp d-none">
              <div v-html="html.fluxTemp">
              </div>
            </div>
          </div>
        </div>
        {{/* BLOCK STATS CA */}}
        <div class="tab-pane mb-5 fade" :class="(tabs.tabIndex === 2) ? 'show active' : ''" v-show="tabs.tabIndex === 2">
          <div class="text-center mt-2" v-if="html.statsCa === '' || tabs.isLoadingStatsCa">
            <div class="mt-5">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
          </div>
          <div v-else>
            <div class="response-api-html-export-stats" v-show="!tabs.isLoadingStatsCa" v-on:keyup="changeTempInput">
              <div v-html="html.statsCa">
              </div>
            </div>

            {{ /* APEX CHART */ }}
            <div class="d-flex justify-content-center mb-5 pl-5 pr-5" v-show="!tabs.isLoadingStatsCa">
              <div
                  v-for="(center, index) in CenterModule.centers"
                  :key="index"
              >
                <ApexChart
                    :id="'chart-stats-' + center.id"
                    :ref="'chartStats'"
                    height="400"
                    width="700"
                    :options="(apexChartStats[index]) ? apexChartStats[index].options : []"
                    :series="(apexChartStats[index]) ? apexChartStats[index].series : []"
                    :key="center.id + 99"
                ></ApexChart>
              </div>
            </div>

            <div class="response-api-html-export-stats-temp d-none">
              <div v-html="html.statsCaTemp">
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>

    {{ /* EXPORT */ }}
    <div class="container-button-csv app-box-shadow p-4" v-if="configExport.isOk">
      <div class="d-flex justify-content-start">
        <div v-if="html.isHtmlFluxGenerate" class="w-100">
          <div class="d-flex justify-content-start">
            <div>
              <button
                  class="btn btn-lg text-white bg-tabs-flux"
                  :class="(html.isHtmlFluxGenerate && html.isHtmlStatCaGenerate)
                    ? ''
                    : 'export-disabled'"
                  v-on:click="sendHtmlExportFlux"
              >
                <template v-if="html.isHtmlFluxGenerate && html.isHtmlStatCaGenerate">
                  Générer le PDF Statistique (Flux)
                </template>
                <template v-else>
                  <b-spinner small></b-spinner>
                  <span class="ml-4">Chargement ...</span>
                </template>
              </button>
              <button
                  class="btn btn-lg text-white bg-tabs-stats ml-2"
                  :class="(html.isHtmlFluxGenerate && html.isHtmlStatCaGenerate)
                    ? ''
                    : 'export-disabled'"
                  v-on:click="sendHtmlExportStatsCa()"
              >
                <template v-if="html.isHtmlFluxGenerate && html.isHtmlStatCaGenerate">
                  Générer le PDF statistique (CA)
                </template>
                <template v-else>
                  <b-spinner small></b-spinner>
                  <span class="ml-4">Chargement ...</span>
                </template>
              </button>
            </div>
          </div>
        </div>
        <div v-else class="w-100 d-flex justify-content-start">
          <button
              class="btn btn-lg btn-success ml-2"
              v-on:click="eventClickButtonMadeVisual()"
          >
            Voir le rendu visuel
          </button>

          <div>
            <button
                class="btn btn-lg btn-info ml-4"
                v-on:click="eventClickExportCsv('flux')"
            >
              Export CSV (Flux)
            </button>

            <button
                class="btn btn-lg btn-info ml-4"
                v-on:click="eventClickExportCsv('stats')"
            >
              Export CSV (Stats)
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import moment from 'moment'
import exportService from '../../../services/ExportService'
import axios from 'axios'
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
  data() {
    return {
      html: {
        flux: '',
        fluxTemp: '',
        statsCa: '',
        statsCaTemp: '',
        isHtmlFluxGenerate: false,
        isHtmlStatCaGenerate: false,
      },
      tabs: {
        tabIndex: 0,
        isLoadingFlux: false,
        isLoadingStatsCa: false
      },
      apexChartFlux: [],
      apexChartStats: [],
      datepickerLanguage: fr,
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      configExport: {
        date: null,
        formatDate: 'MM/yyyy',
        isOk: false,
        showTabs: false,
        options: null,
        comparativeDate: null
      },
      dataPage: {
        title: 'Export(s) Statistique',
      }
    }
  },
  created() {
    this.$store.dispatch('fetchCenters', {
      perPage: null,
      page: null
    })
        .then((response) => {
          response.forEach((center) => {
            this.apexChartFlux.push(this.generateChart('flux', center, []))
            this.apexChartStats.push(this.generateChart('stats', center, []))
          })
        })
  },
  updated() {
  },
  methods: {
    formatDatepicker(date) {
      return moment(date).format('yyyy');
    },
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT DATE ------------------*/
    fixDate(event) {
      return moment(event).format('YYYY-MM-') + moment(event).daysInMonth()
    },
    handleDateConf() {
      if(this.configExport.startDate != null)
        this.configExport.startDate = moment(this.configExport.startDate).format('yyyy-MM-DD')
    },
    /*----------------- MADE VISUAL ------------------*/
    /**
     * Event click button made visual
     */
    eventClickButtonMadeVisual()
    {
      // Show tabs
      this.configExport.showTabs = true
      // Handle Flux
      this.validateConfExportFlux()
      // Handle Stats Ca
      this.validateConfExportStatsCa()
    },
    /*----------------- CSV ------------------*/
    async eventClickExportCsv(typeCsv) {
      // Display loader
      this.showLoader()

      let model = {
        conf: this.configExport,
        shopsId: this.listSelectableShop
      }

      // Var
      let dispatchCsvType = (typeCsv === 'flux') ? 'csvFluxExport' : 'csvStatsExport'
      let fileName = (typeCsv === 'flux') ? 'flux_export_' : 'stats_export_'

      // Fetch Export CSV
      await this.$store.dispatch(dispatchCsvType, model)
        .then((response) => {
          if (response) {
            fileName = fileName +  moment().format('MM-YY')
            exportService.triggerFileDownload(response, fileName, 'text/csv')
          }

          this.hideLoader()
        })
        .catch((error) => {
          this.hideLoader()

          this.$bvToast.toast(error.message, {
            title: 'ERREUR !',
            variant: 'danger',
            solid: true
          })
        })
    },
    /*----------------- VALIDATE CONF ------------------*/
    /**
     * Click on button validate conf export (flux)
     */
    async validateConfExportFlux() {
      // Change format date
      this.handleDateConf()
      // Loader
      this.showLoader()
      // Get HTML from API
      await exportService.getHtmlExportFlux({conf : this.configExport})
          .then((response) => {
            // Set is Generate
            this.html.isHtmlFluxGenerate = true
            // Get in var HTML
            this.html.flux = response.data
            this.html.fluxTemp = response.data
            // Handle tabs index
            this.tabs.tabIndex = 1
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
      if (this.html.isHtmlFluxGenerate) {
        // Charts
        this.handleChartsFlux()
      }
    },
    /**
     * Click on button validate conf export (stats CA)
     */
    async validateConfExportStatsCa() {
      // Change format date
      this.handleDateConf()
      // Loader
      this.showLoader()
      // Get HTML from API
      await exportService.getHtmlExportStatsCa({conf : this.configExport})
          .then((response) => {
            // Set is Generate
            this.html.isHtmlStatCaGenerate = true
            // Get in var HTML
            this.html.statsCa = response.data
            this.html.statsCaTemp = response.data
            // Handle tabs index
            this.tabs.tabIndex = 1
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
      if (this.html.isHtmlStatCaGenerate) {
        // Series chart
        this.handleChartsStats()
      }
    },
    /*----------------- SEND HTML ------------------*/
    /**
     * Send the new html to API
     */
    async sendHtmlExportFlux() {
      // Handle tab
      await this.handleTabIndex(1)
      // Loader
      this.showLoader()
      // Timeout for waiting html to display, for this.$refs
      setTimeout( async ()=> {
        // Change input to span
        let newHtml = this.changeInputToSpanJquery('response-api-html-export-flux');
        // CHANGE APEX CHART TO BASE 64
        for (let i = 0; i < this.CenterModule.centers.length; i++) {
          this.apexChartFlux[i].base64 = await this.$refs.chartFlux[i].chart.dataURI().then(uri => {
            return uri
          })
          $('.response-api-html-export-flux-temp .chart .chart-'+ (i + 1)).html(
              '<img src="'+ this.apexChartFlux[i].base64.imgURI +'" />'
          )
        }
        exportService.sendHtmlExportFlux(newHtml.html())
            .then((response) => {
              const fileName = 'statistiques_flux_' + moment().format('MM-YY')
              exportService.triggerFileDownload(response.data, fileName, 'application/pdf')
            }).then(() => {
              // Reset html
              $('.response-api-html-export-flux-temp').html($('.response-api-html-export-flux').html())
              this.hideLoader()
            })
            .catch((error) => {
              // Loader
              this.hideLoader()
            })
      }, 2000)
    },
    async sendHtmlExportStatsCa() {
      // Handle tab
      await this.handleTabIndex(2)
      // Loader
      this.showLoader()
      // Timeout for waiting html to display, for this.$refs
      setTimeout( async ()=> {
        // Change input to span
        let newHtml = this.changeInputToSpanJquery('response-api-html-export-stats');
        // CHANGE APEX CHART TO BASE 64
        for (let i = 0; i < this.CenterModule.centers.length; i++) {
          this.apexChartStats[i].base64 = await this.$refs.chartStats[i].chart.dataURI().then(uri => {
            return uri
          })
          $('.response-api-html-export-stats-temp .chart .chart-'+ (i + 1)).html(
              '<img src="'+ this.apexChartStats[i].base64.imgURI +'" />'
          )
        }
        exportService.sendHtmlExportStatsCa(newHtml.html())
            .then((response) => {
              const fileName = 'statistiques_ca_' + moment().format('MM-YY')
              exportService.triggerFileDownload(response.data, fileName, 'application/pdf')
            })
            .then(() => {
              // Reset html
              $('.response-api-html-export-stats-temp').html($('.response-api-html-export-stats').html())
              this.hideLoader()
            })
            .catch((error) => {
              // Loader
              this.hideLoader()
            })
      }, 2000)
    },
    /*----------------- JQUERY ------------------*/
    /**
     * Change all input on html request
     */
    changeInputToSpanJquery(idDivExport)
    {
      // INPUT HTML VUE
      let listInput = []
      $('.'+ idDivExport + ' .temp-input').each(function() {
        listInput.push($(this))
      })
      // TEXTAREA HTML
      let listTextArea = []
      $('.'+ idDivExport + ' .temp-textarea').each(function() {
        listTextArea.push($(this))
      })
      // SELECT
      let selectTemp = $('.'+ idDivExport + ' .temp-select')
      // INPUT TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-input').each(function(indexI) {
        var getClassCss = $(this).attr('class')
        // Handle value negative
        if(idDivExport === 'response-api-html-export-stats' && listInput[indexI].val() < 0) {
          getClassCss += ' text-red'
        }
        var dataType = $(this).attr('data-type')

        // INPUT
        $(this).parent().html("<span class='new-span-input text-center' style='width: 95%'>" + listInput[indexI].val() + "</span>")
        $('.new-span-input').each(function() {
          $(this).attr('class', getClassCss)
          if(dataType && dataType === 'percent' && listInput[indexI].val() && listInput[indexI].val() !== 0) {
            $(this).after("<span class='"+ getClassCss +"'> %</span>")
          }
        })
      })
      // TEXTAREA TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-textarea').each(function(indexT) {
        var getClassCss = $(this).attr('class')
        if(listTextArea[indexT] !== undefined) {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'>" +  ($(this).hasClass('temp-textarea-specific')) ? listTextArea[indexT].val().replace(/\n/g, "<br />") : listTextArea[indexT].val() + "</span>")
        } else {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'></span>")
        }
        $('.new-span-textarea').each(function() {
          $(this).attr('class', getClassCss)
        })
      })
      // GET IMG ARROW
      let arrowUp = $('.'+ idDivExport + ' .select-arrow .arrow-up').clone().removeClass('display-none')
      let arrowDown = $('.'+ idDivExport + ' .select-arrow .arrow-down').clone().removeClass('display-none')
      $('.'+ idDivExport + '-temp' + ' .select-arrow .temp-select').remove()
      if(selectTemp.val() === 'up') {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowUp)
      } else {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowDown)
      }
      return $('.'+ idDivExport + '-temp')
    },
    /*----------------- RESET CONF ------------------*/
    /**
     * Reset conf export flux
     */
    async resetConfExportFlux()
    {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          // Delete form DOM the response (html)
          this.html.flux = ''
          this.html.fluxTemp = ''
          // Handle tabs index
          this.tabs.tabIndex = 1
          // Get HTML from API
          exportService.getHtmlExportFlux({conf : this.configExport})
              .then((response) => {
                this.html.flux = response.data
                this.html.fluxTemp = response.data
              })
              .catch(() => {
              })
          if (this.html.flux) {
            // Generate chart
            this.handleChartsFlux()
          }
        }
      });
    },
    async resetConfExportStatsCa() {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          // Delete form DOM the response (html)
          this.html.statsCa = ''
          this.html.statsCaTemp = ''
          // Handle tabs index
          this.tabs.tabIndex = 2
          // Get HTML from API
          exportService.getHtmlExportStatsCa({conf : this.configExport})
            .then((response) => {
              this.html.statsCa = response.data
              this.html.statsCaTemp = response.data
            })
          if (this.html.statsCa) {
            // Generate chart
            this.handleChartsStats()
          }
        }
      });
    },
    /**
     * Reset total conf
     */
    resetConf()
    {
      // Reset var
      this.configExport = {
        date: null,
        isOk: false
      }
      // Remove html
      this.html = {
        flux: '',
        statsCa: '',
        isHtmlFluxGenerate: false,
        isHtmlStatsCaGenerate: false
      }
      // tabs
      this.tabs.tabIndex = 0
    },
    /*----------------- TAB INDEX ------------------*/
    /**
     * Event click on tabs
     */
    handleTabIndex(index) {
      this.tabs.tabIndex = index
      if(index === 1) {
        this.tabs.isLoadingFlux = true
        // generate chart
        this.handleChartsFlux()
        setTimeout(()=> {
          this.tabs.isLoadingFlux = false
        },1000)
      } else if(index === 2) {
        this.tabs.isLoadingStatsCa = true
        // generate chart
        this.handleChartsStats()
        setTimeout(()=> {
          this.tabs.isLoadingStatsCa = false
        },1000)
      }
    },
    /*----------------- CHANGE CONF ------------------*/
    changeConfig() {
      if(this.configExport.isOk) {
        this.$swal({
          title: 'Vous êtes entrain de changer votre configuration',
          text: "Attention : Si vous acceptez le changement de configuration, Les rendus visuels vont être rechargé, vos perdez vos modifications ! ",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2FA76E',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Changer de configuration ?',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            // Delete form DOM the response (html)
            this.html.flux = ''
            this.html.fluxTemp = ''
            this.html.statsCa = ''
            this.html.statsCaTemp = ''
            // Handle tabs index
            this.tabs.tabIndex = 1

            // Get HTML from API (cats)
            exportService.getHtmlExportFlux({conf: this.configExport})
                .then((response) => {
                  this.html.flux = response.data
                  this.html.fluxTemp = response.data
                  // generate chart
                  this.handleChartsFlux()
                })
            // Get HTML from API (stats)
            exportService.getHtmlExportStatsCa({conf: this.configExport})
                .then((response) => {
                  this.html.statsCa = response.data
                  this.html.statsCaTemp = response.data
                  // generate chart
                  this.handleChartsStats()
                })
          }
        });
      }
    },
    /*----------------- APEX CHART --------------------*/
    handleChartsFlux()
    {
      // Date
      let dateRequested = moment(this.configExport.date)
      // For each Center
      this.CenterModule.centers.forEach((center, indexCenter) => {
        // Init var date
        let startDate = moment(dateRequested.format('YYYY') + '-01-01')
        let endDate = moment(dateRequested.format('YYYY') + '-12-31')
        // init var
        let listChartSeriesEvolutionFlux = []
        let listChartSeriesCncc = []
        let model = {
          centerId: center.id,
          listEvolutionFlux: this.getInputValueInTable(dateRequested, 'evolution-flux', 'flux', center.id),
          listCncc: this.getInputValueInTable(dateRequested, 'cncc', 'flux', center.id)
        }
        // For loop between start and end day
        var i = 0
        while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
          listChartSeriesEvolutionFlux.push({
            x: startDate.format('MM-YYYY'),
            y: model.listEvolutionFlux[i]
          })
          listChartSeriesCncc.push({
            x: startDate.format('MM-YYYY'),
            y: model.listCncc[i]
          })
          i++
          startDate.add(1, 'months')
        }
        // Set series for chart
        this.apexChartFlux[indexCenter].series = [
          {
            name: 'Evolution Fréquentation CC',
            data: listChartSeriesEvolutionFlux
          },
          {
            name: 'Evolution Indice CNCC',
            data: listChartSeriesCncc
          }
        ]
      })
    },
    handleChartsStats()
    {
      // Date
      let dateRequested = moment(this.configExport.date)
      // For each Center
      this.CenterModule.centers.forEach((center, indexCenter) => {
        // Init var Date
        let startDate = moment(dateRequested.format('YYYY') + '-01-01')
        let endDate = moment(dateRequested.format('YYYY') + '-12-31')
        // Init var List
        let listChartSeriesEvolutionGross = []
        let listChartSeriesEvolutionCompare = []
        let listChartSeriesCncc = []
        let model = {
          centerId: center.id,
          listEvolutionGross: this.getInputValueInTable(dateRequested, 'evolution-gross', 'stats', center.id),
          listEvolutionCompare: this.getInputValueInTable(dateRequested, 'evolution-compare', 'stats', center.id),
          listCncc: this.getInputValueInTable(dateRequested, 'cncc', 'stats', center.id)
        }
        // For loop between start and end day
        var i = 0
        while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
          listChartSeriesEvolutionGross.push({
            x: startDate.format('MM-YYYY'),
            y: model.listEvolutionGross[i]
          })
          listChartSeriesEvolutionCompare.push({
            x: startDate.format('MM-YYYY'),
            y: model.listEvolutionCompare[i]
          })
          listChartSeriesCncc.push({
            x: startDate.format('MM-YYYY'),
            y: model.listCncc[i]
          })
          i++
          startDate.add(1, 'months')
        }
        // Set series for chart
        this.apexChartStats[indexCenter].series = [
          {
            name: 'Evolution CA Brut',
            data: listChartSeriesEvolutionGross
          },
          {
            name: 'Evolution Indice CNCC',
            data: listChartSeriesCncc
          },
          {
            name: 'Evolution CA Prorata',
            data: listChartSeriesEvolutionCompare
          }
        ]
      })
    },
    handleChartFluxByCenter(centerId)
    {
      // Date
      let dateRequested = moment(this.configExport.date)
      let startDate = moment(dateRequested.format('YYYY') + '-01-01')
      let endDate = moment(dateRequested.format('YYYY') + '-12-31')
      // init var
      let listChartSeriesEvolutionFlux = []
      let listChartSeriesCncc = []
      // For each Center
      let model = {
        centerId: centerId,
        listEvolutionFlux: this.getInputValueInTable(dateRequested, 'evolution-flux', 'flux', centerId),
        listCncc: this.getInputValueInTable(dateRequested, 'cncc', 'flux', centerId)
      }
      // For loop between start and end day
      var i = 0
      while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
        let modelEvolutionFluxChart = {
          x: startDate.format('MM-YYYY'),
          y: model.listEvolutionFlux[i]
        }
        let modelCnccChart = {
          x: startDate.format('MM-YYYY'),
          y: model.listCncc[i]
        }
        listChartSeriesEvolutionFlux.push(modelEvolutionFluxChart)
        listChartSeriesCncc.push(modelCnccChart)
        i++
        startDate.add(1, 'months')
      }
      // Set array Series for chart
      this.apexChartFlux[centerId - 1].series = [
        {
          name: 'Evolution Fréquentation CC',
          data: listChartSeriesEvolutionFlux
        },
        {
          name: 'Evolution Indice CNCC',
          data: listChartSeriesCncc
        }
      ]
    },
    handleChartStatsByCenter(centerId)
    {
      // Date
      let dateRequested = moment(this.configExport.date)
      let startDate = moment(dateRequested.format('YYYY') + '-01-01')
      let endDate = moment(dateRequested.format('YYYY') + '-12-31')
      // init var
      let listChartSeriesEvolutionGross = []
      let listChartSeriesEvolutionCompare = []
      let listChartSeriesCncc = []
      let model = {
        centerId: centerId,
        listEvolutionGross: this.getInputValueInTable(dateRequested, 'evolution-gross', 'stats', centerId),
        listEvolutionCompare: this.getInputValueInTable(dateRequested, 'evolution-compare', 'stats', centerId),
        listCncc: this.getInputValueInTable(dateRequested, 'cncc', 'stats', centerId)
      }

      // For loop between start and end day
      var i = 0
      while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
        let modelEvolutionGross = {
          x: startDate.format('MM-YYYY'),
          y: model.listEvolutionGross[i]
        }
        let modelEvolutionCompare = {
          x: startDate.format('MM-YYYY'),
          y: model.listEvolutionCompare[i]
        }
        let modelCnccChart = {
          x: startDate.format('MM-YYYY'),
          y: model.listCncc[i]
        }
        listChartSeriesEvolutionGross.push(modelEvolutionGross)
        listChartSeriesEvolutionCompare.push(modelEvolutionCompare)
        listChartSeriesCncc.push(modelCnccChart)

        i++
        startDate.add(1, 'months')
      }
      // Set array Series for chart
      this.apexChartStats[centerId - 1].series = [
        {
          name: 'Evolution CA Brut',
          data: listChartSeriesEvolutionGross
        },
        {
          name: 'Evolution Indice CNCC',
          data: listChartSeriesCncc
        },
        {
          name: 'Evolution CA Prorata',
          data: listChartSeriesEvolutionCompare
        }
      ]
    },
    generateChart(type, center, series) {
      return {
        base64: null,
        id: 'chart-'+ type +'-' + center.id,
        options: {
          chart: {
            toolbar: {
              show: false
            },
          },
          xaxis: {
            type: 'category'
          },
          yaxis: {
            max: function(max) {return (Math.round((max * 1.25) /10)) * 10},
            forceNiceScale: true
          },
          title: {
            text: center.city
          },
          stroke: {
            width: 1.2
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'right',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            itemMargin: {
              vertical: 10
            }
          },
          colors: this.handleChartsColors(type, center),
        },
        series: series
      }
    },
    handleChartsColors(type, center)
    {
      if (type === 'flux') {
        if (center.id === 1) {
          return [ '#19ab6a', '#999']
        } else if (center.id === 2) {
          return ['#F5E85A', '#DE7547']
        }
      } else if (type === 'stats') {
        if (center.id === 1) {
          return ['#19ab6a', '#999', '#862BAB']
        } else if (center.id === 2) {
          return ['#F5E85A', '#DE7547', '#73DAF5']
        }
      }
    },
    getInputValueInTable(dateRequested, cssClass, type, centerId)
    {
      // get data from table
      let listInputValue = []
      let rowTable = $('.response-api-html-export-'+ type +' #table-'+ type +'-'+ centerId +' .' + cssClass)
      // get value from input on second TR
      rowTable.find('td').each(function(index) {
        let inputVal = $(this).find('input').val()
        if(inputVal !== undefined && index !== 13 && inputVal !== '-') {
          listInputValue.push(inputVal)
        }
      })

      return listInputValue
    },
    changeTempInput(event) {
      // Jquery var
      let parentNodeElement = $(event.target).parents("table")
      let getIdTable = parentNodeElement.attr('id').split('-')
      let getType = getIdTable[1]
      let getCenterId = getIdTable[2]
      if(event.target.matches('.temp-input')) {
        if (getType === 'flux') {
          this.handleChartFluxByCenter(getCenterId)
        } else if (getType === 'stats') {
         this.handleChartStatsByCenter(getCenterId)
        }
      }
    },
  },
  watch: {
    isHtmlFluxGenerate: function(newValue) {
      this.html.isHtmlFluxGenerate = newValue
    },
    isHtmlStatCaGenerate: function(newValue) {
      this.html.isHtmlStatCaGenerate = newValue
    },
    /**
     * Check config
     * @param confValue
     */
    configProperty(confValue) {
      if (confValue[1] !== null && confValue[1]) {
        this.configExport.isOk = confValue[0] != null && confValue[1] !== null && confValue[2] !== null
      } else {
        this.configExport.isOk = confValue[0] != null && confValue[1] !== null
      }
    },
  },
  computed: {
    configProperty() {
      return [
        this.configExport.date,
        this.configExport.options,
        this.configExport.comparativeDate
      ]
    },
    ...mapState(['CenterModule']),
    ...mapState(['ExportStatModule']),
    ...mapState(['UserModule'])
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    ApexChart: () => import('vue-apexcharts'),
    Loading: () => import('vue-loading-overlay')
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULE
import AuthModule from '@/store/modules/AuthModule'
import CenterModule from '@/store/modules/CenterModule'
import ShopModule from '@/store/modules/ShopModule'
import ExportModule from '@/store/modules/ExportModule'
import DashboardModule from '@/store/modules/DashboardModule'
import UserModule from '@/store/modules/UserModule'
import CategoryModule from '@/store/modules/CategoryModule'
import CompanyModule from '@/store/modules/CompanyModule'
import SaleModule from '@/store/modules/SaleModule'
import SaleRatingModule from '@/store/modules/SaleRatingModule'
import SaleCertifiedModule from '@/store/modules/SaleCertifiedModule'
import EventModule from '@/store/modules/EventModule'
import FluxModule from '@/store/modules/FluxModule'
import CnccModule from '@/store/modules/CnccModule'
import PodiumModule from "@/store/modules/PodiumModule";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthModule,
    CenterModule,
    ShopModule,
    ExportModule,
    DashboardModule,
    UserModule,
    CategoryModule,
    CompanyModule,
    SaleModule,
    SaleRatingModule,
    SaleCertifiedModule,
    EventModule,
    FluxModule,
    CnccModule,
    PodiumModule
  }
})

import AxiosService from '@/services/AxiosService'
import UrlBuilder from '@/helper/urlBuilder'

export default {
  /**
   * Get list of shop
   *
   * @param centerId => center
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getShops(centerId, perPage, page, order, search) {
    return AxiosService.callApi().get(UrlBuilder('shops', centerId, {
      perPage : perPage,
      page: page,
      order: order,
      search: search
    }))
  },
  /**
   * Get list of shop by center
   *
   * @param centerId => center
   * @returns {*}
   */
  getShopsByCenter(centerId) {
    return AxiosService.callApi().get('/shops/center/' + centerId)
  },
  /**
   * Get shop by id
   *
   * @param id
   * @returns {*}
   */
  getShop(id) {
    return AxiosService.callApi().get('/shops/' + id)
  },
  /**
   * Create shop
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/shops', data)
  },
  /**
   * Edit shop
   *
   * @param data
   * @returns {*}
   */
  edit(data) {
    return AxiosService.callApi().put('/shops/' + data.id, data)
  }
}

import DashboardService from "@/services/DashboardService";

export default {
  state: {
    shopsDashboard: [],
    total: null,
    totalRequest: null
  },
  mutations: {
    SET_SHOPS_DASHBOARD(state, shopsDashboard) {
      state.shopsDashboard = shopsDashboard
    }
  },
  actions: {
    /**
     * Fetch in state list of shops dashboard
     *
     * @param commit
     * @param params
     */
    fetchDashboardShops({ commit }, params) {
      return DashboardService.getShopsDashboard(params)
        .then(response => {
          commit('SET_SHOPS_DASHBOARD', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    },
  }
}

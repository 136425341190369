<template>
  <div id="page-category-list">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="tag" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>

      <div>
        <button class="btn btn-dark mt-2 mt-md-0" v-on:click="handleClickActionForm('create', null)">
          Créer une catégorie
        </button>
      </div>
    </div>
    <hr />

    <div class="d-xl-flex justify-content-between">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 p-0">
        <div class="card app-box-shadow">
          <div class="card-header p-0 bg-white">
            <div class="d-flex justify-content-between h-100">
              <div class="col-10">
                <div class="ml-3 mt-3 mb-3">
                  <h5>Choisir une ou des catégories :</h5>
                </div>
              </div>
              <div class="col-2 border-left d-flex justify-content-center bg-grey-light">
                <div class="d-flex flex-column justify-content-center">
                  <button
                      v-on:click="toggleExpandAll()"
                      :class="(isAllOpen) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'">
                    <b-icon
                        :icon="(isAllOpen) ? 'folder2-open' : 'folder2'"
                        font-scale="1.5"
                        variant="white"
                        class="icon-folder"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pl-0 pr-0">
            <div v-for="(parent, index) in CategoryModule.parentCategories" :key="index">
              <ul class="">
                <node-tree
                    :node="parent"
                    :is-all-open="isAllOpen"
                    :handle-click-action-form="handleClickActionForm"
                    :event-selectable-cat="eventSelectableCat"
                    @selectCat="eventSelectableCat"
                ></node-tree>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-md-2 mt-xl-0 p-0 pl-xl-4">
        <div class="card app-box-shadow">
          <div class="card-body">
            <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
              <div class="text-center">
                <b-spinner variant="success" label="Spinning"></b-spinner>
                <div class="text-primary">Chargement en cours ...</div>
              </div>
            </div>
            <div v-else>
              <FormCrudCategory
                  :category="category"
                  :id="category.id"
                  :actionForm="actionForm"
                  :selected-parent="getSelectedParent"
                  @created="newCategory"
                  @edited="updateCategory"
              ></FormCrudCategory>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      selectCat: null,
      isAllOpen: true,
      dataPage: {
        title: 'Catégories'
      },
      loader: {
        isLoading: false,
      },
      selectableCat: null,
      toggleFolder: undefined,
      isOpen: false,
      actionForm: 'create',
      category: {}
    }
  },
  methods: {
    /**
     * Toggle expand all folder
     */
    toggleExpandAll()
    {
      this.isAllOpen = !this.isAllOpen
    },
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /**
     * Event checked for node tree component
     */
    eventSelectableCat(dataEvent)
    {
      this.getSelectableCat(dataEvent)
    },
    /**
     * Handle selectable cat list
     *
     * @param dataEvent
     */
    getSelectableCat(dataEvent)
    {
      this.selectableCat = this.$store.getters.getCategoryById(dataEvent.id)
    },
    /**
     * Handle click on action form
     */
    handleClickActionForm(actionForm, id)
    {
      if (id != null)
      {
        this.actionForm = actionForm
        return this.category = this.$store.getters.getCategoryById(id)
      }
      else
      {
        this.actionForm = actionForm
        return this.category = {}
      }
    },
    /**
     * Emit on created
     */
    async newCategory()
    {
      // Display loader
      this.showLoader()

      this.actionForm = 'create'

      // Fetch list with new categories
      await this.$store.dispatch('fetchParentCategories', {
        perPage: null,
        page: null
      })
        .then(() => {
          this.hideLoader()

          // TOAST
          this.$bvToast.toast('Nouvelle catégorie', {
            title: 'Category créé avec succès',
            variant: 'success',
            solid: true
          })
        })
    },
    /**
     * Update Category
     */
    async updateCategory(parentId)
    {
      // Display loader
      this.showLoader()

      this.actionForm = 'edit'

      // Fetch list with category updated
      await this.$store.dispatch('fetchParentCategories', {
        perPage: null,
        page: null
      })
        .then(() => {
          this.hideLoader()

          // TOAST
          this.$bvToast.toast('Edition de la catégorie', {
            title: 'Category editée avec succès',
            variant: 'success',
            solid: true
          })
        })

      // Fetch parent
      this.category.parent = this.$store.getters.getCategoryById(parentId)
    },
  },
  async created()
  {
    this.showLoader()

    await this.$store.dispatch('fetchParentCategories', {
      perPage: null,
      page: this.page
    }).then(() => {
      this.hideLoader()
    })
  },
  computed: {
    /**
     * Set var selectedParent
     *
     * @returns {*|number}
     */
    getSelectedParent()
    {
      return (this.category && this.category.parent) ? this.category.parent.id : 0
    },
    page()
    {
      return parseInt(this.$route.query.page) || 1
    },
    ...mapState(['CategoryModule']),
    ...mapGetters(['getCategoryById'])
  },
  components: {
    FormCrudCategory: () => import('@/components/pages/category/FormCrudCategory'),
    NodeTree: () => import('@/components/_shared/NodeTree')
  }
}
</script>

<template>
  <div id="page-user-profile">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="person" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>
    </div>

    <hr />

    <div class="p-0 col-12 col-md-12 col-xl-8 offset-xl-2 mt-4">
      <div class="card">
        <div class="p-md-3">
          {{ /* HEADER CARD */}}
          <div class="d-md-flex justify-content-between">
            <div class="">
              <h3 class="text-center text-md-left pt-3 pt-md-0" v-if="!this.$store.getters.userIsAdmin && !this.$store.getters.userIsMarketing">
                <b-icon class="mr-3" icon="person" font-scale="1.5" variant="primary"></b-icon> Ma page profile
              </h3>
            </div>
            <div class="mt-1 d-sm-flex justify-content-sm-center" v-if="!this.$store.getters.userIsAdmin && !this.$store.getters.userIsMarketing">
              <router-link class="btn btn-outline-primary" :to="{ name: 'sale', params: {id: AuthModule.currentUser.shop.id }}">
                Retour à mes saisies chiffres d'affaires
              </router-link>
            </div>
          </div>

          <hr v-if="!this.$store.getters.userIsAdmin"  class="mb-3"/>

          {{ /* Form component */ }}
          <FormCrudUser
              :user="this.$store.getters.getCurrentUser"
              :id="this.$store.getters.getCurrentUser.id"
              :actionForm="'edit'"
              :selected-shop="getSelectedShop"
              from="profile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      dataPage: {
        title: 'Ma page profil'
      },
    }
  },
  async created() {
    await this.$store.dispatch('fetchUser', this.$route.params.id)

    // Redirect if is not user sales
    if(Number(this.$route.params.id) !== Number(this.$store.getters.getCurrentUser.id)) {
      this.$router.replace({ name: 'user-profile', params: {id: this.$store.getters.getCurrentUser.id} }, () => {})
    }
  },
  computed: {
    /**
     * Set var selectedShop
     *
     * @returns {*|number}
     */
    getSelectedShop()
    {
      return (this.$store.getters.getCurrentUser && this.$store.getters.getCurrentUser.shop) ? this.$store.getters.getCurrentUser.shop.id : 0
    },
    ...mapState(['AuthModule']),
    ...mapState(['UserModule']),
  },
  components: {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>

import FluxService from '../../services/FluxService'

export default {
  state: {
    flux: [],
    fluxSpecific: [],
    csvUrl: null
  },
  mutations: {
    SET_FLUX(state, flux) {
      state.flux = flux
    },
    SET_FLUX_SPECIFIC(state, fluxSpecific) {
      state.fluxSpecific = fluxSpecific
    },
    SET_CSV_URL(state, csvUrl) {
      state.csvUrl = csvUrl
    }
  },
  actions: {
    /**
     * Fetch in state list of flux
     *
     * @param commit
     * @param routeParams
     */
    fetchFlux({ commit }, routeParams) {
      return FluxService.getFlux(routeParams)
        .then(response => {
          commit('SET_FLUX', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    },
    /**
     * Fetch in state list of flux
     *
     * @param commit
     * @param routeParams
     */
    fetchFluxSpecific({ commit }, routeParams) {
      return FluxService.getFluxSpecific(routeParams)
          .then(response => {
            commit('SET_FLUX_SPECIFIC', response.data)

            return Promise.resolve(response.data)
          })
          .catch(error => {
          })
    },
    /**
     * Create or update Cncc
     *
     * @param commit
     * @param model
     */
    createOrUpdateFlux({ commit }, model)
    {
      if(model.userId !== null) {
        return FluxService.create(model)
          .then((response) => {
            // Check if has errors on form
            if(response.data.error) {
              return Promise.reject(response.data.error)
            }

            commit('SET_FLUX', model)
            return Promise.resolve()
          })
      }
    },
    csvFlux({ commit }, model) {
      return FluxService.csvFlux(model)
        .then((response) => {
          commit('SET_CSV_URL', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    }
  },
  getters: {
    getCountFlux: state => {
      return state.flux.length
    }
  }
}

<template>
  <div class="page-user-show">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block app-box-shadow col-10 mx-auto bg-white">
        <div class="p-3 d-md-flex justify-content-between">
          <div class="w-100">
            <h5 class="w-100 mx-auto text-center mb-4 text-primary"><strong>{{ UserModule.user.firstname }} {{ UserModule.user.lastname }} </strong></h5>
            <h6 class="text-main-color" v-if="UserModule.user.shop"><strong>Commerce :</strong> {{ UserModule.user.shop.name }}</h6>
          </div>
          <div>
            <router-link
                class="btn btn-outline-primary mt-2 mt-md-0"
                :to="{ name: 'user-list' }"
            >
              Retour
            </router-link>
          </div>
        </div>
      </div>

      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Informations globales</h4>
          <hr class="m-0" />

          <div class="d-flex mt-3">
            <span><strong>Nom de l'utilisateur</strong> : </span>
            <span class="ml-2">{{ UserModule.user.username }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Email</strong> : </span>
            <span class="ml-2">{{ UserModule.user.email }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Prénom</strong> : </span>
            <span class="ml-2">{{ UserModule.user.firstname }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Nom</strong> : </span>
            <span class="ml-2">{{ UserModule.user.lastname }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Téléphone</strong> : </span>
            <span class="ml-2">{{ UserModule.user.phone }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Rôle</strong> : </span>
            <span class="ml-2">{{ UserModule.user.roles }}</span>
          </div>
        </div>
      </div>
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Config(s)</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Utilisateur actif </strong> : </span>
            <span class="ml-2">{{ isActive }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      isActive: '',
      dataPage: {
        title: 'Détails de l\'utilisateur : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchUser', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['UserModule'])
  },
  mounted() {
    this.isActive = (this.UserModule.user.isActive) ? "Oui" : "Non"
  },
  components: {
  }
}
</script>

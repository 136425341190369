<template>
  <div id="center-key-figures-page">
    {{/* HEADING PAGE */}}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="key" font-scale="1.5" variant="primary"></b-icon>
        Chiffres clés du Centre
      </h3>
    </div>

    <hr />

    {{/* Config */}}
    <Config
        @emit-validate-conf="onValidateConf"
    />

    {{/* BLOCK NAV TABS */}}
    <TabsHtml
        v-if="config.isValidate"
        :config="config"
        :html="html"
        :tabs="tabs"
        :chart="chart"
        @emit-tab-index="handleTabIndex"
        @emit-export-xlsx="exportXlsx"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'
import KeyFiguresService from '@/services/KeyFiguresService'

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      config: {
        selectedCenter: null,
        displayCenter: {},
        period: null,
        showTabs: false,
        isValidate: false
      },
      html: {
        flux: '',
        sales: '',
        isHtmlFluxGenerate: false,
        isHtmlSalesGenerate: false,
      },
      chart: {
        fluxSeries: null,
        salesSeries: null,
        fluxSalesSeries: null
      },
      tabs: {
        tabIndex: 1,
        isLoadingFlux: false,
        isLoadingSales: false,
        isLoadingChartFluxSales: false
      }
    }
  },
  created() {
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader()
    {
      this.loader.isLoading = true
    },
    hideLoader()
    {
      this.loader.isLoading = false
    },
    /*----------------- ON VALIDATE CONF ------------------*/
    async onValidateConf(model) {
      // Handle tab
      await this.handleTabIndex(1)
      // Validate on conf
      this.config.isValidate = true

      this.config.selectedCenter = model.selectedCenter.id
      this.config.displayCenter = model.selectedCenter
      this.config.period = model.periodDate

      // Set model
      let modelData = {
        period: model.periodDate,
        center: model.selectedCenter.id
      }

      this.showLoader()
      // Get HTML FLUX
      await KeyFiguresService.getHtmlExportFlux(modelData)
        .then((response) => {
          this.config.showTabs = true
          this.html.isHtmlFluxGenerate = true
          this.html.flux = response.data

        })
      // GET CHARTS DATA FLUX
      await KeyFiguresService.getFormattedChartsFlux(modelData)
        .then((response) => {
          this.chart.fluxSeries = response.data.chartSeries

          this.hideLoader()
        })

      // GET HTML SALES
      KeyFiguresService.getHtmlExportSales(modelData)
        .then((response) => {
          this.config.showTabs = true
          this.html.isHtmlSalesGenerate = true
          this.html.sales = response.data

          this.hideLoader()
        })
      // GET CHARTS DATA SALES
      await KeyFiguresService.getFormattedChartsSales(modelData)
        .then((response) => {
          this.chart.salesSeries = response.data.chartSeries
          this.chart.fluxSalesSeries = this.mergeFluxSalesSeries()
        })
    },
    /*----------------- HANDLE TAB INDEX ------------------*/
    handleTabIndex(index) {
      this.tabs.tabIndex = index

      if(index === 1) {
        this.tabs.isLoadingFlux = true
        setTimeout(()=> {
          this.tabs.isLoadingFlux = false
        },1000)
      } else if(index === 2) {
        this.tabs.isLoadingSales = true
        setTimeout(()=> {
          this.tabs.isLoadingSales = false
        },1000)
      } else if(index === 3) {
        this.tabs.isLoadingChartFluxSales = true
        setTimeout(()=> {
          this.tabs.isLoadingChartFluxSales = false
        },1000)
      }
    },
    /*----------------- HANDLE TAB INDEX ------------------*/
    exportXlsx(isFlux) {
      // Set model
      let modelData = {
        period:  this.config.period,
        center:  this.config.selectedCenter
      }

      KeyFiguresService.exportXlsx(isFlux, modelData)
    },
    mergeFluxSalesSeries() {
      // Init var
      let fluxSalesSeries = []
      // Format data flux
      this.chart.fluxSeries.forEach((dataFlux) => {
        fluxSalesSeries.push({
          name: 'Flux ' + dataFlux.name,
          type: 'column',
          data: dataFlux.data
        })
      })
      // Format data sales
      this.chart.salesSeries.forEach((dataSales) => {
        fluxSalesSeries.push({
          name: 'CA ' + dataSales.name,
          type: 'line',
          data: dataSales.data
        })
      })

      return fluxSalesSeries
    }
  },
  computed: {
    currentYear() {
      return moment().format('YYYY')
    },
    ...mapState(['CenterModule']),
  },
  components: {
    Config: () => import('../../../components/centerKeyFigures/config'),
    TabsHtml: () => import('../../../components/centerKeyFigures/tabsHtml')
  },
}
</script>

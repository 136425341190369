import AxiosService from '@/services/AxiosService'
import axios from "axios";

export default {
    /**
     *
     * @param data
     * @returns {*}
     */
    getHtmlExportFlux(data)
    {
        return AxiosService.callApi().post('/keys_figures/html/flux', data)
    },
    /**
     * @param data
     * @returns {*}
     */
    getFormattedChartsFlux(data)
    {
        return AxiosService.callApi().post('/keys_figures/chart/flux', data)
    },
    /**
     *
     * @param data
     * @returns {*}
     */
    getHtmlExportSales(data)
    {
        return AxiosService.callApi().post('/keys_figures/html/sales', data)
    },
    /**
     * @param data
     * @returns {*}
     */
    getFormattedChartsSales(data)
    {
        return AxiosService.callApi().post('/keys_figures/chart/sales', data)
    },
    exportXlsx(isFlux, data)
    {
        let url = process.env.VUE_APP_API_URL + '/keys_figures/export/xlsx/' + ((isFlux) ? 'flux' : 'sales')

        return axios({
            url: url,
            method: 'POST',
            responseType: 'blob',
            data: data,
            headers: {
                common: {
                    Accept: 'application/xlsx'
                },
                patch: {
                    'Content-Type': 'application/xlsx'
                },
                Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
            }
        })
            .then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/xlsx' }))
                var fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute(
                    'download',
                    'export-chiffres-cles.xlsx'
                )
                document.body.appendChild(fileLink)
                fileLink.click();

                return Promise.resolve(true)
            })
    }
}

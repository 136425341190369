<template>
  <div>
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="trophy" font-scale="1.5" variant="primary"></b-icon>
        Podiums
      </h3>
    </div>
    <hr/>
    <div id="podium">
      <div class="row justify-content-md-center">
        <div class="col-4">
          <div>
            {{ /* PERIOD */ }}
            <div class="d-flex justify-content-between">
              {{ /* BUTTON CHANGE PERIOD */ }}
              <div class="w-100">
                <div class="">
                  <div class="">
                    <div class="w-75 mx-auto">
                      <Datepicker
                          @selected="clickDatePicker"
                          id="period-flux-month"
                          :format="formatDatepicker"
                          :minimumView="'month'"
                          :maximumView="'month'"
                          :language="datepicker.language"
                          :value="datepicker.defaultDate"
                          input-class="mt-2 w-100 date-select"
                          :disabledDates="datepicker.disabledDates"
                          placeholder="Choisir une période"
                      ></Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-12">
          <div class="d-flex justify-content-around mt-4 mb-5">
            {{ /* CHOOSE CENTER */ }}
            <div>
              <div class="w-100 d-flex justify-content-center">
                <b-dropdown
                    class="dropdown-form-dashboard m-auto position-relative"
                    :text="filter.center.city"
                    ref="dropdown"
                    variant="outline-secondary"
                >
                  <b-dropdown-item
                      v-for="(center) in centers"
                      :value="center.id"
                      :key="center.id"
                      v-on:click="changeCenter(center)"
                  >
                    {{ center.city }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-if="searchParams.center === 0" class="container podium">
                <p class="small-text">Veuillez choisir un centre</p>
              </div>
            </div>

            {{ /* CHOOSE CATEGORY */ }}
            <div>
              <div class="w-100 d-flex justify-content-center">
                <b-dropdown
                    class="dropdown-form-dashboard m-auto position-relative"
                    :text="filter.category.name"
                    ref="dropdown"
                    variant="outline-secondary"
                >
                  <b-dropdown-item
                      v-for="(category) in categories"
                      :value="category.id"
                      :key="category.id"
                      v-on:click="changeCategory(category)"
                  >
                    {{ category.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-if="searchParams.category === 0" class="container podium">
                <p class="small-text">Veuillez choisir une catégorie</p>
              </div>
            </div>

            {{ /* CHOOSE MEDAL TYPE */ }}
            <div>
              <div class="w-100 d-flex justify-content-center">
                <b-dropdown
                    class="dropdown-form-dashboard m-auto position-relative"
                    :text="selectedMedalType.label"
                    ref="dropdown"
                    variant="outline-secondary"
                >
                  <b-dropdown-item
                      v-for="(medalType, key) in this.medalTypes"
                      :value="medalType"
                      :key="medalType"
                      v-on:click="changeMedalType(key)"
                  >
                    {{ medalType }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="text-center" v-if="showPodium">
            <h4>{{ selectedMedalType.label }} - {{filter.category.name}}</h4>
            <h4>({{filter.center.name}})</h4>
          </div>
        </div>
      </div>
      <div v-if="showPodium" class="podium d-flex align-items-end justify-content-center">
        <div class="podium__item">
          <div class="podium__rank second">
            <div class="name position-absolute">
              {{ silverMedal.name }}
            </div>
          </div>
        </div>
        <div class="podium__item">
          <div class="podium__rank first">
            <div class="name position-absolute">
              {{ goldMedal.name }}
            </div>
          </div>
        </div>
        <div class="podium__item">
          <div class="podium__rank third">
            <div class="name position-absolute">
              {{ bronzeMedal.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="searchParams.center != 0 && searchParams.category !=0" class="container podium">
        <h5>Aucun podium disponible pour ces paramètres</h5>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import {fr} from "vuejs-datepicker/dist/locale";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
  data() {
    return {
      goldMedal: {
        name: null,
        height: '250px',
        rank: 1
      },
      silverMedal: {
        name: null,
        height: '200px',
        rank: 2
      },
      bronzeMedal: {
        name: null,
        height: '150px',
        rank: 3
      },
      loader: {
        isLoading: false
      },
      filter: {
        center: {
          id: 0,
          city: 'Choisir un centre',
          data: null
        },
        category: {
          id: 0,
          name: 'Choisir une catégorie',
          data: null,
        }
      },
      selectedMedalType: {
        name: 'monthlyYield',
        label: 'Chiffre d’affaire / m² mensuel'
      },
      medalTypes: {
        'monthlyYield': 'Chiffre d’affaire / m² mensuel',
        'monthlyYieldEvolution': 'Évolution chiffre d’affaires / m² mensuel',
        'rollingYield': 'Chiffre d’affaires / m² sur 12 mois',
        'rollingYieldEvolution': 'Évolution chiffre d’affaires / m² mensuel sur 12 mois'
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        disabledDates: {
          to: null,
          from: new Date(new Date()),
        },
      },
      searchParams: {
        center: 0,
        category: 0,
        date: moment().format('YYYY-MM-DD')
      },
      showPodium: true,
      centers: [],
      categories: []
    }
  },
  created() {
    let dateObj = new Date()
    this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

    // Fetch Medals
    this.resetPodium();
    this.fetchCategories();
    this.fetchCenters();
    this.fetchMedals(this.searchParams);
  },

  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- DATEPICKER -------------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value) {
      let momentObj = moment(new Date(value));
      this.searchParams.date = momentObj.format('YYYY-MM-DD');
      this.fetchMedals(this.searchParams);
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      let dateObj = new Date(date)
      return this.capitalizeText(moment(dateObj).format('MMMM yyyy'));
    },

    /*----------------- MEDALS -------------------*/

    handleShowMedals() {

      this.resetPodium();

      let currentMedals = this.$store.getters.getMedals;

      if (currentMedals.length > 0 && this.searchParams.category != 0 && this.searchParams.center != 0) {

        this.showPodium = true;

        currentMedals.forEach(medal => {
          if (medal.type === this.selectedMedalType.name) {
            switch (medal.rank) {
              case 1:
                this.goldMedal.name = medal.shop.name;
                break;
              case 2:
                this.silverMedal.name = medal.shop.name;
                break;
              case 3:
                this.bronzeMedal.name = medal.shop.name;
                break;
            }
          }
        })
      } else this.showPodium = false;
    },
    changeMedalType(type) {
      this.selectedMedalType.name = type;
      this.selectedMedalType.label = this.medalTypes[type];
      this.handleShowMedals();
    },
    changeCategory(category) {
      this.searchParams.category = category.id;
      this.filter.category = category;
      this.fetchMedals(this.searchParams);
    },
    changeCenter(center) {
      this.searchParams.center = center.id;
      this.filter.center = center;
      this.fetchCategories(this.searchParams);
      this.fetchMedals(this.searchParams);
    },
    resetPodium() {
      this.goldMedal.name = '';
      this.silverMedal.name = '';
      this.bronzeMedal.name = '';
      this.showPodium = false;
    },
    fetchMedals(searchParams) {
      if (searchParams.center && searchParams.category && searchParams.date) {
        this.$store.dispatch('fetchMedals', {...searchParams})
            .then(() => {
              this.handleShowMedals();
            })
      } else this.handleShowMedals();
    },
    fetchCategories() {
      this.$store.dispatch('fetchParentCategories').then(() => {
        this.categories = this.$store.getters.getAllParentCat;
      })

    },
    fetchCenters() {
      this.$store.dispatch('fetchCenters', {}).then(() => {
        this.centers = this.$store.getters.getAllCenters;
      })
    },
    changePeriod($month, $monthStr, $monthSubtract, $year) {
      // Display loader
      this.showLoader()

      // Change period
      this.period.month = ($month < 10) ? '0' + $month : $month
      this.period.monthStr = $monthStr
      this.period.monthSubtract = $monthSubtract
      this.period.year = $year

      this.hideLoader()
    },
    capitalizeText(initialText) {
      return initialText.charAt(0).toUpperCase() + initialText.slice(1);
    }
  }
};
</script>

<style scoped>

</style>

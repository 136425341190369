<template>
  <div class="page-shop-show mb-5">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block app-box-shadow col-10 mx-auto bg-white">
        <div class="p-3 d-md-flex justify-content-between">
          <div class="w-100">
            <h5 class="w-100 mx-auto text-center mb-4 text-primary"><strong>{{ ShopModule.shop.name }}</strong></h5>
            <h6 class="text-main-color"><strong>Centre :</strong> {{ ShopModule.shop.center.name }}</h6>
            <h6 class="text-main-color"><strong>Catégorie :</strong> {{ ShopModule.shop.category.name }}</h6>
          </div>

          <div v-if="!this.$route.query.isDashboard">
            <div class="d-flex align-items-center">
              <router-link
                  class="btn btn-outline-primary "
                  :to="{ name: 'shop-list' }"
              >
                Retour
              </router-link>
              <router-link
                  class="btn btn-primary mt-2 ml-2 mt-md-0"
                  :to="{ name: 'shop-edit' }"
              >
                Modifier
              </router-link>
            </div>
          </div>
          <div v-else>
            <div class="d-flex">
              <router-link
                  class="btn btn-outline-primary"
                  :to="{ name: 'dashboard' }"
              >
                Retour
              </router-link>
              <router-link
                  class="btn btn-primary mt-2 ml-2 mt-md-0"
                  :to="{ name: 'shop-edit' }"
              >
                Edit
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!--  NOM, TVA , LOT    -->
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Nom, TVA, lot & surface</h4>
          <hr class="m-0" />

          <div class="d-flex mt-3">
            <span><strong>Nom</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.name }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>TVA</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.vatRate }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Numéro de lot </strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.lot }}</span>
          </div>

          <div class="d-flex mt-3">
            <span><strong>Surface</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.surface }} m<sup>2</sup></span>
          </div>
        </div>
      </div>

      <!--  CONTACT -->
      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Contact</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Prénom du contact</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.contactFirstname }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Nom du contact</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.contactLastname }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Email du contact </strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.contactEmail }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Téléphone du contact</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.contactPhone }}</span>
          </div>
        </div>
      </div>

      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Date(s)</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Date d'ouverture</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.openDate | moment('DD/MM/Y') }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Date de fermeture</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.closeDate | moment('DD/MM/Y') }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Date de livraison de coque </strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.hullDate | moment('DD/MM/Y') }}</span>
          </div>
        </div>
      </div>

      <div class="app-box-shadow col-10 mx-auto bg-white mt-4">
        <div class="p-3">
          <h4 class="text-main-color">Config(s)</h4>
          <hr class="m-0" />
          <div class="d-flex mt-3">
            <span><strong>Double saisie du CA</strong> : </span>
            <span class="ml-2">{{ doubleSaleEntry }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Saisie par mois du CA</strong> : </span>
            <span class="ml-2">{{ isMonth }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Date limite du CA</strong> : </span>
            <span class="ml-2">{{ ShopModule.shop.dateLimitSale }}</span>
          </div>
          <div class="d-flex mt-3">
            <span><strong>Déclaration de CA</strong> : </span>
            <span class="ml-2">{{ (ShopModule.shop.isHaveSale) ? 'Oui' : 'Non' }}</span>
          </div>
        </div>
      </div>


    </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      doubleSaleEntry: '',
      isMonth: '',
      dataPage: {
        title: 'Détails du commerce : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchShop', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    ...mapState(['ShopModule'])
  },
  mounted() {
    this.doubleSaleEntry = (this.ShopModule.shop.doubleSaleEntry) ? "Oui" : "Non"
    this.isMonth = (this.ShopModule.shop.isMonth) ? "Oui" : "Non"
  },
  components: {
  }
}
</script>

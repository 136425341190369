import store from '../store/index'

// APP
import NotFound from '@/views/app/404'
import Error from '@/views/app/error'
import ForgotPassword from '@/views/app/ForgotPassword'
import ChangePassword from '@/views/app/changePassword'

// ACCOUNT
import Login from '@/views/auth/Login'

// Dashboard
import Dashboard from '@/views/pages/dashboard/Dashboard'

// CENTER
import CenterFlux from '../views/pages/center/CenterFlux'
import CenterCncc from '../views/pages/center/CenterCncc'
import CenterKeyFigures from '../views/pages/center/CenterKeyFigures'

// SALE
import Sale from '@/views/pages/sale/sales'

// SHOP
import ShopList from '@/views/pages/shop/ShopList'
import ShopShow from '@/views/pages/shop/ShopShow'
import ShopCreate from '@/views/pages/shop/ShopCreate'
import ShopEdit from '@/views/pages/shop/ShopEdit'

// USER
import UserList from '@/views/pages/user/UserList'
import UserShow from '@/views/pages/user/UserShow'
import UserCreate from '@/views/pages/user/UserCreate'
import UserEdit from '@/views/pages/user/UserEdit'
import UserProfile from '@/views/pages/user/UserProfile'

// CATEGORY
import CategoryList from '@/views/pages/category/CategoryList'

// COMPANY
import CompanyList from '@/views/pages/company/CompanyList'
import CompanyShow from '@/views/pages/company/CompanyShow'
import CompanyCreate from '@/views/pages/company/CompanyCreate'
import CompanyEdit from '@/views/pages/company/CompanyEdit'

// EXPORT
import ExportCA from '@/views/pages/export/exportCA'
import ExportMarketing from '@/views/pages/export/exportMarketing'
import ExportStats from '@/views/pages/export/exportStats'
import ExportComparative from '@/views/pages/export/exportComparative'
import Export from '@/views/pages/export/export'

// SALE CERTIFIED
import SaleCertified from '@/views/pages/sale/saleCertified'

// CALENDAR
import Calendar from '@/views/pages/calendar/Index'
import CalendarEvents from '@/views/pages/calendar/Events'

// PODIUM

import PodiumView from "@/components/podium/podiumView";
import HistogramCA from "@/views/pages/histogramCA";

const AppRoutes = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
]

const AccountRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      authRequired: false,
      routePublic: true
    }
  },
  {
    path: '/change-password/:token/:id',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      authRequired: false,
      routePublic: true,
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve() {
        store.dispatch('logout', true)
      }
    }
  }
]

const PagesRoutes = [
  {
    path: '/sale/:id',
    name: 'sale',
    component: Sale,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/sale/certified/:id',
    name: 'sale-certified',
    component: SaleCertified,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const ShopRoutes = [
  {
    path: '/shop',
    name: 'shop-list',
    component: ShopList,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/shop/show/:id',
    name: 'shop-show',
    component: ShopShow,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/shop/create',
    name: 'shop-create',
    component: ShopCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/shop/edit/:id',
    name: 'shop-edit',
    component: ShopEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const UserRoutes = [
  {
    path: '/user',
    name: 'user-list',
    component: UserList,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/user/show/:id',
    name: 'user-show',
    component: UserShow,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: UserCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: UserEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/user/profile/:id',
    name: 'user-profile',
    component: UserProfile,
    meta: {
      authRequired: true
    }
  }
]

const CategoryRoutes = [
  {
    path: '/category',
    name: 'category-list',
    component: CategoryList,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const CompanyRoutes = [
  {
    path: '/company',
    name: 'company-list',
    component: CompanyList,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/company/show/:id',
    name: 'company-show',
    component: CompanyShow,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/company/create',
    name: 'company-create',
    component: CompanyCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/company/edit/:id',
    name: 'company-edit',
    component: CompanyEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const ExportRoutes = [
  {
    path: '/export',
    name: 'export-index',
    component: Export,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true
    }
  },
  {
    path: '/export/marketing',
    name: 'export-marketing-index',
    component: ExportMarketing,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true
    }
  },
  {
    path: '/export/stats',
    name: 'export-stats-index',
    component: ExportStats,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true
    }
  },
  {
    path: '/export/comparative',
    name: 'export-comparative-index',
    component: ExportComparative,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true
    },
  },
  {
    path: '/export/ca',
    name: 'export-ca-index',
    component: ExportCA,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true
    },
  }
]

const CalendarRoutes = [
  {
    path: '/calendar',
    name: 'calendar-index',
    component: Calendar,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true

    }
  },
  {
    path: '/calendar/events/:date',
    name: 'calendar-events',
    component: CalendarEvents,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true,
      routeMarketing: true

    }
  }
]

const CenterRoutes = [
  {
    path: '/center/flux',
    name: 'center-flux',
    component: CenterFlux,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/center/cncc',
    name: 'center-cncc',
    component: CenterCncc,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/center/key-figures',
    name: 'center-key-figures',
    component: CenterKeyFigures,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/podium',
    name: 'podium-index',
    component: PodiumView,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
    {
      path: '/histogram',
      name: 'histogram-index',
      component: HistogramCA,
      props: true,
      meta: {
        authRequired: true,
        routeAdmin: true
      }
  }
]

const allRoutes = [
  ...AppRoutes,
  ...AccountRoutes,
  ...PagesRoutes,
  ...ShopRoutes,
  ...UserRoutes,
  ...CategoryRoutes,
  ...CompanyRoutes,
  ...ExportRoutes,
  ...CalendarRoutes,
  ...CenterRoutes
]

export { allRoutes }

import SaleRatingService from '@/services/SaleRatingService'

export default {
  state: {
    saleRating: {},
  },
  mutations: {
    SET_SALE_RATING(state, saleRating) {
      state.saleRating = saleRating
    },
  },
  actions: {
    /**
     * Fetch in state a sale rating
     *
     * @param commit
     * @param getters
     * @param model
     */
    fetchSaleRating({ commit }, model)
    {
      return SaleRatingService.getSaleRating(model)
        .then(response => {
          commit('SET_SALE_RATING', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Create sale rating
     *
     * @param commit
     * @param model
     */
    createSaleRating({ commit }, model)
    {
      return SaleRatingService.create(model)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SALE_RATING', model.ratingModel)
          return Promise.resolve(response)
        })
    },
    /**
     * Update sale rating
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateSaleRating({ commit }, model)
    {
      return SaleRatingService.edit(model)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SALE_RATING', response.data)
        })
    },
  }
}

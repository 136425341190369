import SaleCertifiedService from '@/services/SaleCertifiedService'

export default {
  state: {
    saleCertified: {},
    totalSalesCertified: null,
  },
  mutations: {
    SET_SALE_CERTIFIED(state, saleCertified) {
      state.saleCertified = saleCertified
    },
    SET_TOTAL_SALE_CERTIFIED(state, total) {
      state.totalSalesCertified = total
    }
  },
  actions: {
    fetchTotalSalesCertified({commit}, model)
    {
      return SaleCertifiedService.getTotalSales(model)
        .then(response => {
          commit('SET_TOTAL_SALE_CERTIFIED', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Fetch in state a sale Certified
     *
     * @param commit
     * @param getters
     * @param model
     */
    fetchSaleCertified({ commit }, model)
    {
      return SaleCertifiedService.getSaleCertified(model)
        .then(response => {
          commit('SET_SALE_CERTIFIED', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Create sale Certified
     *
     * @param commit
     * @param saleCertified
     */
    createSaleCertified({ commit }, saleCertified)
    {
      return SaleCertifiedService.create(saleCertified)
        .then((response) => {
          commit('SET_SALE_CERTIFIED', saleCertified)
          return Promise.resolve(response)
        })
    },
    /**
     * Update sale Certified
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateSaleCertified({ commit }, model)
    {
      return SaleCertifiedService.edit(model)
        .then((response) => {
          commit('SET_SALE_CERTIFIED', response.data)
        })
    },
  }
}

<template>
    <div id="center-flux-page">
      {{/* HEADING PAGE */}}
      <div class="header-pages-block d-md-flex justify-content-between">
        <h3 class="p-0">
          <b-icon class="mr-3" icon="graph-up" font-scale="1.5" variant="primary"></b-icon>
          Flux visiteurs
        </h3>

        <div>
          <button
              class="btn btn-dark btn-sm"
              v-on:click="eventClickExportCsv()"
          >
            Export CSV
          </button>
        </div>

      </div>

      <hr />

      <div class="d-md-flex p-0">
        <div class="col-12 col-md-12 col-xl-9">
          {{/* CARD BY DAY */}}
          <div class="block-card-flux">
            <form ref="form-flux" class="form-flux w-100">
              <div class="d-flex flex-wrap pb-4 w-100">
                <div
                    class="col-6 col-sm-6 col-md-4 col-lg-6 col-xl-4 p-0"
                    v-for="(obj, mainIndex) in listByDay" :key="mainIndex"
                >
                  <div class="card border-0 mb-4 mr-4 app-box-shadow">
                    {{ /* DATE AND ICONS */ }}
                    <div class="card-header border-0 d-flex justify-content-between bg-card-header">
                      <div class="date w-100 text-center">
                        <div class="font-weight-bold">
                          {{ displayDate(obj.date) }}
                        </div>
                      </div>

                    </div>

                    <div class="card-body input-days border-0">
                      {{/* INPUTS */}}
                      <div class="mt-2 p-2" :class="'div-flux-input-' + index" v-for="(center, index) in CenterModule.centers" :key="index">
                        <label for="input-flux">
                          Flux visiteurs : <b :style="{color: center.color}">{{ center.name }}</b>
                        </label>
                        <div id="input-flux" class="input-group">
                          <input
                              type="number"
                              step="1"
                              min="0"
                              class="form-control"
                              v-model="obj.fluxValue[index].value"
                              placeholder="Rentrer un flux visiteurs"
                              v-on:change="onChangeInputFlux($event, mainIndex, center)"
                              v-on:focusout="onFocusInputFlux()"
                          >
                        </div>
                      </div>
                      {{/* INPUTS SPECIFIC */}}
                      <div class="mt-2 p-2 div-flux-input-specific">
                        <label for="input-flux">
                          Flux visiteurs :
                          <b :style="{color: (CenterModule.centers) ? CenterModule.centers[0].color : ''}">
                            {{ totalMonthFluxSpecific.name }}
                          </b>
                        </label>
                        <div id="input-flux-specific" class="input-group">
                          <input
                              type="number"
                              step="1"
                              min="0"
                              class="form-control"
                              v-model="obj.fluxValueSpecific.value"
                              placeholder="Rentrer un flux visiteurs"
                              v-on:change="onChangeInputFluxSpecific($event, mainIndex)"
                              v-on:focusout="onFocusInputFluxSpecific()"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-3 p-0">
          <div class="card app-box-shadow border-0">
            {{/* PERIOD */}}
            <div class="d-flex justify-content-between">
              {{ /* BUTTON CHANGE PERIOD */ }}
              <div class="w-100">
                <div class="">
                  <div class="">
                    <div class="w-75 mx-auto">
                      <Datepicker
                          @selected="clickDatePicker"
                          id="period-flux-month"
                          :format="formatDatepicker"
                          :minimumView="'month'"
                          :maximumView="'month'"
                          :language="datepicker.language"
                          :value="datepicker.defaultDate"
                          input-class="mt-2 w-100 date-select"
                          :disabledDates="datepicker.disabledDates"
                          placeholder="Choisir une période"
                      ></Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {{/* BLOCK ACTION */}}
            <div class="action-flux">
              <div class="p-2 m-2">
                <div class="total-month-desktop" >
                  <div class="">
                    <h6 class="text-center">
                      Total flux du mois en cours :
                      <span class="text-info">
                        <b>{{ period.monthStr }} {{ period.year }}</b>
                      </span>
                    </h6>

                    <div class="text-center mt-4 mb-2" v-if="loader.isLoading">
                      <b-spinner variant="success" label="Spinning"></b-spinner>
                      <div class="text-primary">Chargement en cours ...</div>
                    </div>
                    <div class="mt-4" v-else>
                      <div class="d-flex justify-content-between" v-for="(obj, index) in totalMonthFlux" :key="index">
                        <span class="medium-text-2 mt-2" :style="{color: obj.center.color}">{{ obj.center.name }}</span>
                        <span class="medium-text-2 mt-2">
                          {{ obj.total }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mt-4">
                  <button
                      class="btn btn-success btn-lg"
                      v-on:click="onSubmit"
                  >
                    Valider la saisie des Flux
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'
import axios from "axios";
import CenterModule from "@/store/modules/CenterModule";
import exportService from "@/services/ExportService";

export default {
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: new Date(new Date()), // Disable all dates after specific date
        },
      },
      period : {
        month : this.handleDate(0, 'MM'),
        monthStr : this.capitalizeText(this.handleDate(0, 'MMMM')),
        monthSubtract: 0,
        year : this.handleDate(0, 'YYYY')
      },
      listByDay: [],
      totalMonthFlux: [],
      totalMonthFluxSpecific: {
        total: 0,
        name: 'Dont Terrasse PB'
      },
      card: {
        index: null,
        bgColor: null
      },
    }
  },
  created() {

    // set default date for datepicker
    let dateObj = new Date()
    this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

    // Key component
    this.componentKey += 1

    // Set period
    this.period.month = this.handleDate(0, 'MM')
    this.period.year = this.handleDate(0, 'YYYY')

    // Construct list by day
    this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

    // Fetch Center
    this.$store.dispatch('fetchCenters', {
      perPage: null,
      page: null
    })
      .then((response) => {
        // Handle total month
        response.forEach((center) => {
          let model = {
            center: center,
            total: 0,
            isSpecific: false
          }

          this.totalMonthFlux.push(model)
        })
        // Fetch List Flux
        this.$store.dispatch('fetchFlux', this.period)
            .then((response) => {
              this.fillListByDayInMonth(response)
              this.CenterModule.centers.forEach((centerObj, index) => {
                this.totalMonthFlux[index].total = this.listByDay.reduce((result, obj) => {
                  result += Number(obj.fluxValue[index].value)
                  return Math.round(result)
                }, 0)
              })
              // Fetch List Flux Specific
              this.$store.dispatch('fetchFluxSpecific', this.period)
                  .then((response) => {
                    this.fillListByDayInMonth(response, true)
                    this.handleTotalFluxSpecific()

                    this.totalMonthFlux.splice(1, 0, {
                      center: {
                        color: this.CenterModule.centers[0].color,
                        name: 'Dont Terrasse PB'
                      },
                      isSpecific: true,
                      total: this.totalMonthFluxSpecific.total
                    })
                  })
            })
      })




  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader()
    {
      this.loader.isLoading = true
    },
    hideLoader()
    {
      this.loader.isLoading = false
    },
    /*----------------- DATE ----------------*/
    displayDate(date) {
      let dateObj = moment(date).format('dddd DD MMM YYYY')

      let splitDate = dateObj.split(' ')
      let day = splitDate[0].toUpperCase()
      let month = splitDate[2].toUpperCase()

      return day + ' ' + splitDate[1] + ' ' + month + ' ' + splitDate[3]
    },
    /*----------------- CARD ----------------*/
    /**
     * Toggle active on card
     *
     * @param index
     * @param obj
     */
    cardActiveEvent(index, obj)
    {
      // Set obj card
      this.card.index = index
      this.card.bgColor = (obj.bgColor) ? obj.bgColor : 'bg-green-dark'
    },
    /*----------------- PERIOD ----------------*/
    changePeriod($month, $monthStr, $monthSubtract, $year)
    {
      // Display loader
      this.showLoader()

      // Close active card
      this.card.index = null

      // Change period
      this.period.month = ($month < 10) ? '0' + $month : $month
      this.period.monthStr = $monthStr
      this.period.monthSubtract = $monthSubtract
      this.period.year = $year

      // ListByDay
      this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

      // Fetch List Flux
      this.$store.dispatch('fetchFlux', this.period)
        .then((response) => {
          this.fillListByDayInMonth(response, false)

          this.CenterModule.centers.forEach((centerObj, index) => {
            let getEntryInListTotal = this.totalMonthFlux.find(obj => obj.center.name === centerObj.name);

            getEntryInListTotal.total = this.listByDay.reduce((result, obj) => {
                result += Number(obj.fluxValue[index].value)

                return Math.round(result)
              }, 0)
          })
        })

      // Fetch List Flux Specific
      this.$store.dispatch('fetchFluxSpecific', this.period)
        .then((response) => {
          this.fillListByDayInMonth(response, true)
          this.handleTotalFluxSpecific()

          let getEntryInListTotal = this.totalMonthFlux.find(obj => obj.isSpecific === true);
          getEntryInListTotal.total = this.listByDay.reduce((result, obj) => {
            result += Number(obj.fluxValueSpecific.value)

            return Math.round(result)
          }, 0)
        })

      this.hideLoader()
    },
    /**
     * Get date period
     *
     * @param format
     */
    getDatePeriod(format)
    {
      let dateObj = new Date(this.period.year + '-' + this.period.month + '-01')
      return moment(dateObj).format(format)
    },
    handleDate($monthToSubtract, format)
    {
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')
      let newDate = moment(currentDate).subtract($monthToSubtract, 'month')

      return newDate.format(format)
    },
    /*----------------- ON CHANGE -------------------*/
    onChangeInputFlux($event, mainIndex, center)
    {
      // bg-info on change
      this.listByDay[mainIndex].bgColor = 'bg-info'

      if ($event.target.value) {
        this.listByDay[mainIndex].isChange = true
        this.listByDay[mainIndex].fluxValue.find(obj => obj.center.id === center.id).value = $event.target.value
      } else {
        this.listByDay[mainIndex].isChange = false
        this.listByDay[mainIndex].fluxValue.find(obj => obj.center.id === center.id).value = 0
      }
    },
    onChangeInputFluxSpecific($event, mainIndex)
    {
      if ($event.target.value) {
        this.listByDay[mainIndex].isChange = true
        this.listByDay[mainIndex].fluxValueSpecific.value = $event.target.value
      } else {
        this.listByDay[mainIndex].isChange = false
        this.listByDay[mainIndex].fluxValueSpecific.value = 0
      }
    },
    onFocusInputFlux()
    {
      this.CenterModule.centers.forEach((centerObj, index) => {
        let getEntryInListTotal = this.totalMonthFlux.find(obj => obj.center.name === centerObj.name);
        getEntryInListTotal.total = this.listByDay.reduce((result, obj) => {
          result += Number(obj.fluxValue[index].value)

          return Math.round(result)
        }, 0)
      })
    },
    onFocusInputFluxSpecific()
    {
      let findSpecificEntryInListTotal = this.totalMonthFlux.find(obj => obj.isSpecific === true)
      this.totalMonthFluxSpecific.total = this.listByDay.reduce((result, obj) => {
        result += Number(obj.fluxValueSpecific.value)

        return Math.round(result)
      }, 0)

      findSpecificEntryInListTotal.total = this.listByDay.reduce((result, obj) => {
        result += Number(obj.fluxValueSpecific.value)

        return Math.round(result)
      }, 0)
    },
    /*----------------- TOTAL FLUX SPECIFIC -------------------*/
    handleTotalFluxSpecific()
    {
      this.totalMonthFluxSpecific.total = this.listByDay.reduce((result, obj) => {
        result += Number(obj.fluxValueSpecific.value)
        return Math.round(result)
      }, 0)
    },
    /*----------------- DATEPICKER -------------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)
      var momentObj = moment(dateObj)

      this.changePeriod(
          Number(momentObj.format('MM')),
          this.capitalizeText(momentObj.format('MMMM')),
          0,
          Number(momentObj.format('YYYY'))
      )
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date)
    {
      let dateObj = new Date(date)
      return this.capitalizeText(moment(dateObj).format('MMMM yyyy'));
    },
    /**
     * List days of current month
     *
     * @returns {[]}
     */
    getDaysInMonth(month, year)
    {
      // init var
      let dayArray = []
      let strDate = new Date(year + '-' + month + '-01')

      // Set start and end date
      let startDayMonth = moment(strDate).format('YYYY-MM-DD')
      let endDayMonth = moment(strDate).format('YYYY-MM-') + moment(strDate).daysInMonth()

      // For loop between start and end day
      let i = 0;
      while (startDayMonth <= endDayMonth) {

        let model = {
          date: startDayMonth,
          fluxValue: [],
          fluxValueSpecific: {
            day: startDayMonth,
            value: null
          },
          bgColor: null,
          index: i,
          user: this.$store.getters.getCurrentUser,
          isChange: false
        }

        this.CenterModule.centers.forEach((centerObj) => {
          model.fluxValue.push({
            center: centerObj,
            value: null
          })
        })

        dayArray.push(model)
        startDayMonth = moment(startDayMonth).add(1, 'days').format('YYYY-MM-DD')

        i ++
      }

      return dayArray
    },
    fillListByDayInMonth(response, isSpecific)
    {
      response.forEach((data) => {
        let findDayInListInMonth = this.listByDay.find(obj => obj.date === data.date)

        if (isSpecific) {
          findDayInListInMonth.fluxValueSpecific.value = data.value
        } else {
          findDayInListInMonth.fluxValue.forEach((fluxValue, index) => {
            if (data.center.id === fluxValue.center.id) {
              findDayInListInMonth.fluxValue[index].value = data.value
            }
          })
        }
      })
    },
    /*----------------- EXPORT CSV -------------------*/
    async eventClickExportCsv()
    {
      // Display loader
      this.showLoader()

      let model = {
        date: {
          month: this.period.month,
          year: this.period.year
        },
        listFlux: this.listByDay
      }


      // Fetch Export CSV
      await this.$store.dispatch('csvFlux', model)
        .then((response) => {
          if (response) {
            const fileName = 'flux_mensuel_' + moment().format('MM-YY')
            exportService.triggerFileDownload(response, fileName, 'text/csv')
          }

          this.hideLoader()
        })
        .catch((error) => {
          this.hideLoader()

          this.$bvToast.toast(error.message, {
            title: 'ERREUR !',
            variant: 'danger',
            solid: true
          })
        })
    },
    /*----------------- ON SUBMIT -------------------*/
    onSubmit()
    {
      // Loader
      this.showLoader()

      // Handle list by day for create
      let listForCreate = []
      this.listByDay.forEach((obj) => {
        if (obj.isChange) {
          listForCreate.push(obj)
        }
      })

      this.$store.dispatch('createOrUpdateFlux', listForCreate)
        .then(() => {
          this.hideLoader()

          // Fetch List Flux
          this.$store.dispatch('fetchFlux', this.period)
            .then((response) => {
              this.fillListByDayInMonth(response)

              this.$bvToast.toast('Les flux visiteurs ont été mis à jour', {
                title: 'Changement Flux visiteur(s)',
                variant: 'success',
                solid: true
              })
            })
        })
        .catch(() => {
          this.hideLoader()
        })
    },
    capitalizeText(initialText) {
      return initialText.charAt(0).toUpperCase() + initialText.slice(1);
    }
  },
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['UserModule'])
  }
}
</script>

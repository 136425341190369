import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get list of event
   *
   * @param params
   * @returns {*}
   */
  getEvents(params) {
    let url = '/events'

    if (params.period.date.start) {
      url += '?_startDate=' + params.period.date.start
    }
    if (params.period.date.start) {
      url += '&_endDate=' + params.period.date.end
    }
    if (params.filter) {
      url += '&_filter=' + params.filter
    }

    return AxiosService.callApi().get(url)
  },
  /**
   * Get list of event types
   *
   * @param shopType
   * @returns {*}
   */
  getEventTypes(shopType) {
    let url = '/events/types'
    if (shopType) {
      url += '?_targetType=' + shopType
    }
    return AxiosService.callApi().get(url)
  },
  /**
   * Get event by id
   *
   * @param eventId
   * @returns {*}
   */
  getEvent(eventId) {
    return AxiosService.callApi().get('/events/' + eventId)
  },
  /**
   * Create event
   *
   * @param event
   * @returns {*}
   */
  create(event) {
    return AxiosService.callApi().post('/events', event)
  },
  /**
   * Change date event
   *
   * @param model
   */
  changeDateEvent(model) {
    return AxiosService.callApi().put('/events/dates/' + model.event.id, model)
  },
  /**
   * Edit event
   *
   * @param event
   * @returns {*}
   */
  edit(event) {
    return AxiosService.callApi().post('/events/' + event.id, event)
  },
  /**
   * Delete event
   *
   * @param model
   */
  delete(model) {
    return AxiosService.callApi().delete('/events/' + model.id)
  }
}

<template>
  <div id="page-export-ca">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>
    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>
      <div>
        <button class="btn btn-outline-danger mt-2" v-on:click="resetConf">
          Réinitialiser
        </button>
      </div>
    </div>
    <hr />
    {{/* BLOCK NAV TABS */}}
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 0) ? 'active' : ''" v-on:click="handleTabIndex(0)">
          <div class="mt-1 p-1">
            Configuration Export
            <span class="text-info text-bold"> {{ (filter.center.data) ? ' | ' + filter.center.data.city : ''}}</span>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 1) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(1)">
          <div v-if="html.cat !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-category">Rendu Visuel de l'export (catégorie)</div>
            <div class="">
              <button
                  class="btn btn-sm bg-tabs-category ml-3"
                  v-on:click="resetConfExportCats()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
        <a class="nav-item nav-link c-pointer" :class="(tabs.tabIndex === 2) ? 'active' : ''" v-show="configExport.showTabs" v-on:click="handleTabIndex(2)">
          <div v-if="html.shop !== ''" class="d-flex justify-content-between">
            <div class="my-auto text-tabs-shop">Rendu Visuel de l'export (magasins)</div>
            <div class="">
              <button
                  class="btn btn-sm bg-tabs-shop ml-3"
                  v-on:click="resetConfExportShops()"
              >
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
              </button>
            </div>
          </div>
          <div class="p-1 mt-1" v-else>
            <b-spinner variant="success" type="border" small></b-spinner>
          </div>
        </a>
      </div>
    </nav>
    {{/* TAB CONTENT */}}
    <div class="tab-content" id="nav-tabContent">
      <b-card class="border-top-0" no-body>
        {{/* BLOCK CONFIG */}}
        <div class="fade" :class="(tabs.tabIndex === 0) ? 'show active' : ''" v-show="tabs.tabIndex === 0">
          <div class="config-tab">
            <b-alert v-if="configExport.showTabs" show variant="info" class="ml-4 mr-4 mt-4 mb-4">
              <h5 class="alert-heading">Changement de configuration</h5>
              <hr />
              <p>
                <b>Attention :</b> si vous changez la configuration,
                <br />
                Vous avez la possibilité de recharger chaque rendu visuel en cliquant sur leur boutton respectif,
                <br />
                présent dans les onglets au dessus.
                <button class="btn btn-sm btn-secondary ml-2">
                  <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
                </button>
              </p>

              <hr />
              <b>Sinon :</b> cliquez sur ce boutton pour recharger les rendus visuels
              <button class="btn btn-sm btn-primary ml-3" v-on:click="changeConfig">
                <b-icon icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon> Recharger les rendu visuels ?
              </button>
            </b-alert>

            <div class="mt-3 row">
              <div class="col-12 col-md-4 col-lg-4 p-0 pl-3 mb-2 mx-auto">
                <div class="p-2">
                  <div class="">
                    <div class="form-group">
                      <Datepicker
                          input-class="w-100 date-select text-center"
                          id="date-start"
                          v-model="configExport.dateStart"
                          :format="formatDatepicker"
                          :minimumView="'month'"
                          :maximumView="'month'"
                          :language="datepickerLanguage"
                          @input="configExport.date = fixDate($event)"
                          placeholder="Date de début"
                      >
                      </Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 row">
              <div class="col-12 col-md-4 col-lg-4 p-0 pl-3 mb-2 mx-auto">
                <div class="p-2">
                  <div class="">
                    <div class="form-group">
                      <Datepicker
                          input-class="w-100 date-select text-center"
                          id="date-start"
                          v-model="configExport.dateEnd"
                          :format="formatDatepicker"
                          :minimumView="'month'"
                          :maximumView="'month'"
                          :language="datepickerLanguage"
                          @input="configExport.date = fixDate($event)"
                          placeholder="Date de fin"
                      >
                      </Datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 p-0 m-auto" v-if="configExport.dateEnd != null && configExport.dateStart != null">
              <SelectShop class="ml-4 m-auto pb-5" @emit-list-shops="emitListShops"/>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import moment from 'moment'
import exportService from '../../../services/ExportService'
import {fr} from 'vuejs-datepicker/dist/locale'
import ExportService from "@/services/ExportService";

export default {
  data() {
    return {
      filter: {
        center: {
          id: 0,
          title: 'Choisir un centre',
          data: null
        }
      },
      isotope: {
        currentLayout: 'masonry',
        selectLayout: null,
        layouts: [
          "masonry"
        ],
        containerStyle: {
          overflow: 'auto'
        },
        options: {
          masonry: {
            columnWidth: 30
          }
        }
      },
      html: {
        cat: '',
        catTemp: '',
        shop: '',
        shopTemp: '',
        isHtmlCatGenerate: false,
        isHtmlShopGenerate: false,
      },
      tabs: {
        tabIndex: 0,
        isLoadingCat: false,
        isLoadingShop: false
      },
      shops: [],
      datepickerLanguage: fr,
      isAllOpen: null,
      isAllChecked: null,
      isAllSelectableShop: null,
      selectableCat: null,
      listSelectableShop: [],
      listSelectableCat: [],
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      configExport: {
        dateStart: null,
        dateEnd: null,
        typeExport: 'shop',
        formatDate: 'MM/yyyy',
        isOk: false,
        showShop: false,
        showTabs: false,
        listShops: null
      },
      dataPage: {
        title: 'Export(s) CA',
        buttonAllSelectableShop: {
          title: 'Décocher',
          cssClass: 'btn-outline-danger mb-2'
        }
      }
    }
  },
  created() {
  },
  updated() {
  },
  methods: {
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      return moment(date).format('MMMM');
    },
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FILTER ------------------*/
    changeFilterCenter(centerId) {
      if (centerId !== 0) {
        // Reset conf
        this.resetConf()

        this.filter.center.id = centerId
        let center = this.$store.getters.getCenterById(centerId)
        this.filter.center.title = 'Centre choisi : ' + center.city
        this.filter.center.data = center

        // Fetch PARENT category
        this.showLoader()

        this.$store.dispatch('fetchExportParentCategories', this.filter.center.id)
            .then(() => {
              this.hideLoader()
            })

      } else {
        this.filter.center.id = null
        this.filter.center.title = 'choisir un centre'
        this.filter.center.data = null
      }
    },
    fixDate(event) {
      return moment(event).format('YYYY-MM-') + moment(event).daysInMonth()
    },
    handleDateConf()
    {
      if(this.configExport.startDate != null)
        this.configExport.startDate = moment(this.configExport.startDate).format('yyyy-MM-DD')
    },
    /**
     * Toggle expand all folder
     */
    toggleExpandAll()
    {
      this.isAllOpen = !this.isAllOpen
    },
    selectAllCatParent(event)
    {
      // Get all parent cat
      let parentsCat = this.CategoryModule.exportParentCategories

      if(event.target.checked) {
        // Set all checked to child component NodeTree
        this.isAllChecked = true

        parentsCat.forEach((cat) => {
          this.listSelectableCat.push(cat)

          // Handle push shop
          this.handleRecursiveCatChildrenPush(cat)
        })
      } else {
        // Set all checked to child component NodeTree
        this.isAllChecked = false

        this.listSelectableCat = []
        this.listSelectableShop = []
      }
    },
    // eslint-disable-next-line no-unused-vars
    isCheckboxShopDisplay(openDate, closeDate = null)
    {
      // @deprecated
      /*
      if(closeDate > this.configExport.date) {
        return true
      } else if(closeDate && closeDate < this.configExport.date) {
        return false
      } else if(openDate < this.configExport.date) {
        return true
      } else {
        return false
      }
      */

      return true
    },
    handleSelectAllShop()
    {
      let that = this

      this.isAllSelectableShop = this.isAllSelectableShop === false

      if(!this.isAllSelectableShop) {
        $('.div-checkbox-node-shop input').each(function() {
          $(this).prop('checked', false)
        })

        this.listSelectableShop = []

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Tout cocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-success'
      } else {
        that.listSelectableShop = []

        $('.div-checkbox-node-shop input').each(function() {
          let id = $(this).attr('id')

          $(this).prop('checked', true)

          that.listSelectableShop.push(Number(id))
        })

        // Handle button
        this.dataPage.buttonAllSelectableShop.title = 'Décocher'
        this.dataPage.buttonAllSelectableShop.cssClass = 'btn-outline-danger'
      }
    },
    /**
     * Event checked for node tree component
     */
    eventSelectableCat(dataEvent) {
      this.getSelectableCat(dataEvent)
    },
    /**
     * Remove or add shop
     * Checkbox
     */
    eventSelectableShop(dataEvent, cat) {
      this.getSelectableShop(dataEvent, cat)
    },
    /**
     * Handle selectable cat list
     *
     * @param dataEvent
     */
    getSelectableCat(dataEvent)
    {
      if(dataEvent.parent === null) {
        this.shops = (this.$store.getters.getExportParentCategoryById(dataEvent.id).shops) ? this.$store.getters.getExportParentCategoryById(dataEvent.id).shops : []
        this.selectableCat = this.$store.getters.getExportParentCategoryById(dataEvent.id)
      } else {
        this.shops = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id).shops
        this.selectableCat = this.$store.getters.getExportCategoryById(dataEvent.parent, dataEvent.id)
      }

      // If cat input was checked
      if(dataEvent.checked) {
        this.listSelectableCat.push(this.selectableCat)

        // Handle push shop
        if(this.selectableCat.shops && this.selectableCat.shops.length > 0) {
          this.selectableCat.shops.forEach(shop => {
            if(this.isCheckboxShopDisplay(shop.openDate, shop.closeDate)) {
              this.listSelectableShop.push(shop.id)
            }
          })
        }

        this.handleRecursiveCatChildrenPush(this.selectableCat)
      } else {
        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj.id === dataEvent.id)
        if (indexCat > -1) {
          this.listSelectableCat.splice(indexCat, 1)

          // Handle children
          if(this.selectableCat.childs.length > 0) {
            this.handleRecursiveCatChildForSplice(this.selectableCat)
          }

          // Handle splice shop
          if(this.selectableCat.shops && this.selectableCat.shops.length > 0) {
            this.selectableCat.shops.forEach(shop => {
              let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
              if (indexShop > -1) {
                this.listSelectableShop.splice(indexShop, 1)
              }
            })
          }
        }
      }
    },
    /**
     * Recursive for get child on cat and push
     */
    handleRecursiveCatChildrenPush(cat)
    {
      if(cat.childs.length > 0) {
        cat.childs.forEach(child => {
          // List cat
          this.listSelectableCat.push(child)

          // Handle push shop
          if(child.shops && child.shops.length > 0) {
            child.shops.forEach(shop => {
              if(this.isCheckboxShopDisplay(shop.openDate, shop.closeDate)) {
                this.listSelectableShop.push(shop.id)
              }
            })
          }

          this.handleRecursiveCatChildrenPush(child)
        })
      }
    },
    /**
     * Recursive for get child on cat and splice
     */
    handleRecursiveCatChildForSplice(cat)
    {
      cat.childs.forEach(child => {
        // Remove cat
        let indexChild = this.listSelectableCat.findIndex(obj => obj.id === child.id)
        if(indexChild > -1) {
          this.listSelectableCat.splice(indexChild, 1)
          this.handleRecursiveCatChildForSplice(child)
        }

        // Handle splice shop
        if(child.shops && child.shops.length > 0) {
          child.shops.forEach(shop => {
            let indexShop = this.listSelectableShop.findIndex(id => id === shop.id)
            if (indexShop > -1) {
              this.listSelectableShop.splice(indexShop, 1)
            }
          })
        }
      })
    },
    /**
     * Handle selectable shop list
     *
     * @param dataEvent
     * @param cat
     */
    getSelectableShop(dataEvent, cat) {
      // If shop input checked was checked
      if(dataEvent.target.checked) {
        this.listSelectableShop.push(parseInt(dataEvent.target.id))
      } else {
        // Handle shop
        let indexShop = this.listSelectableShop.findIndex(id => id === parseInt(dataEvent.target.id))
        if (indexShop > -1) {
          this.listSelectableShop.splice(indexShop, 1)
        }

        // Handle cat
        let indexCat = this.listSelectableCat.findIndex(obj => obj === cat)
        if (indexCat > -1 && dataEvent.target.countElemChecked === 0) {
          this.listSelectableCat.splice(indexCat, 1)
        }
      }

      // Get count of this listSelectableShop
      this.isShopSelectable = this.listSelectableShop.length > 0
    },
    /**
     * Event click button made visual
     */
    eventClickButtonMadeVisual()
    {
      // Show tabs
      this.configExport.showTabs = true
      // Handle Cat
      this.validateConfExportCats()
      // Handle Shops
      this.validateConfExportShops()
    },
    async eventClickExportCsv() {
      // Display loader
      this.showLoader()

      let model = {
        centerId: this.filter.center.id,
        conf: this.configExport,
        shopsId: this.listSelectableShop
      }


      // Fetch Export CSV
      await this.$store.dispatch('csvShopsExport', model)
          .then((response) => {
            if (response) {
              let centerName = this.filter.center.data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
              const fileName = 'shops_' + centerName.replace(/ /g,'').toLowerCase() + '_' + moment().format('MM-YY')
              exportService.triggerFileDownload(response, fileName, 'text/csv')
            }

            this.hideLoader()
          })
          .catch((error) => {
            this.hideLoader()

            this.$bvToast.toast(error.message, {
              title: 'ERREUR !',
              variant: 'danger',
              solid: true
            })
          })
    },
    /**
     * Click on button validate conf export (cat)
     */
    async validateConfExportCats() {
      let that = this

      // Change format date
      this.handleDateConf()

      // Loader
      this.showLoader()

      // Get HTML from API
      exportService.getHtmlExportCats({
        centerId: this.filter.center.id,
        conf : this.configExport,
        shopIds: this.listSelectableShop
      })
          .then((response) => {
            // Set is Generate
            that.html.isHtmlCatGenerate = true

            // Get in var HTML
            this.html.cat = response.data
            this.html.catTemp = response.data

            // Handle tabs index
            this.tabs.tabIndex = 1

            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /**
     * Send the new html to API
     */
    async sendHtmlExportCats() {
      // Handle tab
      await this.handleTabIndex(1)

      // Loader
      this.showLoader()

      let newHtml = this.changeInputToSpanJquery('response-api-html-export-cat');

      exportService.sendHtmlExportCats(newHtml.html())
          .then((response) => {

            let centerName = this.filter.center.data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            const fileName = 'bilan_' + centerName.replace(/ /g,'').toLowerCase() + '_' + moment().format('MM-YY')
            exportService.triggerFileDownload(response.data, fileName, 'application/pdf')

          })
          .then(() => {
            // Reset html
            $('.response-api-html-export-cat-temp').html($('.response-api-html-export-cat').html())

            this.hideLoader()
          })
          .catch((error) => {
            this.hideLoader()
            throw error;

          })
    },
    /**
     * Click on button validate conf export (shop)
     */
    async validateConfExportShops() {
      let that = this

      // Change format date
      this.handleDateConf()

      // Loader
      this.showLoader()

      // Get HTML from API
      exportService.getHtmlExportShops({
        centerId: this.filter.center.id,
        conf : this.configExport,
        shopIds: this.listSelectableShop
      })
          .then((response) => {
            // Set is Generate
            that.html.isHtmlShopGenerate = true

            // Get in var HTML
            this.html.shop = response.data
            this.html.shopTemp = response.data

            // Handle tabs index
            this.tabs.tabIndex = 1

            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    async sendHtmlExportShops() {
      // Handle tab
      await this.handleTabIndex(2)

      let newHtml = this.changeInputToSpanJquery('response-api-html-export-shop');

      this.showLoader()

      await exportService.sendHtmlExportShops(newHtml.html())
          .then((response) => {

            let centerName = this.filter.center.data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            const fileName = 'bilan_' + centerName.replace(/ /g,'').toLowerCase() + '_' + moment().format('MM-YY')
            exportService.triggerFileDownload(response.data, fileName, 'application/pdf')

          }).catch((error) => {
            throw error;
          })
          .then(() => {
            // Reset html
            $('.response-api-html-export-shop-temp').html($('.response-api-html-export-shop').html())

            this.hideLoader()
          })
          .catch((error) => {
            this.hideLoader()
            throw error;
          })
    },
    /**
     * Change all input on html request
     */
    changeInputToSpanJquery(idDivExport)
    {
      // INPUT HTML VUE
      let listInput = []
      $('.'+ idDivExport + ' .temp-input').each(function() {
        listInput.push($(this))
      })
      // TEXTAREA HTML
      let listTextArea = []
      $('.'+ idDivExport + ' .temp-textarea').each(function() {
        listTextArea.push($(this))
      })

      // SELECT
      let selectTemp = $('.'+ idDivExport + ' .temp-select')

      // INPUT TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-input').each(function(indexI) {
        const getClassCss = $(this).attr('class');

        const dataType = $(this).attr('data-type');

        // INPUT
        if (listInput[indexI] !== undefined) {
          $(this).parent().html("<span class='new-span-input text-center' style='width: 95%'>" + listInput[indexI].val() + "</span>")
        }

        $('.new-span-input').each(function() {
          $(this).attr('class', getClassCss)
          if(dataType && dataType === 'percent' && listInput[indexI] && listInput[indexI].val() && listInput[indexI].val() !== 0) {
            $(this).after("<span class='"+ getClassCss +"'> %</span>")
          }
        })
      })

      // TEXTAREA TO SPAN ON HTML SEND
      $('.'+ idDivExport + '-temp' + ' .temp-textarea').each(function(indexT) {
        const getClassCss = $(this).attr('class');

        if(listTextArea[indexT] !== undefined) {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'>" +  ($(this).hasClass('temp-textarea-specific')) ? listTextArea[indexT].val().replace(/\n/g, "<br />") : listTextArea[indexT].val() + "</span>")
        } else {
          $(this).parent().html("<span class='new-span-textarea text-center' style='margin-left: 10px; width: 95%'></span>")
        }

        $('.new-span-textarea').each(function() {
          $(this).attr('class', getClassCss)
        })
      })

      // GET IMG ARROW
      let arrowUp = $('.'+ idDivExport + ' .select-arrow .arrow-up').clone().removeClass('display-none')
      let arrowDown = $('.'+ idDivExport + ' .select-arrow .arrow-down').clone().removeClass('display-none')

      $('.'+ idDivExport + '-temp' + ' .select-arrow .temp-select').remove()
      if(selectTemp.val() === 'up') {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowUp)
      } else {
        $('.'+ idDivExport + '-temp' + ' .select-arrow').html(arrowDown)
      }

      return $('.'+ idDivExport + '-temp')
    },
    /**
     * Reset conf export cats
     */
    resetConfExportCats()
    {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {

          // Delete form DOM the response (html)
          this.html.cat = ''
          this.html.catTemp = ''

          // Handle tabs index
          this.tabs.tabIndex = 1

          // Get HTML from API
          exportService.getHtmlExportCats({
            centerId: this.filter.center.id,
            conf : this.configExport,
            shopIds: this.listSelectableShop
          })
              .then((response) => {
                this.html.cat = response.data
                this.html.catTemp = response.data
              })
        }
      });
    },
    /**
     * Reset conf export shops
     */
    resetConfExportShops()
    {
      this.$swal({
        title: 'êtes vous sur ?',
        text: "Attention : Si vous acceptez le rechargement, vous perdez vos modifications ! ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Recharger',
        cancelButtonText: 'Annuler'
      }).then((result) => {

        if (result.value) {
          // Delete form DOM the response (html)
          this.html.shop = ''
          this.html.shopTemp = ''

          // Handle tabs index
          this.tabs.tabIndex = 2

          // Get HTML from API
          exportService.getHtmlExportShops({
            centerId: this.filter.center.id,
            conf : this.configExport,
            shopIds: this.listSelectableShop
          })
              .then((response) => {
                this.html.shop = response.data
                this.html.shopTemp = response.data
              })
        }
      });
    },
    /**
     * Reset total conf
     */
    resetConf()
    {
      // Reset var
      this.configExport = {
        date: null,
        isOk: false,
        showShop: false
      }

      // Filter center
      this.filter.center.id = 0
      this.filter.center.title = 'Choisir un centre'
      this.filter.center.data = null

      this.selectableCat = null
      this.listSelectableShop = []
      this.listSelectableCat =  []

      // Remove html
      this.html = {
        cat: '',
        shop: '',
        isHtmlCatGenerate: false,
        isHtmlShopGenerate: false
      }

      // Checkbox
      this.isAllOpen = false

      // tabs
      this.tabs.tabIndex = 0

      // jquery
      $('.form-check-input').prop('checked', false);
    },
    /*----------------- EMIT LIST SHOPS ------------------*/
    emitListShops(listShop)
    {
      this.configExport.listShops = listShop;

      this.showLoader()
      ExportService.exportCAXlsx(this.configExport)
          .then(() => {
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /**
     * Event click on tabs
     */
    handleTabIndex(index) {
      this.tabs.tabIndex = index

      if(index === 1) {
        this.tabs.isLoadingCat = true
        setTimeout(()=> {
          this.tabs.isLoadingCat = false
        },1000)
      } else if(index === 2) {
        this.tabs.isLoadingShop = true
        setTimeout(()=> {
          this.tabs.isLoadingShop = false
        },1000)
      }
    },
    changeConfig() {
      if(this.configExport.isOk) {
        this.$swal({
          title: 'Vous êtes entrain de changer votre configuration',
          text: "Attention : Si vous acceptez le changement de configuration, Les rendus visuels vont être rechargé, vos perdez vos modifications ! ",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2FA76E',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Changer de configuration ?',
          cancelButtonText: 'Annuler'
        }).then((result) => {

          if (result.value) {
            // Delete form DOM the response (html)
            this.html.shop = ''
            this.html.shopTemp = ''
            this.html.cat = ''
            this.html.catTemp = ''

            // Handle tabs index
            this.tabs.tabIndex = 1

            // Get HTML from API (cats)
            exportService.getHtmlExportCats({
              centerId: this.filter.center.id,
              conf: this.configExport,
              shopIds: this.listSelectableShop
            })
                .then((response) => {
                  this.html.cat = response.data
                  this.html.catTemp = response.data
                })
            // Get HTML from API (shop)
            exportService.getHtmlExportShops({
              centerId: this.filter.center.id,
              conf: this.configExport,
              shopIds: this.listSelectableShop
            })
                .then((response) => {
                  this.html.shop = response.data
                  this.html.shopTemp = response.data
                })
          }
        });
      }
    },
  },
  watch: {
    isHtmlCatGenerate: function(newValue) {
      this.html.isHtmlCatGenerate = newValue
    },
    isHtmlShopGenerate: function(newValue) {
      this.html.isHtmlShopGenerate = newValue
    },
    /**
     * Check if conf date and selectableShop was correctly fill
     * @param confValue
     */
    configProperty(confValue) {
      this.configExport.isOk = confValue[0] != null && confValue[1] !== 0

      if(confValue[0] != null && confValue[1] !== 0) {
        this.configExport.showShop = true
      }
    },
  },
  computed: {
    configProperty() {
      return [this.configExport.date, this.listSelectableShop.length]
    },
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule']),
    ...mapState(['ExportModule']),
    ...mapState(['UserModule'])
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    Loading: () => import('vue-loading-overlay'),
    SelectShop: () => import('../../../components/exportMarketing/SelectShops')

  }
}
</script>

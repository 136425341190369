<template>
  <div id="page-sale">
    {{ /* HEADING PAGE FOR ADMIN */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <div class="">
        <h3 class="">
          <b-icon class="mr-3" icon="bar-chart-line" font-scale="1.5" variant="primary"></b-icon>
          {{ 'Chiffres d\'affaires : ' + SaleModule.shop.name }}
        </h3>
        <div v-if="!SaleModule.shop.isMonth">
          Le magasin a jusqu'au {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }} pour remplir son
          chiffre d'affaires
        </div>
      </div>

      <div class="mt-4">
        <router-link
            class="btn btn-outline-primary"
            :to="{ name: 'shop-list' }"
        >
          Retour
        </router-link>
      </div>
    </div>

    <hr class="mt-5"/>
    <div v-if="this.saleError.isError" class="text-center alert alert-danger mt-4 col-8 offset-2" role="alert">
      {{ this.saleError.errorMessage }}
    </div>

    {{ /* TOTAL MONTH */ }}
    <div class="col-4">
      {{ /* BUTTON CHANGE PERIOD */ }}
      <div class="p-2 h-25 mb-2 w-100">
        <div class="">
          <Datepicker
              @selected="clickDatePicker"
              id="date-sale-month"
              :format="formatDatepicker"
              :minimumView="'month'"
              :maximumView="'month'"
              :language="datepicker.language"
              :value="datepicker.defaultDate"
              input-class="mt-2 text-capitalize date-select"
              :disabledDates="datepicker.disabledDates"
              placeholder="Choisir une période"
          ></Datepicker>
        </div>
      </div>
    </div>

    <div class="d-md-flex">
      <div class="col-12 p-0">
        {{ /* CARD SALES */ }}
        <div class="card" :class="(SaleModule.shop.isMonth) ? 'card-month-sales' : 'card-daily-sales'">
          <div class="">
            {{ /* IF SHOP IS MONTH */ }}
            <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-4 mb-4"
                 v-if="loader.isLoading">
              <div class="text-center">
                <b-spinner variant="success" label="Spinning"></b-spinner>
                <div class="text-primary">Chargement en cours ...</div>
              </div>
            </div>
            <div v-else>
              <div v-if="SaleModule.shop.isMonth">
                <div class="d-flex justify-content-center">
                  {{ /* CARD */ }}
                  <div
                      class="card card-sales-month d-flex flex-column justify-content-between app-box-shadow-button border-0 col-8 col-md-4 mb-3 p-2"
                      :class="[
                        (saleModel.userWithVat !== null || saleModel.userWithoutVat !== null) ? 'card-fill' : '',
                        (saleModel.bgColor !== null) ? saleModel.bgColor : ''
                    ]"
                  >
                    {{ /* ICON */ }}
                    <div v-if="saleModel.totalWithoutVat && saleModel.totalWithVat">
                      <button class="btn btn-sm btn-reset-sales mr-2" v-on:click.prevent="resetSales(saleModel)">
                        <span class="text-grey">Réinitialiser</span>
                      </button>
                    </div>
                    <div class="icon d-flex justify-content-center">
                      <div v-if="saleModel.bgColor === 'bg-danger'">
                        <b-icon icon="exclamation-triangle" font-scale="1.5" variant="white"></b-icon>
                      </div>
                      <div v-else-if="saleModel.bgColor === 'bg-primary'">
                        <b-icon icon="check-circle" font-scale="1.5" variant="white"></b-icon>
                      </div>
                      <div v-else-if="saleModel.bgColor === 'bg-warning'">
                        <b-icon icon="person" font-scale="1.5" variant="white"></b-icon>
                      </div>
                    </div>

                    {{ /* DATE */ }}
                    <h3 class="text-white text-center">
                      {{ getDatePeriod('MMMM YYYY') }}
                    </h3>

                    <form @submit.prevent="initSubmit(true)">
                      <div class="d-flex justify-content-center">
                        <div class="input-group w-75">
                          <input
                              type="number"
                              step="0.01"
                              class="form-control border-0"
                              v-model="saleModel.totalWithoutVat"
                              placeholder="Chiffre d'affaires"
                              v-on:keyup="onChangeInputWithoutVat($event, saleModel)"
                          >
                          <div class="input-group-append">
                            <div class="input-group-text text-grey bg-white border-0">HT</div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-center mt-4">
                        <div class="input-group w-75">
                          <input
                              type="number"
                              step="0.01"
                              class="form-control border-0"
                              v-model="saleModel.totalWithVat"
                              placeholder="Chiffre d'affaires"
                              v-on:keyup="onChangeInputWithVat($event, saleModel)"
                          >
                          <div class="input-group-append">
                            <div class="input-group-text text-grey bg-white border-0">TTC</div>
                          </div>
                        </div>

                        <input type="hidden" v-model="saleModel.id">
                      </div>

                      <div class="text-center mt-1" v-if="errorFormSales">
                        <p class="form-error"
                           :class="(saleModel.bgColor === 'bg-danger') ? 'text-warning' : 'text-danger'">
                          {{ errorFormSales }}
                        </p>
                      </div>

                      <div class="d-flex justify-content-center mt-5"
                           v-if="$store.getters.userIsAdmin || saleModel.bgColor !== 'bg-primary'">
                        <input
                            type="submit"
                            class="btn ml-3 mt-2"
                            v-if="!saleModel.error"
                            :class="(saleModel.bgColor !== '') ? 'btn-outline-light' : 'btn-primary'"
                            value="Valider"
                        />
                        <div v-else-if="saleModel.error">
                          <span class="text-danger">{{ saleModel.error }}</span>
                        </div>
                      </div>
                    </form>

                    <div class="text-white small-text text-center" v-if="saleModel.bgColor === 'bg-warning'">
                      <template v-if="$store.getters.userIsAdmin">
                        Chiffre d'affaires rempli par l'utilisateur
                      </template>
                      <template v-else>
                        Chiffre d'affaires rempli par un administrateur
                      </template>
                    </div>
                    <div class="text-white small-text text-center" v-else>
                      Le magasin à jusqu'au {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }}
                      pour remplir son chiffre d'affaires
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <form ref="formSales" @submit.prevent="initSubmit(false)" class="form-sales w-100">
                  <div class="d-flex flex-wrap mt-2 pb-4 w-100">
                    {{ /* CARD SALES BY DAY */ }}
                    <div
                        class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                        v-for="(obj, index) in listByDay" :key="index"
                    >
                      <div
                          class="card card-sales app-box-shadow-button border-0 p-2 mt-3"
                          v-on:click="cardActiveEvent(index, obj)"
                          :class="[
                            (card.index === index) ? 'card-active' : '',
                            (obj.userWithVat !== null || obj.userWithoutVat !== null) ? 'card-fill' : '',
                            (obj.bgColor !== null) ? obj.bgColor : '',
                          ]"
                      >
                        {{ /* DATE AND ICONS */ }}
                        <div class="d-flex justify-content-between">
                          <div class="date" :class="(obj.bgColor === 'bg-danger') ? 'text-white' : 'text-grey'">
                            <div class="sale-date-desktop">
                              {{
                                obj.date | moment((card.index === index || (obj.userWithVat !== null || obj.userWithoutVat !== null)) ? 'ddd DD' : 'DD')
                              }}
                            </div>
                            <div class="sale-date-mobile">
                              {{ obj.date | moment('DD') }}
                            </div>
                          </div>

                          <div class="icon-desktop icon d-flex justify-content-between">
                            <div v-if="obj.totalWithoutVat && obj.totalWithVat">
                              <button class="btn btn-sm btn-reset-sales mr-2" v-on:click.prevent="resetSales(obj)">
                                <span class="text-grey">Réinitialiser</span>
                              </button>
                            </div>
                            <div v-if="obj.bgColor === 'bg-info'">
                              <b-icon icon="pencil-square" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-danger'">
                              <b-icon icon="exclamation-triangle" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-primary'" class="mt-1">
                              <b-icon icon="check-circle" font-scale="1.5" variant="white"></b-icon>
                            </div>
                            <div v-else-if="obj.bgColor === 'bg-warning'">
                              <b-icon icon="person" font-scale="1.5" variant="white"></b-icon>
                            </div>
                          </div>
                        </div>

                        {{ /* INPUTS */ }}
                        <div class="inputs mt-3">
                          <div class="input-group">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control border-0"
                                v-model="obj.totalWithoutVat"
                                placeholder="CA"
                                v-on:keyup="onChangeInputWithoutVat($event, listByDay[index])"
                                v-on:focusout="onFocusInputWithoutVat()"
                                v-on:blur="incrementCountFillCardSales(obj)"
                            >
                            <input type="hidden" v-model="obj.userWithoutVat">
                            <div class="input-group-append">
                              <div class="input-group-text text-grey bg-white border-0">HT</div>
                            </div>
                          </div>

                          <div class="input-group mt-2">
                            <input
                                type="number"
                                step="0.01"
                                class="form-control border-0"
                                v-model="obj.totalWithVat"
                                placeholder="CA"
                                v-on:keyup="onChangeInputWithVat($event, listByDay[index])"
                                v-on:focusout="onFocusInputWithVat()"
                                v-on:blur="incrementCountFillCardSales(obj)"
                            >
                            <input type="hidden" v-model="obj.userWithVat">
                            <div class="input-group-append">
                              <div class="input-group-text text-grey bg-white border-0">TTC</div>
                            </div>
                          </div>
                        </div>
                        {{ /* ERROR ON SALES */ }}
                        <div class="text-light text-center" v-if="obj.error">
                          <b-alert class="p-0 mt-1" show variant="danger">{{ obj.error }}</b-alert>
                        </div>

                        <div class="total-mobile mt-4 text-white">
                          <div class="d-flex justify-content-center">
                            {{ (obj.totalWithoutVat) ? obj.totalWithoutVat + ' €' : '' }}
                            <span class="ml-2">{{ (obj.totalWithoutVat) ? ' (HT)' : '' }}</span>
                          </div>
                          <div class="d-flex justify-content-center">
                            {{ (obj.totalWithVat) ? obj.totalWithVat + ' €' : '' }}
                            <span class="ml-2">{{ (obj.totalWithVat) ? ' (TTC)' : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* INPUTS MOBILE */ }}
    <div
        v-if="card.index !== null"
        class="card border-0 app-box-shadow p-2 sale-mobile position-fixed"
        :class="(card.bgColor !== null) ? card.bgColor : 'bg-green-dark'"
    >
      <div class="header-sale-mobile mb-4">
        <div class="d-flex justify-content-end">
          <b-icon
              v-on:click="closeBlockSaleMobile"
              class=""
              icon="x-square"
              font-scale="1.5"
              variant="danger"
          ></b-icon>
        </div>

        <div class="text-center">
          <div class="d-flex justify-content-center">
            <div>
              <b-icon
                  v-on:click="
                    (card.index > 0) ? card.index -= 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                  class="button-period mr-3 p-2"
                  icon="chevron-left"
                  font-scale="2.5"
                  :variant="(card.index > 0) ? 'light' : 'secondary'"
              ></b-icon>
            </div>
            <div class="text-center mt-2 mr-4 ml-4 text-capitalize">
              {{ card.date | moment('dddd DD MMMM YYYY') }}
            </div>
            <div>
              <b-icon
                  v-on:click="
                    (card.index < listByDay.length - 1) ? card.index += 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                  class="button-period mr-3 p-2"
                  icon="chevron-right"
                  font-scale="2.5"
                  :variant="(card.index < listByDay.length - 1) ? 'light' : 'secondary'"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="inputs mt-3">
        <div class="input-group">
          <input
              type="number"
              step="0.01"
              class="form-control border-0"
              v-model="card.totalWithoutVat"
              placeholder="CA"
              v-on:keyup="onChangeInputWithoutVat($event, card)"
              v-on:blur="incrementCountFillCardSales(card)"
          >
          <input type="hidden" v-model="card.userWithoutVat">
          <div class="input-group-append">
            <div class="input-group-text text-grey bg-white border-0">HT</div>
          </div>
        </div>

        <div class="input-group mt-2">
          <input
              type="number"
              step="0.01"
              class="form-control border-0"
              v-model="card.totalWithVat"
              placeholder="CA"
              v-on:keyup="onChangeInputWithVat($event, card)"
              v-on:blur="incrementCountFillCardSales(card)"
          >
          <input type="hidden" v-model="card.userWithVat">
          <div class="input-group-append">
            <div class="input-group-text text-grey bg-white border-0">TTC</div>
          </div>
        </div>
      </div>
    </div>

    <div class="action-sale position-fixed" v-if="!SaleModule.shop.isMonth">
      <div class="d-flex justify-content-between action-sale-content">
        <div class="card w-25 mt-2 ml-2 h-25 total-month-desktop">
          <div class="card-body">
            <h6 class="text-center">
              Total du mois en cours : <span class="text-info text-capitalize"><b>{{ period.monthStr }} {{ period.year }}</b></span>
            </h6>

            <div class="text-center mt-2 mb-2" v-if="loader.isLoading">
              <b-spinner variant="success" label="Spinning"></b-spinner>
              <div class="text-primary">Chargement en cours ...</div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-between">
            <span class="big-text">
              {{ totalMonthWithoutVat }} €
            </span>
                <span class="big-text ml-4">H.T</span>
              </div>

              <div class="d-flex justify-content-between">
            <span class="big-text">
              {{ totalMonthWithVat }} €
            </span>
                <span class="big-text ml-4">T.T.C</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
              class="btn btn-success mr-5 mt-5 btn-lg"
              v-on:click="initSubmit(false)"
          >
            Valider la saisie chiffre d'affaires
          </button>
        </div>
      </div>
      <div class="div-modal">
        <b-modal
            :key="componentKey + 104"
            id="days-not-filled-modal"
            ref="modal"
            title="Attention"
            v-model="notFilledDays"
            @ok=close()
            :hide-header-close="true"
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            header-bg-variant="danger"
            header-text-variant="light"
            body-bg-variant="light"
            footer-bg-variant="light"
        >
          Vous êtes en train de renseigner un CA pour un jour alors que un ou plusieurs jours antérieurs ne sont pas
          remplis, n'oubliez pas de préciser 0 en CA pour les jours de fermeture.
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()">
              Ok
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'
import SaleService from '@/services/SaleService'

export default {
  data() {
    return {
      notFilledDays: false,
      componentKey: 0,
      showHelpMobile: false,
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      totalMonthWithVat: null,
      currentInputValueWithVat: null,
      totalMonthWithoutVat: null,
      currentInputValueWithoutVat: null,
      userCanEdit: this.userCanEditComputed,
      errorFormSales: false,
      saleError: {
        isError: false,
        message: null
      },
      card: {
        id: null,
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        vatRate: null,
        date: null,
        index: null,
        bgColor: null
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: new Date(new Date()), // Disable all dates after specific date
        },
      },
      page: {
        doubleEntryMessage:
            'Attention votre magasin est en double saisie, merci de bien remplir les deux champs du formulaire pour chaque ligne.'
        ,
      },
      period: {
        month: this.handleDate(0, 'MM'),
        monthStr: this.handleDate(0, 'MMMM'),
        monthSubtract: 0,
        dateLimit: null,
        year: this.handleDate(0, 'YYYY')
      },
      listByDay: [],
      errorSales: false,
      countFillCardSales: this.incrementFillCardSalesComputed,
      saleModel: {
        id: null,
        date: null,
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        vatRate: null,
        bgColor: null
      },
      askForHelp: {
        isEmailSend: false,
        clicked: false,
        form: {
          phone: null,
          email: null,
          error: null
        }
      },
    }
  },
  created() {
    this.prepareDataPage()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    allPreviousDaysAreFilled(days, index) {
      let filled = true;
      days.forEach((day) => {
        if (days.indexOf(day) < index && (day.totalWithoutVat === null || day.totalWithVat === null)) {
          filled = false;
        }
      })
      return filled;
    },
    /**
     * Toggle active on card
     *
     * @param index
     * @param sale
     */
    cardActiveEvent(index, sale) {
      // Set obj card
      this.card.id = (sale.id) ? sale.id : null
      this.card.index = index
      this.card.date = sale.date
      this.card.vatRate = (sale.vatRate) ? sale.vatRate : null
      this.card.totalWithoutVat = (sale.totalWithoutVat) ? sale.totalWithoutVat : null
      this.card.totalWithVat = (sale.totalWithVat) ? sale.totalWithVat : null
      this.card.userWithoutVat = (sale.userWithoutVat) ? sale.userWithoutVat : null
      this.card.userWithVat = (sale.userWithVat) ? sale.userWithVat : null
      this.card.bgColor = (sale.bgColor) ? sale.bgColor : 'bg-green-dark'
    },
    /**
     * Nav between card
     */
    navBlockSaleMobile(index) {
      this.card.index = index
      this.card.date = this.listByDay[index].date
      this.card.vatRate = (this.listByDay[index].vatRate) ? this.listByDay[index].vatRate : null
      this.card.totalWithoutVat = (this.listByDay[index].totalWithoutVat) ? this.listByDay[index].totalWithoutVat : null
      this.card.totalWithVat = (this.listByDay[index].totalWithVat) ? this.listByDay[index].totalWithVat : null
      this.card.userWithoutVat = (this.listByDay[index].userWithoutVat) ? this.listByDay[index].userWithoutVat : null
      this.card.userWithVat = (this.listByDay[index].userWithVat) ? this.listByDay[index].userWithVat : null
      this.card.bgColor = (this.listByDay[index].bgColor) ? this.listByDay[index].bgColor : 'bg-green-dark'
    },
    /**
     * Close block sale mobile
     */
    closeBlockSaleMobile() {
      this.card = {
        totalWithVat: null,
        totalWithoutVat: null,
        userWithVat: null,
        userWithoutVat: null,
        date: null,
        index: null,
        bgColor: null
      }
    },
    initSubmitMobile() {
      // Send create
      this.$store.dispatch('createOrUpdateSales', {
        data: [this.card],
        shopId: this.SaleModule.shop.id,
        userId: this.AuthModule.currentUser.id
      })
          .then(() => {
            // Fetch Sales
            this.$store.dispatch('fetchSales', {
              month: this.period.month,
              year: this.period.year,
              shopId: this.$route.params.id
            })
                .then(() => {
                  // ListByDay
                  this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                  // Increment month total
                  this.incrementTotalMonthWithVat(this.card.totalWithVat)
                  this.incrementTotalMonthWithoutVat(this.card.totalWithoutVat)

                  // Hide loader
                  this.hideLoader()
                })
          })
    },
    /*----------------- GET DATA FROM API ------------------*/
    prepareDataPage() {
      // Display loader
      this.showLoader()

      // set default date for datepicker
      if (this.$store.getters.userIsAdmin) {
        let dateObj = new Date()
        this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')
      }

      // Key component
      this.componentKey += 1

      // Set period
      this.period.month = this.handleDate(0, 'MM')
      this.period.year = this.handleDate(0, 'YYYY')
      this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

      // Fetch Shop
      this.$store.dispatch('fetchSalesShop', this.$route.params.id)

      // Fetch Sales for previous month
      if (this.$store.getters.userIsShop) {
        this.$store.dispatch('fetchSales', {
          month: this.handleDate(1, 'MM'),
          year: this.handleDate(1, 'YYYY'),
          shopId: this.$route.params.id
        })
            .then(() => {
              // set default date for datepicker
              let dateObj = new Date(this.handleDate(1, 'YYYY-MM-DD'))
              this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

              let getLastDayInPreviousMonth = this.handleDate(1, 'YYYY-MM-DD')

              if (this.$store.getters.getCountSales !== moment(getLastDayInPreviousMonth).daysInMonth()) {
                this.changePeriodSales(
                    Number(this.handleDate(1, 'MM')),
                    this.handleDate(1, 'MMMM'),
                    0,
                    Number(this.handleDate(1, 'YYYY'))
                )

                // ListByDay
                this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                // Count list sales
                this.countFillCardSales = this.$store.getters.getCountSales

                // Sale model
                this.saleModel = this.setModelSaleComputed

                // Total Month
                this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
                this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

              } else {
                this.fetchSalesAndSetVar()
              }

              // Hide loader
              this.hideLoader()
            })
      } else {
        this.fetchSalesAndSetVar()
      }
    },
    fetchSalesAndSetVar() {
      this.$store.dispatch('fetchSales', {
        month: this.period.month,
        year: this.period.year,
        shopId: this.$route.params.id
      })
          .then(() => {
            // set default date for datepicker
            let dateObj = new Date(this.handleDate(0, 'YYYY-MM-DD'))
            this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

            // ListByDay
            this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

            // Count list sales
            this.countFillCardSales = this.$store.getters.getCountSales

            // Sale model
            this.saleModel = this.setModelSaleComputed

            // Total Month
            this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
            this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

            // Hide loader
            this.hideLoader()
          })
    },
    /*----------------- DATE ------------------*/
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      let dateObj = new Date(date)
      return moment(dateObj).format('MMMM yyyy');
    },
    /**
     * Get date period
     *
     * @param format
     */
    getDatePeriod(format) {
      let dateObj = new Date(this.period.year + '-' + this.period.month + '-01')
      return moment(dateObj).format(format)
    },
    handleDate($monthToSubtract, format) {
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')
      let newDate = moment(currentDate).subtract($monthToSubtract, 'month')

      return newDate.format(format)
    },
    getDateLimitPeriod(dateLimitSale, format) {
      let constructDateLimitDay = (dateLimitSale < 10) ? '0' + dateLimitSale : dateLimitSale
      let constructDate = this.period.year + '-' + this.period.month + '-' + constructDateLimitDay

      let dateObj = new Date(String(constructDate))
      let date = moment(dateObj)
      let newDate = moment(date).add(1, 'month')

      return newDate.format(format)
    },
    /**
     * List days of current month
     *
     * @returns {[]}
     */
    getDaysInMonth(month, year) {
      // current date for late sale
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')

      // init var
      let dayArray = []
      let strDate = new Date(year + '-' + month + '-01')

      // Set start and end date
      let startDayMonth = moment(strDate).format('YYYY-MM-DD')
      let endDayMonth = moment(strDate).format('YYYY-MM-') + moment(strDate).daysInMonth()

      // For loop between start and end day
      while (startDayMonth <= endDayMonth) {
        // Find sale by date
        let findSales = this.SaleModule.sales.find(item => item.date === startDayMonth)

        let model = {
          date: startDayMonth,
          id: (findSales !== undefined) ? findSales.id : null,
          totalWithVat: (findSales !== undefined) ? findSales.totalWithVat : null,
          totalWithoutVat: (findSales !== undefined) ? findSales.totalWithoutVat : null,
          userWithVat: (findSales !== undefined) ? (findSales.userWithVat) ? findSales.userWithVat.id : null : null,
          userWithoutVat: (findSales !== undefined) ? (findSales.userWithoutVat) ? findSales.userWithoutVat.id : null : null,
          vatRate: (findSales !== undefined) ? findSales.vatRate : null,
          bgColor: (findSales !== undefined) ? this.handleColorInput(findSales, true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
          error: null
        }

        dayArray.push(model)
        startDayMonth = moment(startDayMonth).add(1, 'days').format('YYYY-MM-DD')
      }

      return dayArray
    },
    /**
     * Handle color input
     *
     * @param sale
     * @param isVat
     */
    handleColorInput(sale, isVat) {
      // current date for late sale
      let currentDate = moment().format('YYYY-MM-DD')

      if (sale !== undefined) {
        if (isVat) {
          if (sale.userWithVat != null) {
            // IsMonth or daily
            if (sale.userWithVat instanceof Object) {
              if (sale.userWithVat.id === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            } else {
              if (sale.userWithVat === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            }
          } else {
            if (currentDate > this.period.dateLimit) {
              return 'bg-danger'
            } else {
              return ''
            }
          }
        } else {
          if (sale.userWithoutVat != null) {
            if (sale.userWithoutVat instanceof Object) {
              if (sale.userWithoutVat.id === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            } else {
              if (sale.userWithoutVat === this.AuthModule.currentUser.id) {
                return 'bg-primary'
              } else {
                return 'bg-warning'
              }
            }
          } else {
            if (currentDate > this.period.dateLimit) {
              return 'bg-danger'
            } else {
              return ''
            }
          }
        }
      }
    },
    /*----------------- Change period ------------------*/
    changePeriodSales($month, $monthStr, $monthSubtract, $year) {
      // Display loader
      this.showLoader()

      // Close active card
      this.card.index = null

      // Change period
      this.period.month = ($month < 10) ? '0' + $month : $month
      this.period.monthStr = $monthStr
      this.period.monthSubtract = $monthSubtract
      this.period.year = $year
      this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

      // Fetch Sales
      this.$store.dispatch('fetchSales', {
        month: this.period.month,
        year: this.period.year,
        shopId: this.$route.params.id
      })
          .then(() => {
            // ListByDay
            this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

            // Sale model
            this.saleModel = this.setModelSaleComputed
            // Count list sales
            this.countFillCardSales = this.$store.getters.getCountSales
            // Total Month
            this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
            this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

            // Hide loader
            this.hideLoader()
          })
    },
    /*----------------- On change input ------------------*/
    onChangeInputWithVat($event, sale) {
      if ($event.target.value) {
        if (!this.SaleModule.shop.doubleSaleEntry) {
          // Refresh totalWithoutVat
          sale.totalWithoutVat = null
          // AutoComplete field totalWithoutVat
          sale.totalWithoutVat = this.calculationSale('withoutVat', sale.totalWithVat)

          if (!this.allPreviousDaysAreFilled(this.listByDay, this.listByDay.indexOf(sale))) {
            this.notFilledDays = true;
          }

          // total month
          this.onFocusInputWithoutVat()
        }

        // Set user without vat
        sale.userWithoutVat = this.AuthModule.currentUser.id
        // Color input
        sale.bgColor = 'bg-info'
        // Set user
        sale.userWithVat = this.AuthModule.currentUser.id
        // handle error
        this.handleErrorSale($event, sale)
      }
    },
    onChangeInputWithoutVat($event, sale) {
      if ($event.target.value) {
        if (!this.SaleModule.shop.doubleSaleEntry) {
          // Refresh totalWithVat
          sale.totalWithVat = null
          // AutoComplete field totalWithVat
          sale.totalWithVat = this.calculationSale('withVat', sale.totalWithoutVat)

          if (!this.allPreviousDaysAreFilled(this.listByDay, this.listByDay.indexOf(sale))) {
            this.notFilledDays = true;
          }

          // total month
          this.onFocusInputWithVat()
        }

        // Set user without vat
        sale.userWithVat = this.AuthModule.currentUser.id
        // Color input
        sale.bgColor = 'bg-info'
        // Set user
        sale.userWithoutVat = this.AuthModule.currentUser.id
        // handle error
        this.handleErrorSale($event, sale)
      }
    },
    onFocusInputWithVat() {
      this.totalMonthWithVat = this.listByDay.reduce((result, sale) => {
        result += Number(sale.totalWithVat)

        return Math.round(result)
      }, 0)
    },
    onFocusInputWithoutVat() {
      this.totalMonthWithoutVat = this.listByDay.reduce((result, sale) => {
        result += Number(sale.totalWithoutVat)

        return Math.round(result)
      }, 0)
    },
    incrementTotalMonthWithVat() {
      this.onFocusInputWithVat()
    },
    incrementTotalMonthWithoutVat() {
      this.onFocusInputWithoutVat()
    },
    handleErrorSale($event, sale) {
      if (this.SaleModule.shop.doubleSaleEntry) {
        if (sale.totalWithoutVat && sale.totalWithVat) {
          // Calculation vat rate
          this.checkVatRatBetweenSales(sale)
        } else {
          sale.error = 'Merci de remplir les deux champs'
          this.errorSales = true
        }
      } else {
        if (sale.totalWithoutVat || sale.totalWithVat) {
          // Calculation vat rate
          this.checkVatRatBetweenSales(sale)
        } else {
          sale.error = 'Merci de remplir les deux champs'
          this.errorSales = true
        }
      }
    },
    incrementCountFillCardSales(sale) {
      if (sale.totalWithVat && sale.totalWithoutVat) {
        this.countFillCardSales++
      }
    },
    /*----------------- Reset sales ------------------*/
    resetSales(sale) {
      this.$swal({
        title: 'Voulez vous réinitialiser le CA ?',
        text: 'Attention : Action irréversible, Le CA actuelle sera perdu',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          SaleService.delete(sale.id)
              .then(() => {
                // Fetch Sales
                this.$store.dispatch('fetchSales', {
                  month: this.period.month,
                  year: this.period.year,
                  shopId: this.$route.params.id
                })
                    .then(() => {
                      // ListByDay
                      this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                      // Increment month total
                      this.incrementTotalMonthWithVat(this.card.totalWithVat)
                      this.incrementTotalMonthWithoutVat(this.card.totalWithoutVat)

                      // Hide loader
                      this.hideLoader()
                    })
              })
        }
      })

    },
    /*----------------- CalculationVatRate ------------------*/
    checkVatRatBetweenSales(sale) {
      let subtractPercentage = (Math.abs(sale.totalWithVat / 4)) * 3

      if (Math.abs(sale.totalWithoutVat) < subtractPercentage) {
        this.errorSales = true
        sale.error = 'Merci de vérifier votre saisie, pourcentage TVA trop élevé !'
      } else {
        this.errorSales = false
        sale.error = ''
      }
    },
    /**
     * Calculation vatRate
     */
    calculationSale(saleType, total) {
      let vatPercentage = (this.SaleModule.shop.vatRate / 100) + 1

      if (saleType === 'withVat') {
        let calculationSaleWithVat = Number(total) * Number(vatPercentage)
        return Math.round((calculationSaleWithVat + Number.EPSILON) * 100) / 100
      } else {
        let calculationSaleWithoutVat = Number(total) / Number(vatPercentage)
        return Math.round((calculationSaleWithoutVat + Number.EPSILON) * 100) / 100
      }
    },
    /*----------------- DATEPICKER -------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value) {
      // Pass str to date Obj
      const dateObj = new Date(value);
      const momentObj = moment(dateObj);

      this.changePeriodSales(
          Number(momentObj.format('MM')),
          momentObj.format('MMMM'),
          0,
          Number(momentObj.format('YYYY'))
      )
    },
    /*----------------- Check error ------------------*/
    checkListError() {

      return false
    },
    /*----------------- Submit form ------------------*/
    initSubmit(isMonth) {
      // Display loader
      this.showLoader()

      if (isMonth) {
        if (this.SaleModule.shop.doubleSaleEntry && (!this.saleModel.totalWithoutVat || !this.saleModel.totalWithVat)) {
          this.loader.isLoading = false
          this.errorFormSales = 'Merci de remplir les deux champs'
        } else {
          this.errorFormSales = false

          // Send create
          this.$store.dispatch('createOrUpdateSales', {
            data: [this.saleModel],
            shopId: this.SaleModule.shop.id,
            userId: this.AuthModule.currentUser.id
          })
              .then(() => {
                // Fetch Sales
                this.$store.dispatch('fetchSales', {
                  month: this.period.month,
                  year: this.period.year,
                  shopId: this.$route.params.id
                })
                    .then(() => {
                      // Set sale model
                      this.saleModel = this.setModelSaleComputed

                      // Hide loader
                      this.hideLoader()
                    })
              })
        }
      } else {
        // Handle list by day for create
        let listForCreate = []
        this.listByDay.forEach((sale) => {
          if (sale.userWithVat !== null || sale.userWithoutVat !== null) {
            listForCreate.push(sale)
          }
        })

        // Send create
        this.$store.dispatch('createOrUpdateSales', {
          data: listForCreate,
          shopId: this.SaleModule.shop.id,
          userId: this.AuthModule.currentUser.id
        })
            .then(() => {
              this.saleError.isError = false
              this.saleError.errorMessage = null

              // Fetch Sales
              this.$store.dispatch('fetchSales', {
                month: this.period.month,
                year: this.period.year,
                shopId: this.$route.params.id
              })
                  .then(() => {
                    // ListByDay
                    this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                    // Hide loader
                    this.hideLoader()
                  })
            })
            .catch((error) => {
              this.saleError.isError = true
              this.saleError.errorMessage = error

              this.hideLoader()
            })
      }
    },
  },
  watch: {
    countFillCardSales: function (newValue) {
      this.countFillCardSales = newValue
    },
    setModelSaleComputed: function (newValue) {
      this.saleModel = newValue
    },
  },
  computed: {
    getLastDayInMonthPeriod() {
      let constructDate = this.period.year + '-' + this.period.month + '-01'
      let dateObj = new Date(constructDate)
      let date = moment(dateObj).format("YYYY-MM-DD")

      return parseInt(moment(date).daysInMonth())
    },
    /**
     * set computed sale model
     * @returns {{date: (*|null), userWithoutVat: (null|*|null), totalWithVat: (null|*|null), vatRate: (null|*|null), id: (*|null), totalWithoutVat: (null|*|null), userWithVat: (null|*|null)}}
     */
    setModelSaleComputed() {
      // current date for late sale
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')

      return {
        id: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].id : null,
        date: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].date : this.getDatePeriod('YYYY-MM-DD'),
        totalWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithVat : null,
        totalWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithoutVat : null,
        userWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithVat.id : null,
        userWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithoutVat.id : null,
        vatRate: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].vatRate : null,
        bgColor: (this.SaleModule.sales[0]) ? this.handleColorInput(this.SaleModule.sales[0], true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
      }
    },
    userCanEditComputed() {
      return !!this.$store.getters.userIsAdmin;
    },
    upperCaseMonthStr() {
      return this.period.monthStr.toUpperCase()
    },
    ...mapState(['ChannelModule']),
    ...mapState(['AuthModule']),
    ...mapState(['SaleModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
}
</script>

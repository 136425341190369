/**
 * builder url for api call
 * @param originUrl
 * @param centerId
 * @param model
 * @returns {string}
 */
export default function builderUrl(originUrl, centerId, model) {
  let url = '/' + originUrl

  let urlParamsPart = '';
  if (model && model.perPage)
    urlParamsPart += '?_limit=' + model.perPage + '&_page=' + model.page
  if (model && model.order)
    urlParamsPart += (urlParamsPart.length < 1) ? '?': '&' + '_index' + model.order.index +'&_order=' + model.order.order

  if (model && model.search)
    urlParamsPart += '&_search=' + model.search
  if (model && model.date)
    urlParamsPart += '?_month=' + model.date.month + '&_year=' + model.date.year
  if (centerId) {
    let getBuilder = (urlParamsPart.length < 1) ? '?': '&'
    urlParamsPart += getBuilder + '_centerId=' + centerId
  }

  url = url + urlParamsPart
  return url
}

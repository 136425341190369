import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get List CNCC for a year
   *
   * @param routeParams
   * @returns {*}
   */
  getCnccPeriod(routeParams)
  {
    return AxiosService.callApi().get('/cncc?_year=' + routeParams.year)
  },
  getCnccCumulativePeriod(routeParams)
  {
    return AxiosService.callApi().get('/cncc/cumulative?_year=' + routeParams.year)
  },
  /**
   * Create Cncc
   *
   * @param data
   * @returns {*}
   */
  create(data)
  {
    return AxiosService.callApi().post('/cncc', data)
  },
  csvCncc(data) {
    return AxiosService.callCsvDownloadApiRoute().post('/cncc/csv', data);
  },
}

<template>
  <div id="center-cncc-page">
    {{/* HEADING PAGE */}}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="bar-chart" font-scale="1.5" variant="primary"></b-icon>
        Indice CNCC
      </h3>

      <div>
        <button
            class="btn btn-dark btn-sm"
            v-on:click="eventClickExportCsv()"
        >
          Export CSV
        </button>
      </div>

    </div>

    <hr />

    {{/* PERIOD */}}
    <div class="d-flex justify-content-center">
      {{ /* BUTTON CHANGE PERIOD */ }}
      <div class="p-2 h-25 w-25 mb-2">
        <div class="text-center">
          <Datepicker
              @selected="clickDatePicker"
              id="date-cncc-year"
              :format="formatDatepicker"
              :minimumView="'year'"
              :maximumView="'year'"
              :language="datepicker.language"
              :value="datepicker.defaultDate"
              input-class="mt-2 date-select"
              :disabledDates="datepicker.disabledDates"
              class="text-center"
              placeholder="Choisir une année"
          ></Datepicker>
        </div>
      </div>
    </div>

    {{/* CARD BY MONTH */}}
    <div class="">
      <form ref="form-flux" class="form-cncc w-100">
        <div class="d-flex flex-wrap mt-2 w-100">
          <div
              class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-0"
              v-for="(obj, mainIndex) in period.listMonth" :key="mainIndex"
          >
            <div class="card border-0 mt-3 mb-4 mr-4 app-box-shadow">
              {{ /* DATE AND ICONS */ }}
              <div class="card-header border-0 d-flex justify-content-between bg-card-header">
                <div class="date w-100 text-center">
                  <div class="flux-date-desktop font-weight-bold" >
                    {{ obj.monthStr.toUpperCase() + ' ' + period.year }}
                  </div>
                </div>
              </div>
              <div class="card-body border-0">
                {{/* INPUTS */}}
                <div class="cncc-flux">
                  <label class="mt-2" for="input-cncc-flux">
                    <span class="text-info">Indice CNCC Fréquentation</span>
                  </label>
                  <div id="input-cncc-flux" class="input-group mb-2">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control"
                        v-model="obj.values[0].value"
                        v-on:change="onChangeInputCncc($event, obj, mainIndex, true)"
                        placeholder="Rentrer un indice CNCC Fréquentation"
                    >
                  </div>
                </div>
                <div class="cnnc-sales">
                  <label class="mt-2" for="input-cncc-sales">
                    <span class="text-success">Indice CNCC CA</span>
                  </label>
                  <div id="input-cncc-sales" class="input-group mb-2">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control"
                        v-model="obj.values[1].value"
                        v-on:change="onChangeInputCncc($event, obj, mainIndex, false)"
                        placeholder="Rentrer un indice CNCC CA"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {{/* CARD FOR FULL YEAR */}}
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap mt-2 w-100">
            <div
              class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-0"
            >
              <div class="card border-0 mt-3 mb-4 mr-4 a">
                {{ /* DATE AND ICONS */ }}
                <div class="card-header border-0 d-flex justify-content-between bg-white">
                  <div class="date w-100 text-center">
                    <div class="flux-date-desktop" >
                      Indice Cumulé année {{ period.year }}
                    </div>
                  </div>
                </div>
                <div class="card-body border-0">
                  {{/* INPUTS */}}
                  <div class="cncc-flux">
                    <label class="mt-2" for="input-cncc-flux-year">
                      <span class="text-info">Indice cumulé Fréquentation</span>
                    </label>
                    <div id="input-cncc-flux-year" class="input-group mb-2">
                      <input
                          type="number"
                          step="0.01"
                          class="form-control"
                          v-model="cnccCumulative.cumulativeValues[0].value"
                          v-on:change="onChangeInputCnccCumulative($event,true)"
                          placeholder="Rentrer un indice CNCC Fréquentation"
                      >
                    </div>
                  </div>
                  <div class="cnnc-sales">
                    <label class="mt-2" for="input-cncc-sales-year">
                      <span class="text-success">Indice cumulé CA</span>
                    </label>
                    <div id="input-cncc-sales-year" class="input-group mb-2">
                      <input
                          type="number"
                          step="0.01"
                          class="form-control"
                          v-model="cnccCumulative.cumulativeValues[1].value"
                          v-on:change="onChangeInputCnccCumulative($event,false)"
                          placeholder="Rentrer un indice CNCC CA"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {{/* SUBMIT */}}
          <div>
            <button class="btn btn-success mt-2 mr-4 w-100" v-on:click.prevent="onSubmit">
              Valider les indices CNCC
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { fr } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import axios from "axios";
import exportService from "@/services/ExportService";

export default {
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        disabledDates: {
          to: null, // Disable all dates up to specific date
          from: new Date(new Date()), // Disable all dates after specific date
        },
      },
      period : {
        year : this.handleDate(0, 'YYYY'),
        listMonth: []
      },
      card: {
        index: null,
        bgColor: null
      },
      cnccCumulative: {
        cumulativeValues: [
          {
            value: null,
            type: null,
            year: this.handleDate(0, 'YYYY'),
          },
          {
            value: null,
            type: null,
            year: this.handleDate(0, 'YYYY'),
          }
        ]
      }
    }
  },
  created() {
    // set default date for datepicker
    let dateObj = new Date()
    this.datepicker.defaultDate = moment(dateObj).format('YYYY')
    // Construct list month
    this.period.listMonth = this.handleListMonth(this.period.year)
    // Fetch List CNCC
    this.$store.dispatch('fetchCncc', this.period)
      .then((response) => {
        this.fillListCncc(response)
      })

    // Fetch CNCC Cumulative
    this.$store.dispatch('fetchCnccCumulative', this.period)
      .then((response) => {
        this.fillCnccCumulative(response)
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader()
    {
      this.loader.isLoading = true
    },
    hideLoader()
    {
      this.loader.isLoading = false
    },
    /*----------------- PERIOD ----------------*/
    /**
     * Get date period
     *
     * @param format
     */
    getDatePeriod(format)
    {
      let dateObj = new Date(this.period.year + '-' + this.period.month + '-01')
      return moment(dateObj).format(format)
    },
    handleDate($monthToSubtract, format)
    {
      let dateObj = new Date()
      let currentDate = moment(dateObj).format('YYYY-MM-DD')
      let newDate = moment(currentDate).subtract($monthToSubtract, 'month')

      return newDate.format(format)
    },
    /*----------------- DATEPICKER -------------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)
      var momentObj = moment(dateObj)

      this.changePeriod(
          Number(momentObj.format('YYYY'))
      )
      this.period.listMonth = this.handleListMonth(Number(momentObj.format('YYYY')))

      // Fetch List CNCC
      this.$store.dispatch('fetchCncc', this.period)
        .then((response) => {
          this.fillListCncc(response)
        })
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date)
    {
      let dateObj = new Date(date)
      return moment(dateObj).format('yyyy');
    },
    /*----------------- PERIOD ----------------*/
    changePeriod(year)
    {
      // Display loader
      this.showLoader()

      // Change period
      this.period.year = year
      this.period.listMonth = this.handleListMonth(year)

      // Fetch CNCC Cumulative
      this.$store.dispatch('fetchCnccCumulative', this.period)
        .then((response) => {
          this.fillCnccCumulative(response)
        })

      this.hideLoader()
    },
    handleListMonth(year)
    {
      let listMonth = []

      for (let i = 1; i <= 12; i++) {
        let date = new Date(year + '-' + i + '-01')
        let dateMoment = moment(date)
        listMonth.push({
          month: Number(dateMoment.format('MM')),
          monthStr: dateMoment.format('MMMM'),
          date: dateMoment.format('YYYY-MM-DD'),
          year: year,
          values: [
            {
              value: null,
              type: null
            },
            {
              value: null,
              type: null
            }
          ],
          bgColor: null,
          isChange: false
        })
      }

      return listMonth
    },
    /*----------------- FILL LIST CNCC ----------------*/
    fillListCncc(response)
    {
      if (response.length > 0) {
        response.forEach((data) => {
          let findMonthInListCncc = this.period.listMonth.find(obj => obj.date === data.date)
          findMonthInListCncc.bgColor = 'bg-success'

          if (data.type === 'flux') {
            findMonthInListCncc.values[0].value = data.value
            findMonthInListCncc.values[0].type = data.type
          } else if (data.type === 'sale') {
            findMonthInListCncc.values[1].value = data.value
            findMonthInListCncc.values[1].type = data.type
          }
        })
      }
    },
    fillCnccCumulative(response)
    {
      this.cnccCumulative = {
        cumulativeValues: [
          {
            value: null,
            type: null,
            year: this.period.year,
          },
          {
            value: null,
            type: null,
            year: this.period.year
          }
        ]
      }
      if (response.length > 0) {
        response.forEach((data) => {
          if (data.type === 'flux') {
            this.cnccCumulative.cumulativeValues[0].value = (data.value) ? data.value : null
            this.cnccCumulative.cumulativeValues[0].type = (data.type) ? data.type : null
            this.cnccCumulative.cumulativeValues[0].year = (data.year) ? data.year : null
          } else if (data.type === 'sale') {
            this.cnccCumulative.cumulativeValues[1].value = (data.value) ? data.value : null
            this.cnccCumulative.cumulativeValues[1].type = (data.type) ? data.type : null
            this.cnccCumulative.cumulativeValues[1].year = (data.year) ? data.year : null
          }
        })
      }
    },
    /*----------------- ON CHANGE -------------------*/
    onChangeInputCncc($event, obj, index, isFlux)
    {
      // Set if the value has change
      this.period.listMonth[index].isChange = obj.value !== 0

      // Set value
      if (isFlux) {
        this.period.listMonth[index].values[0].value = $event.target.value
        this.period.listMonth[index].values[0].type = 'flux'
      } else {
        this.period.listMonth[index].values[1].value = $event.target.value
        this.period.listMonth[index].values[1].type = 'sale'
      }
    },
    onChangeInputCnccCumulative($event, isFlux) {
      if (isFlux) {
        this.cnccCumulative.cumulativeValues[0].value = $event.target.value
        this.cnccCumulative.cumulativeValues[0].type = 'flux'
      } else {
        this.cnccCumulative.cumulativeValues[1].value = $event.target.value
        this.cnccCumulative.cumulativeValues[1].type = 'sale'
      }
    },
    /*----------------- CSV -------------------*/
    async eventClickExportCsv() {
      // Display loader
      this.showLoader()

      // Fetch Export CSV
      await this.$store.dispatch('csvCncc', this.period)
          .then((response) => {
            if (response) {
              const fileName = 'export_cncc_' + moment().format('MM-YY')
              exportService.triggerFileDownload(response, fileName, 'text/csv')
            }

            this.hideLoader()
          })
          .catch((error) => {
            this.hideLoader()

            this.$bvToast.toast(error.message, {
              title: 'ERREUR !',
              variant: 'danger',
              solid: true
            })
          })
    },
    /*----------------- ON SUBMIT -------------------*/
    onSubmit()
    {
      // Loader
      this.showLoader()

      // Handle list by Month for create
      let listForCreate = {
        cnccValues: [],
        cnccCumulative: []
      }
      this.period.listMonth.forEach((obj) => {
        if (obj.isChange) {
          listForCreate.cnccValues.push(obj)
        }
      })

      // push cncc cumulative
      listForCreate.cnccCumulative = this.cnccCumulative.cumulativeValues

      this.$store.dispatch('createOrUpdateCncc', listForCreate)
        .then(() => {
          this.hideLoader()

          // Fetch List Flux
          this.$store.dispatch('fetchCncc', this.period)
            .then((response) => {
              this.fillListCncc(response)

              this.$bvToast.toast('Les indices CNCC ont été mis à jour', {
                title: 'Changement indice(s) CNCC',
                variant: 'success',
                solid: true
              })
            })
        })
        .catch(() => {
          this.hideLoader()
        })
    }
  }
}
</script>

import AxiosService from '@/services/AxiosService'
import axios from 'axios'
import moment from 'moment'

export default {
  /**
   * Get shops with export data
   * @returns {*}
   */
  csvShopsExport(data)
  {
    return AxiosService.callCsvDownloadApiRoute().post('/export/csv/shops', data)
  },
  csvFluxExport(data)
  {
    return AxiosService.callCsvDownloadApiRoute().post('/export/csv/flux', data)
  },
  csvStatsExport(data)
  {
    return AxiosService.callApi().post('/export/csv/stats', data)
  },
  /**
   * get shops with export data for marketing
   *
   * @returns {*}
   */
  postShopsExportMarketing(data)
  {
    return AxiosService.callApi().post('/export/marketing', data)
  },
  postShopsExportCA(data)
  {
    return AxiosService.callApi().post('/export/ca', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportCats(data)
  {
    return AxiosService.callApi().post('/export/monthly/html/categories', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportCats(data)
  {
    return AxiosService.callPdfDownloadApiRoute().post('/export/pdf/categories', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportShops(data)
  {
    return AxiosService.callApi().post('/export/monthly/html/shops', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportShops(data)
  {
    return AxiosService.callPdfDownloadApiRoute().post('/export/pdf/shops', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportStatsCa(data)
  {
    return AxiosService.callApi().post('/export/stats/html/statsCa', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportStatsCa(data)
  {
    return AxiosService.callPdfDownloadApiRoute().post('/export/pdf/statsCa', data)
  },
  /**
   *
   * @param data
   * @returns {*}
   */
  getHtmlExportFlux(data)
  {
    return AxiosService.callApi().post('/export/stats/html/flux', data)
  },
  /**
   * Send new html to API
   *
   * @param data
   */
  sendHtmlExportFlux(data)
  {
    return AxiosService.callPdfDownloadApiRoute().post('/export/pdf/flux', data)
  },
  sendListExportComparative(data)
  {
    return AxiosService.callPdfDownloadApiRoute().post('/export/pdf/comparative', data)
  },
  exportMarketingXlsx(data)
  {
    return axios({
      url: process.env.VUE_APP_API_URL + '/export/marketing',
      method: 'POST',
      responseType: 'blob',
      data: data,
      headers: {
        common: {
          Accept: 'application/xlsx'
        },
        patch: {
          'Content-Type': 'application/xlsx'
        },
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      }
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/xlsx' }))
        var fileLink = document.createElement('a')

        let filename = 'export-marketing' + moment().format('YYYY-MM-DD') + '.xlsx'

        fileLink.href = fileURL
        fileLink.setAttribute(
            'download',
          filename
        )
        document.body.appendChild(fileLink)
        fileLink.click();

        return Promise.resolve(true)
      })
  },
  exportCAXlsx(data)
  {
    return axios({
      url: process.env.VUE_APP_API_URL + '/export/ca',
      method: 'POST',
      responseType: 'blob',
      data: data,
      headers: {
        common: {
          Accept: 'application/xlsx'
        },
        patch: {
          'Content-Type': 'application/xlsx'
        },
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      }
    })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/xlsx' }))
          var fileLink = document.createElement('a')

          let filename = 'export-ca' + moment().format('YYYY-MM-DD') + '.xlsx'

          fileLink.href = fileURL
          fileLink.setAttribute(
              'download',
              filename
          )
          document.body.appendChild(fileLink)
          fileLink.click();

          return Promise.resolve(true)
        })
  },
  triggerFileDownload(responseFile, fileName, type) {
    // DIRECT DOWNLOAD
    const file = new Blob(
        [responseFile],
        {type: type});
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    var fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute(
        'download',
        fileURL
    )
    document.body.appendChild(fileLink)
    fileLink.download = fileName
    fileLink.click();
  }
}

<template>
  <div id="calendar-index">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{/* HEADING PAGE */}}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="calendar3" font-scale="1.5" variant="primary"></b-icon>
        Calendrier des événements
      </h3>

      {{/* LEGEND */}}
      <div class="d-flex">
        <Legend></Legend>

        <div class="calendar-filter">
          <b-dropdown
              class="dropdown-filter-calendar"
              :text="filter.title"
              ref="dropdown"
              variant="info"
          >
            <b-dropdown-item
                :value="0"
                :key="0"
                v-on:click="changeFilterCalendar(null)"
            >
              <span class="small-text">Tout les événéments</span>
            </b-dropdown-item>
            <b-dropdown-item
                v-for="(filterObj) in filter.list"
                :value="filterObj.id"
                :key="filterObj.slug"
                v-on:click="changeFilterCalendar(filterObj)"
            >
              {{ filterObj.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="list-event ml-3">
          <router-link class="btn btn-dark" :to="{ name: 'calendar-events', params: { date: this.period.date.dateCalendar } }">
            Liste des événements N-1
          </router-link>
        </div>
      </div>
    </div>

    <hr />

    <div class="datepicker-calendar-container position-relative">
      <Datepicker
          @selected="clickDatePicker"
          class="mt-1 mr-2 mr-md-0"
          id="datepicker-calendar"
          :format="formatDatepicker"
          :minimumView="'month'"
          :maximumView="'month'"
          :language="datepicker.language"
          :value="datepicker.defaultDate"
          input-class="text-center date-select"
      ></Datepicker>
      <FullCalendar
          ref="calendarEvents"
          :options="calendarOptions"
      />
    </div>


    {{/* MODAL DATA */}}
    <ModalData
      :selectedEvent="selectedEvent"
      @emit-edit-action="editModalData"
      @emit-delete-action="deleteModalData"
      @emit-close-modal="closeModalData"
    />
    {{/* MODAL FORM */}}
    <ModalForm
      :start-date="this.dateClick.startDate"
      :end-date="this.dateClick.endDate"
      :is-edit="isEditEvent"
      :selectedEvent="selectedEvent"
      :selectedEventType="(selectedEvent.type) ? selectedEvent.type.id : null"
      @emit-close-modal="closeModalForm"
      @emit-submit-form="onSubmit"
    />
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
import frLocale from '@fullcalendar/core/locales/fr'

import moment from 'moment'
import {mapState} from 'vuex'
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    ModalData: () => import('@/components/calendarEvent/ModalData'),
    ModalForm: () => import('@/components/calendarEvent/ModalForm'),
    Legend: () => import('@/components/calendarEvent/Legend'),
    Datepicker: () => import('vuejs-datepicker'),
    Loading: () => import('vue-loading-overlay')
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        year: null,
        disabledDates: {
          from: new Date(new Date().getFullYear() -1, 0, 1),
        }
      },
      period: {
        type: 'dayGridMonth',
        date: {
          start: null,
          end: null,
          dateCalendar: null
        }
      },
      filter: {
        title: 'Filtres d\'événements',
        type: null,
        list: []
      },
      legend: {
        show: false
      },
      calendarOptions: {
        aspectRatio: 2.5,
        plugins: [
          DayGridPlugin,
          TimeGridPlugin,
          InteractionPlugin,
          ListPlugin
        ],
        locale: frLocale,
        headerToolbar: {
          left: 'today',
          center: 'title',
          right: ''
        },
        views: {
          listEvent: {
            type: 'list',
            visibleRange: {
              start: null,
              end: null
            }
          }
        },
        customButtons: {
          prev: {
            click: this.prevButton,
          },
          next: {
            click: this.nextButton
          },
          today: {
            text: "Aujourd'hui",
            click: this.todayButton
          },
          dayGridMonth: {
            text: 'Mois',
            click: this.headerButton
          },
          timeGridWeek: {
            text: 'Semaine',
            click: this.headerButton
          },
          timeGridDay: {
            text: 'Jour',
            click: this.headerButton
          }
        },
        initialView: 'dayGridMonth', // dayGridMonth, dayGridWeek, timeGridDay, listWeek,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true, // initial value
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventChange: this.onChangeEvents,
        /* you can update a remote database when these fire:
       eventAdd:
       eventChange:
       eventRemove:
       */
        events: []
      },
      selectedEvent: {},
      dateClick: {
        startDate: null,
        endDate: null
      },
      isEditEvent: null
    }
  },
  mounted() {
    // Set period
    this.handlePeriod()

    // Set default value for datepicker
    this.datepicker.defaultDate = this.handleDate(0, 'YYYY-MM')
    this.datepicker.year = Number(this.handleDate(0, 'YYYY-MM'))

    this.$store.dispatch('fetchEvents', { period: this.period })
      .then(response => {
        this.formatEvents(response)
      })

    // Fetch event types
    this.$store.dispatch('fetchEventTypes', null)
      .then((response) => {
        this.filter.list = response
      })

    // Fetch Centers
    this.$store.dispatch('fetchCenters', {
      perPage: null,
      page: null
    })

  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- DATE ------------------*/
    handleDate($yearToSubtract, format)
    {
      let currentDate = moment().format(format)
      let newDate = moment(currentDate).subtract($yearToSubtract, 'year')

      return newDate.format(format)
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      return moment(date).format('MMMM yyyy');
    },
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)

      this.showLoader()

      let calendarApi = this.$refs.calendarEvents.getApi()
      calendarApi.gotoDate(dateObj)
      this.period.date.start = moment(calendarApi.currentData.dateProfile.activeRange.start).format('YYYY-MM-DD')
      this.period.date.end = moment(calendarApi.currentData.dateProfile.activeRange.end).format('YYYY-MM-DD')
      this.period.date.dateCalendar = moment(calendarApi.getDate()).format('YYYY-MM')

      this.$store.dispatch(
          'fetchEvents',
          { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
      )
        .then((response) => {
          this.formatEvents(response)
          this.hideLoader()
        })
    },
    /*-------------GO TO DATE----------------*/
    goToDateCalendar()
    {
      let calendarApi = this.$refs.calendarEvents.getApi()
      calendarApi.gotoDate(this.period.date.dateCalendar)
    },
    /*-------------FILTER----------------*/
    changeFilterCalendar(filterObj)
    {
      if (filterObj) {
        let findFilter = this.filter.list.find(filter => filter.id === filterObj.id)
        this.filter.title = findFilter.name
        this.filter.type = findFilter

        this.showLoader()
        this.$store.dispatch('fetchEvents', { period: this.period, filter: findFilter.id })
          .then((response) => {
            this.formatEvents(response)
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
      } else {
        this.filter.title = 'Tous les événements'
        this.filter.type = null
        this.showLoader()
        this.$store.dispatch('fetchEvents', { period: this.period })
          .then((response) => {
            this.formatEvents(response)
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
      }
    },
    /*-------------PERIOD----------------*/
    handlePeriod() {
      let calendarApi = this.$refs.calendarEvents.getApi()
      this.period.date.start = moment(calendarApi.currentData.dateProfile.activeRange.start).format('YYYY-MM-DD')
      this.period.date.end = moment(calendarApi.currentData.dateProfile.activeRange.end).format('YYYY-MM-DD')
      this.period.date.dateCalendar = moment(calendarApi.getDate()).format('YYYY-MM')

      // DatePicker
      this.datepicker.defaultDate = moment(this.period.date.dateCalendar).format('YYYY-MM-DD')
      this.datepicker.year = Number(moment(this.period.date.dateCalendar).format('YYYY'))
    },
    /*-------------ON CHANGE EVENTS ACTION----------------*/
    onChangeEvents(eventsData) {
      // Set var
      let rangeDates = eventsData.event._instance.range
      let getEvent = this.calendarOptions.events.find(event => event.id === parseInt(eventsData.event._def.publicId))

      // Remove on day on rangesDates end
      rangeDates.end = moment(rangeDates.end).add(-1, 'day').format('YYYY-MM-DD')

      // Set model
      let model = {
        event: getEvent,
        range: rangeDates
      }

      // Update date event
      this.$store.dispatch('updateDateEvent', model)
        .then((response) => {
          this.$store.dispatch('fetchEvents', { period: this.period })
            .then(response => {
              this.formatEvents(response)
            })

          this.$bvToast.toast(
      'Changement de date pour l\'événement | ' + response.title + '',
      {
              title: 'Evénement mis à jour',
              variant: 'success',
              solid: true
            }
          )
        })
    },
    /*-------------CLICK BUTTON CALENDAR----------------*/
    prevButton() {
      let calendarApi = this.$refs.calendarEvents.getApi()
      calendarApi.prev()
      // Handle period
      this.handlePeriod()

      this.showLoader()
      this.$store.dispatch(
          'fetchEvents',
          { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null}
      )
        .then((response) => {
          this.formatEvents(response)
          this.hideLoader()
        })
    },
    nextButton() {
      let calendarApi = this.$refs.calendarEvents.getApi()
      calendarApi.next()
      // Handle period
      this.handlePeriod()

      this.showLoader()

      this.$store.dispatch(
          'fetchEvents',
          { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
      )
        .then((response) => {
          this.formatEvents(response)
          this.hideLoader()
        })
    },
    todayButton() {
      let calendarApi = this.$refs.calendarEvents.getApi()
      calendarApi.gotoDate(moment().format('YYYY-MM-DD'))

      // Handle period
      this.period.date.start = moment().startOf('month').format('YYYY-MM-DD')
      this.period.date.end = moment().endOf('month').format('YYYY-MM-DD')

      // DatePicker
      this.datepicker.defaultDate = moment(this.period.date.start).format('YYYY-MM-DD')
      this.datepicker.year = Number(moment(this.period.date.start).format('YYYY'))

      this.showLoader()
      this.$store.dispatch(
          'fetchEvents',
          { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
      )
          .then((response) => {
            this.formatEvents(response)
            this.hideLoader()
          })
    },
    headerButton(event) {
      // Calendar API
      let calendarApi = this.$refs.calendarEvents.getApi()
      let getTypeButton = event.target.classList[0].split('-')[1]
      calendarApi.changeView(getTypeButton)
      // Set period type
      this.period.type = getTypeButton
      // Handle period
      if(getTypeButton !== 'listWeek') {
        this.handlePeriod()
      }

      // Fetch Event
      this.$store.dispatch(
          'fetchEvents',
          { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
      )
        .then((response) => {
          this.formatEvents(response)
        })
    },
    /*-------------CLICK ACTION----------------*/
    calendarLegend(isShowLegend) {
      this.legend.show = isShowLegend
    },
    /*-------------CLICK ACTION----------------*/
    handleDateSelect(clickInfo) {
      this.dateClick.startDate = new Date(moment(clickInfo.start))
      this.dateClick.endDate = new Date(moment(clickInfo.end).add(-1, 'days'))

      this.isEditEvent = false
      this.$bvModal.show('modal-form-event')
    },
    handleEventClick(clickInfo) {
      this.selectedEvent = this.getEvent(clickInfo.event._def.publicId)
      this.$bvModal.show('modal-data-event')
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    getEvent(eventId) {
      return this.calendarOptions.events.find(event => event.id === parseInt(eventId))
    },
    /*--------------------MODALS--------------------*/
    editModalData(selectedEvent) {
      this.isEditEvent = true
      this.selectedEvent = selectedEvent
      this.$bvModal.show('modal-form-event')
    },
    deleteModalData() {
      this.$swal({
        title: 'ATTENTION !',
        text: 'Voulez vous vraiment supprimer cet événement ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.showLoader()

          this.$store.dispatch('deleteEvent', this.selectedEvent)
            .then(() => {
              this.$bvModal.hide('modal-data-event')
              this.$bvToast.toast(
                  'Supression de l\'événément avec succès',
                  {
                    title: 'Supression de l\'événément',
                    variant: 'warning',
                    solid: true
                  }
              )

              this.$store.dispatch(
                  'fetchEvents',
                  { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
              )
                  .then(response => {
                    this.formatEvents(response)
                    this.goToDateCalendar()
                  })

              this.hideLoader()
            })
            .catch(() => {
              this.hideLoader()
            })
        }
      })

    },
    closeModalData() {
      this.isEditEvent = false
      this.selectedEvent = {
        startDate: null,
        endDate: null,
        shops: []
      }
      this.dateClick.startDate = null
      this.dateClick.endDate = null
    },
    closeModalForm(isEdit) {
      if(!isEdit) {
        this.selectedEvent = {
          startDate: null,
          endDate: null,
          shops: []
        }
        this.isEditEvent = false
      }
      this.dateClick.startDate = null
      this.dateClick.endDate = null
    },
    /*--------------------MODAL FORM--------------------*/
    onSubmit(model) {
      this.showLoader()
      // Edit or create
      if (model.isEdit) {
        this.$store.dispatch('updateEvent', model.event)
          .then(() => {
            this.hideLoader()

            this.$bvModal.hide('modal-form-event')
            this.$bvModal.hide('modal-data-event')
            this.closeModalData()
            this.closeModalForm(true)

            this.$store.dispatch(
                'fetchEvents',
                { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
            )
              .then(response => {
                this.formatEvents(response)
                // Go to date calendar
                this.goToDateCalendar(this.period.date.dateCalendar)

                this.$bvToast.toast(
                    'Edition de l\'événément avec succès',
                    {
                      title: 'Edition de l\'événément',
                      variant: 'success',
                      solid: true
                    }
                )
              })
          })
          .catch(() => {
            this.hideLoader()

            this.$bvModal.hide('modal-form-event')
            this.$bvModal.hide('modal-data-event')
            this.closeModalData()
            this.closeModalForm(true)
          })
      } else {
        this.$store.dispatch('createEvent', model.event)
          .then(() => {
            this.hideLoader()

            this.$bvModal.hide('modal-form-event')
            this.$bvModal.hide('modal-data-event')
            this.$store.dispatch(
                'fetchEvents',
                { period: this.period, filter: (this.filter.type) ? this.filter.type.id : null }
            )
              .then(response => {
                this.formatEvents(response)
                // Go to date calendar
                this.goToDateCalendar(this.period.date.dateCalendar)

                this.$bvToast.toast(
                    'Création de l\'événément avec succès',
                    {
                      title: 'Création de l\'événément',
                      variant: 'success',
                      solid: true
                    }
                )
              })
          })
          .catch(() => {
            this.hideLoader()
          })
      }

    },
    formatEvents(arrayEvents) {
      this.calendarOptions.events = []

      arrayEvents.forEach((event) => {
        // Handle endDate
        let endDateObj = moment(event.endDate).add(1, 'day').format('YYYY-MM-DD')
        // Get center
        let getCenter = null
        if (event.center) {
          getCenter = this.$store.getters.getCenterById(event.center.id)
        }

        let modelEvent = {
          id: event.id,
          title: event.title,
          start: event.startDate,
          end: endDateObj,
          user: event.user,
          comment: event.comment,
          color: (getCenter) ? getCenter.color : (event.eventType) ? event.eventType.color : event.eventShopType.color,
          type: event.eventType,
          center: (event.center) ? event.center.id : null,
          category: (event.category) ? event.category.id : null,
          shops: (event.shops) ? event.shops : [],
          eventShopType: (event.eventShopType) ? event.eventShopType.id : null,
          eventDates: (event.eventDates) ? event.eventDates : null
        }

        this.calendarOptions.events.push(modelEvent)
      })
    },
  },
  computed: {
    ...mapState(['CenterModule']),
  },
}
</script>

import CompanyService from '@/services/CompanyService'

export default {
  state: {
    company: {},
    companies: [],
    total: null,
    totalRequest: null
  },
  mutations: {
    ADD_COMPANY(state, company) {
      state.companies.push(company)
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_COMPANIES_TOTAL(state, total) {
      state.total = total
    },
    SET_COMPANIES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_COMPANY(state, company) {
      state.company = company
    }
  },
  actions: {
    /**
     * Fetch in state list of companies
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchCompanies({ commit }, { perPage, page, order, search }) {
      CompanyService.getCompanies(
        (perPage) ? perPage : null,
        (page) ? page : null,
        (order) ? order : null,
        (search) ? search : null
      )
        .then(response => {
          commit('SET_COMPANIES', response.data.list)
          commit('SET_COMPANIES_TOTAL', response.data.total)
          commit('SET_COMPANIES_TOTAL_REQUEST', response.data.totalRequest)
        })
        .catch(error => {
        })
    },
    /**
     * Fetch in state a company
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCompany({ commit }, id)
    {
      CompanyService.getCompany(id)
        .then(response => {
          commit('SET_COMPANY', response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Create company
     *
     * @param commit
     * @param company
     */
    createCompany({ commit }, company)
    {
      return CompanyService.create(company)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_COMPANY', company)
          return Promise.resolve()
        })
    },
    /**
     * Update company
     *
     * @param commit
     * @param id
     * @param company
     * @returns {*}
     */
    updateCompany({ commit }, {id, company})
    {
      return CompanyService.edit(id, company)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_COMPANY', response.data)
        })
    }
  }
}

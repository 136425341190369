<template>
  <div id="page-company-list">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-md-3" icon="building" font-scale="1.5" variant="primary"></b-icon> {{ dataPage.title }}
      </h3>
      <div>
        <router-link
            class="btn btn-dark mt-2 mt-md-0"
            :to="{ name: 'company-create' }"
        >
          Créer une entreprise
        </router-link>
      </div>
    </div>

    <hr />

    <div class="d-md-flex justify-content-between mb-4">
      <div class="mt-2">
        Nombre de d'entreprise(s): {{ CompanyModule.total }}
      </div>

      <div class="search mt-3 mt-md-0">
        <div class="input-group">
          <input type="text" class="form-control border bg-white" v-model="filter.search" placeholder="Mots-clés" v-on:keyup.enter="sendResearchRequest">
          <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
          <div class="input-group-append">
            <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
              Rechercher
            </button>
          </div>
        </div>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <TableComponent
          v-if="CompanyModule.companies.length !== 0"
          :headerList="configTableComponent.headerList"
          :datas="CompanyModule.companies"
          :entityName="'center'"
          :actionList="configTableComponent.actionList"
          :isAction="true"
          @ordered="clickOrdered"
      />
      <div v-if="CompanyModule.companies.length === 0">
        <div v-if="filter.search != null">
          Votre recherche n'a pas aboutie, merci de faire une autre recherche ou de cliquer sur la croix dans le champ de recherche
        </div>
        <div v-else>
          Aucune entreprise, merci d'ajouter une entreprise, en cliquant sur le button créer une entreprise, en haut à droite de votre écran
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <vPaginate
            v-if="CompanyModule.companies.length > 0"
            :classes="bootstrapPaginationClasses"
            v-model="filter.page"
            :page-count="(CompanyModule.totalRequest > 0) ? Math.ceil(CompanyModule.totalRequest / filter.elemPerPage) : Math.ceil(CompanyModule.total / filter.elemPerPage)"
            @change="sendPaginateRequest"
        >
        </vPaginate>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        isLoading: false,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 10,
        order: {
          index: 'id',
          order: 'DESC'
        },
        search: null,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      dataPage: {
        title: 'Entreprises'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true, 'order' : 'DESC'},
          {'index' : 'name', 'name' : 'Nom', 'align' : 'left', 'filter': true},
          {'index' : 'shops', 'name' : 'Magasin(s)', 'align' : 'left', 'filter': false, 'list': true},
        ],
        actionList: [
          { 'linkName': 'Modifier', 'cssClass' : 'btn-info', 'routeName' : 'company-edit', 'params' : true },
          { 'linkName' : 'Voir', 'cssClass' : 'btn-success ml-2', 'routeName' : 'company-show', 'params' : true }
        ]
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- ORDER ------------------*/
    clickOrdered(order)
    {
      this.filter.order = order

      this.$store.dispatch('fetchCompanies', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null
      })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      this.filter.search = null

      this.$store.dispatch('fetchCompanies', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
        .then(() => {
          setTimeout(function () { this.hideLoader() }.bind(this), 250)
        })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchCompanies', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
        .then(() => {
          setTimeout(function () { this.hideLoader() }.bind(this), 250)
        })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchCompanies', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
        .then(() => {
          setTimeout(function () { this.hideLoader() }.bind(this), 250)
        })
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchCompanies', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order
    }).then(() => {
      setTimeout(function () { this.hideLoader() }.bind(this), 250)
    })
  },
  computed: {
    ...mapState(['CompanyModule'])
  },
  components: {
    TableComponent: () => import('@/components/_shared/TableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>

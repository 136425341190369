import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get list Flux on period
   *
   * @param routeParams
   * @returns {*}
   */
  getFlux(routeParams)
  {
    return AxiosService.callApi().get('/flux?_month=' + routeParams.month + '&_year=' + routeParams.year)
  },
  /**
   * Get list Flux specific on period
   *
   * @param routeParams
   * @returns {*}
   */
  getFluxSpecific(routeParams)
  {
    return AxiosService.callApi().get('/flux/specific?_month=' + routeParams.month + '&_year=' + routeParams.year)
  },
  /**
   * Create flux
   *
   * @param data
   * @returns {*}
   */
  create(data)
  {
    return AxiosService.callApi().post('/flux', data)
  },
  csvFlux(data) {
    return AxiosService.callCsvDownloadApiRoute().post('/flux/csv', data);
  },
}

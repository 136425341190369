import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get list of center
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getCenters(perPage, page, order, search) {
    let url = '/centers'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get center by id
   *
   * @param id
   * @returns {*}
   */
  getCenter(id) {
    return AxiosService.callApi().get('/centers/' + id)
  },
  /**
   * Create Center
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/centers', data)
  },
  /**
   * Edit center
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/centers/' + id, data)
  }
}

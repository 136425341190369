<template>
  <div id="page-company-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block app-box-shadow col-10 mx-auto bg-white">
      <div class="p-3 d-md-flex justify-content-between">
        <div class="w-100">
          <h5 class="w-100 mx-auto text-center mb-4 text-primary">{{ dataPage.title }}</h5>
        </div>
        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'user-list' }"
          >
            Retour
          </router-link>
        </div>
      </div>
    </div>
    {{ /* Form component */ }}
    <FormCrudCompany
        :company="company"
        :actionForm="'create'"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      dataPage: {
        title: 'Créer une entreprise'
      },
      company: {}
    }
  },
  components : {
    FormCrudCompany: () => import('@/components/pages/company/FormCrudCompany')
  }
}
</script>

<style scoped>

</style>

<template>
  <div id="page-export">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon>
        {{ dataPage.title }}
      </h3>
    </div>
    <hr/>
    <div class="row justify-content-md-center">
      <div class="col-6">
        <div class="d-flex justify-content-around mt-4 mb-5">
          {{ /* CHOOSE CENTER */ }}
          <div>
            <div class="w-100 d-flex justify-content-center">
              <b-dropdown
                  class="dropdown-form-dashboard m-auto position-relative"
                  :text="filter.center.city"
                  ref="dropdown"
                  variant="outline-secondary"
              >
                <b-dropdown-item
                    v-for="(center) in centers"
                    :value="center.id"
                    :key="center.id"
                    v-on:click="changeCenter(center)"
                >
                  {{ center.city }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          {{ /* CHOOSE CATEGORY */ }}
          <div>
            <div class="w-100 d-flex justify-content-center">
              <b-dropdown
                  class="dropdown-form-dashboard m-auto position-relative"
                  :text="filter.category.name"
                  ref="dropdown"
                  variant="outline-secondary"
              >
                <b-dropdown-item
                    v-for="(category) in categories"
                    :value="category.id"
                    :key="category.id"
                    v-on:click="changeCategory(category)"
                >
                  {{ category.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div>
            <div class="w-100 d-flex justify-content-center">
              <b-dropdown
                  class="dropdown-form-dashboard m-auto position-relative"
                  :text="filter.shop.name"
                  ref="dropdown"
                  variant="outline-secondary"
              >
                <b-dropdown-item
                    v-for="(shop) in shops"
                    :value="shop.id"
                    :key="shop.id"
                    v-on:click="changeShop(shop)"
                >
                  {{ shop.name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <ApexChart type="bar" height="350" :options="chartOptions" :series="series"></ApexChart>
  </div>
</template>
<script>

import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'
import {mapState} from "vuex";

export default {
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    ApexChart: () => import('vue-apexcharts'),
    Loading: () => import('vue-loading-overlay')
  },
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
  },
  data() {
    return {
      searchParams: {
        center: 0,
        category: 0,
        shop: 0,
        date: moment().format('YYYY-MM-DD')
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        disabledDates: {
          to: null,
          from: new Date(new Date()),
        },
      },
      apexChartFlux: [],
      apexChartStats: [],
      datepickerLanguage: fr,
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      configExport: {
        date: null,
        formatDate: 'MM/yyyy',
        isOk: false,
        showTabs: false,
        options: null,
        comparativeDate: null
      },
      dataPage: {
        title: 'Histogramme CA HT',
      },
      centers: [],
      categories: [],
      shops: [],
      selectedShops: [],
      filter: {
        center: {
          id: 0,
          city: 'Choisir un centre',
          data: null
        },
        category: {
          id: 0,
          name: 'Choisir une catégorie',
          data: null,
        },
        shop: {
          id: 0,
          name: 'Choisir un magasin',
          data: null,
        },
      },
      series: [{
        name: 'CA',
        data: []
      }],
      chartOptions: {
        colors: ["#27495C", "#37A86C"],
        legend: {
          position: 'top',
        },
        chart: {
          height: '500',
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            if (val) {
              let parts = val.toString().split(".");
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
              return parts.join(".");
            }
            return '0';
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: [],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              if (val) {
                let parts = val.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
              }
              return '0';
            }
          }
        },
        title: {
          text: '',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
    }
  },
  created() {
    let dateObj = new Date()
    this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

    // Fetch Medals
    this.fetchCategories();
    this.fetchCenters();
    this.fetchShops();
  },
  methods: {
    fetchData() {
      this.$store.dispatch('fetchRollingSales', {
        shops: this.selectedShops,
        date: this.searchParams.date
      }).then(() => {
        this.handleData(this.$store.getters.getRollingSales)
      })
    },
    handleData(data) {
      this.series = [];
      this.chartOptions.xaxis.categories = [];

      let dataSets = [];
      let newCategories = [];

      data.forEach((dataSet, index) => {
        dataSets[index] = [];
        Object.keys(dataSet).forEach((date, key) => {
          dataSets[index].push(dataSet[date]);
          newCategories[key] = this.capitalizeText(moment().month(date.split('-')[1] - 1).format('MMMM'));
        })
      })
      dataSets.map((dataSet, index) => {
        let label = !index ? "Année n-1" : "Année en cours";
        this.series = [...this.series, {name: label, data: dataSet}]
      })
      this.chartOptions = {...this.chartOptions, ...{xaxis: {categories: [...newCategories]}}}
    },
    changeShop(shop) {
      this.filter.shop = shop;
      this.selectedShops = [shop.id];
      this.fetchData();
    },
    changeCategory(category) {
      this.filter.shop = {
        id: 0,
        name: 'Choisir un magasin',
        data: null,
      };
      category = (JSON.parse(JSON.stringify(category)))
      this.searchParams.category = category.id;
      this.filter.category = category;
      this.selectedShops = this.shops = [];
      category.childs.forEach((child) => {
        this.selectedShops = [...this.selectedShops, ...child.shops.map((shop) => {
          return shop.id
        })];
        this.shops = [...this.shops, ...child.shops];
      })
      this.shops = this.shops.filter(shop => shop.center.id === this.filter.center.id && !shop.closeDate)
    },
    changeCenter(center) {
      this.filter.category = {
        id: 0,
        name: 'Choisir une catégorie',
        data: null,
      };
      this.filter.shop = {
        id: 0,
        name: 'Choisir un magasin',
        data: null,
      };
      this.searchParams.center = center.id;
      this.filter.center = center;
      this.fetchCategories(this.searchParams);
    },
    fetchCategories() {
      this.$store.dispatch('fetchParentCategories').then(() => {
        this.categories = this.$store.getters.getAllParentCat;
      })
    },
    fetchCenters() {
      this.$store.dispatch('fetchCenters', {}).then(() => {
        this.centers = this.$store.getters.getAllCenters;
        this.searchParams.center = this.centers[0].id;
      })
    },
    fetchShops() {
      this.$store.dispatch('fetchShops', {}).then(() => {
        this.shops = this.$store.getters.getShops;
      })
    },
    formatDatepicker(date) {
      let dateObj = new Date(date)
      return this.capitalizeText(moment(dateObj).format('MMMM yyyy'));
    },
    clickDatePicker(value) {
      let momentObj = moment(new Date(value));
      this.searchParams.date = momentObj.format('YYYY-MM-DD');
    },
    capitalizeText(initialText) {
      return initialText.charAt(0).toUpperCase() + initialText.slice(1);
    }
  }
}
</script>

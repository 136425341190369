<template>
  <div id="page-export-comparative">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon>
        {{ dataPage.title }}
      </h3>

      <div>
        <button class="btn btn-outline-danger mt-2" v-on:click="resetConf">
          Réinitialiser
        </button>
      </div>
    </div>

    <hr />

    <div class="mt-3 row">
      <div class="col-12 col-md-4 col-lg-3 p-0 pl-3 mb-2 mx-auto">
        <div class="p-2">
          <div>
            <div class="form-group">
              <Datepicker
                  input-class="w-100 date-select"
                  id="date-start"
                  v-model="datepickerOptions.date"
                  :format="formatDatepicker"
                  :minimumView="'month'"
                  :maximumView="'month'"
                  :language="datepickerOptions.language"
                  @input="datepickerOptions.date = fixDate($event)"
                  placeholder="Choisir une période"
              >
              </Datepicker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2" v-if="datepickerOptions.date">
      <b-card no-body>
        <b-tabs card v-model="tabs.activeComparativeTab" v-on:changed="changeActiveComparativeTab">
          <b-tab active>
            <template #title>
              {{ tabs.firstComparativeTabTitle }}
            </template>
            <b-card-text>
              {{/* TAB COMPARATIVE */}}
              <TabComparative
                  @emit-change-shop="changeShop"
                  @emit-new-shop-on-changed="newShopOnChanged"
                  @emit-new-tab-comparative="newTabComparative"
              />
            </b-card-text>
          </b-tab>
          <b-tab v-for="(j, index) in tabs.listTabComparative" :key="'dyn-tab-comparative-' + j" :title="'Comparaison n° ' + (index + 1)">
            {{/* TAB COMPARATIVE */}}
            <TabComparative
                @emit-change-shop="changeShop"
                @emit-new-shop-on-changed="newShopOnChanged"
                @emit-new-tab-comparative="newTabComparative"
            />
            <b-button
                size="sm"
                variant="warning"
                class="float-right mb-2"
                @click="closeTabComparative(j)"
                v-if="tabs.counterComparative >= 1"
            >
              Supprimer la comparaison
              <!--<b-icon class="" icon="x" font-scale="1.5" variant="light"></b-icon>-->         </b-button>
          </b-tab>

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-end>
            <b-nav-item role="presentation" @click.prevent="newTabComparative" href="#"><b>+</b></b-nav-item>
          </template>
        </b-tabs>
      </b-card>

      <div class="mt-2 d-flex justify-content-end" v-if="listComparative.length >= 1">
        <button class="btn btn-success" v-on:click="validateComparative">Valider le bilan Comparatif</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import exportService from '../../../services/ExportService'
import {fr} from 'vuejs-datepicker/dist/locale'
import axios from 'axios'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      dataPage: {
        title: 'Export(s) Bilan Comparatif',
      },
      datepickerOptions: {
        date: null,
        formatDate: 'MM/yyyy',
        language: fr
      },
      tabs: {
        counterComparative: 0,
        activeComparativeTab: 0,
        firstComparativeTabTitle: 'Première Comparaison',
        listTabComparative: [],
      },
      listComparative: []
    }
  },
  methods: {
    formatDatepicker(date) {
      return moment(date).format('MMMM yyyy');
    },
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- RESET CONF ------------------*/
    resetConf() {
      this.$swal({
        title: 'ATTENTION !',
        text: 'En validant vous allez perdre toute vos modifications !',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          Object.assign(this.$data, this.$options.data())
        }
      })
    },
    /*----------------- VALIDATE COMPARATIVE ------------------*/
    async validateComparative() {
      let model = {
        date: this.datepickerOptions.date,
        data: this.listComparative
      }

      await exportService.sendListExportComparative(model)
        .then((response) => {
          this.hideLoader()
          const fileName = 'comparatif_' + moment().format('MM-YY')
          exportService.triggerFileDownload(response.data, fileName, 'application/pdf')

        })
        .catch((error) => {
          this.hideLoader()

          this.$bvToast.toast(error.message, {
            title: 'ERREUR !',
            variant: 'danger',
            solid: true
          })
        })
    },
    /*----------------- TABS ------------------*/
    newTabComparative(model) {
      this.tabs.listTabComparative.push(this.tabs.counterComparative ++)

      if (model.listComparative) {
        this.formatListComparative(model)
      }
    },
    changeActiveComparativeTab() {
      this.tabs.activeComparativeTab = this.tabs.listTabComparative.length
    },
    closeTabComparative(index) {
      for (let i = 0; i < this.tabs.listTabComparative.length; i++) {
        if (this.tabs.listTabComparative[i] === index) {
          this.tabs.listTabComparative.splice(i, 1)
          this.listComparative.splice(index+1, 1);
        }
      }
      this.tabs.counterComparative --
    },
    /*----------------- EMIT CHANGE SHOP (UPDATE LIST COMPARATIVE) ------------------*/
    changeShop(model) {
      let findCatInListComparative = this.listComparative.find(obj => obj.cat === model.cat)
      let findShopToChange = findCatInListComparative.list.find(obj => obj.shop === model.oldShop.id)
      if (findShopToChange) {
        let indexInList = findCatInListComparative.list.findIndex(obj => obj.shop === model.oldShop.id)
        if (indexInList > -1) {
          findCatInListComparative.list.splice(indexInList, 1)
        }
      }
    },
    newShopOnChanged(model) {
      let findCatInListComparative = this.listComparative.find(obj => obj.cat === model.cat)
      findCatInListComparative.list.push({
        center: model.center,
        shop: model.newShop
      })
    },
    /*----------------- PRIVATE FUNCTION ------------------*/
    fixDate(event) {
      return moment(event).format('YYYY-MM-') + moment(event).daysInMonth()
    },
    formatListComparative(model) {
      if (!model.isManual) {
        this.listComparative.push(model)
      }
    }
  },
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule']),
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    Datepicker: () => import('vuejs-datepicker'),
    TabComparative: () => import('../../../components/exportComparative/tabComparative')
  }
}
</script>

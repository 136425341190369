import PodiumService from '../../services/PodiumService'

export default {
    state: {
        medals: [],
    },
    mutations: {
        SET_MEDALS(state, medals) {
            state.medals = medals
        },
    },
    actions: {
        /**
         * Fetch in state list of flux
         *
         * @param commit
         * @param routeParams
         */
        fetchMedals({commit}, routeParams) {
            return PodiumService.getMedals(routeParams)
                .then(response => {
                    commit('SET_MEDALS', response.data)

                    return Promise.resolve(response.data)
                })
                .catch(error => {
                    throw error.toJSON();
                })
        }
    },
    getters: {
        getMedals: state => {return state.medals}
    }
}

import axios from 'axios'

const baseAPIUrl = process.env.VUE_APP_API_URL
const baseAPIPublicUrl = process.env.VUE_APP_PUBLIC_API_URL

import store from '../store/index'
import { BToast } from 'bootstrap-vue'

export default {
  /**
   * Call API for all request
   *
   * @returns {*}
   */
  callApi() {
    return axios
      .create({
        baseURL: baseAPIUrl,
        withCredentials: false,
        validateStatus: function (status) {

          return handleAxiosResponseStatus(status)

        },
        headers: {
          common: {
            Accept: 'application/json'
          },
          patch: {
            'Content-Type': 'application/json'
          },
          Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
        }
    })
  },
  callPdfDownloadApiRoute() {
    return axios.create({
      baseURL: baseAPIUrl,
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      },
      validateStatus: function (status) {
        return handleAxiosResponseStatus(status)
      }
    })
  },
  callCsvDownloadApiRoute() {
    return axios.create({
      baseURL: baseAPIUrl,
      responseType: 'blob',
      headers: {
        Accept : "text/csv; charset=utf-8",
        "Content-Type": "text/csv; charset=utf-8",
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      },
      validateStatus: function (status) {
        return handleAxiosResponseStatus(status)
      }
    })
  },
  /**
   * Call API for all request Public
   *
   * @returns {*}
   */
  callApiPublic() {
    return axios
      .create({
        baseURL: baseAPIPublicUrl,
        withCredentials: false,
        validateStatus: function (status) {
          return handleAxiosResponseStatus(status)
        },
        headers: {
          common: {
            Accept: 'application/json'
          },
          patch: {
            'Content-Type': 'application/json'
          }
        }
      })
  },
  /**
   * Get user token
   *
   * @param data
   * @returns {*}
   */
  getToken(data) {
    return axios.post(baseAPIUrl + '/login_check', data)
  }
}

function handleAxiosResponseStatus(status) {
  if(status === 400) { // ERROR 400
    return axios.Cancel
  } else if(status === 401) { // ERROR 401
    // Disconnect
    store.dispatch('logout', true)
    window.location.href = 'login'

    return axios.Cancel
  } else if(status === 500) { // ERROR 500
    let toaster = new BToast()

    toaster.$bvToast.toast('Une erreur est survenue !', {
      title: 'Attention',
      toaster: 'b-toaster-top-right',
      solid: true,
      appendToast: false,
      variant: 'danger',
    })
  }

  return status >= 200 && status < 300 // default
}
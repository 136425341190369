<template>
  <div id="calendar-events">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{/* HEADING PAGE */}}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="calendar4-range" font-scale="1.5" variant="primary"></b-icon>
        Liste des événements N-1
      </h3>

      <div class="d-flex">
        <Legend></Legend>

        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'calendar-index' }"
          >
            Retour au calendrier
          </router-link>
        </div>
      </div>
    </div>

    <hr />

    {{/* HEADER ACTION */}}
    <div class="header-action d-flex justify-content-between">
      <div class="button-period">
        <button
            v-on:click="changeMonth(0)"
            class="btn btn-dark"
            :class="(period.indexMonth === 0) ? 'disabled' : ''"
        >
          Date N-1 Initial
        </button>
      </div>
      <h3 class="text-capitalize">{{ period.dateStr }}</h3>

      <Datepicker
          @selected="clickDatePicker"
          class="mt-1 mr-2 mr-md-0"
          id="datepicker-events"
          :format="formatDatepicker"
          :minimumView="'month'"
          :maximumView="'month'"
          :language="datepicker.language"
          :value="datepicker.defaultDate"
          input-class="text-center date-select"
      ></Datepicker>
    </div>

    {{/* BLOCK EVENT */}}
    <div v-if="events.length > 0">
      <div v-for="(event, index) in events" :key="index">
        <div class="card mt-2 app-box-shadow">
          <h5 class="text-main-color pl-3 pt-3 font-weight-bold">{{ event.title }} :</h5>
          <div class="card-body p-3 d-flex">
            <span
                class="circle-legend-color mr-4"
                :style="{backgroundColor: (event.eventType.slug === 'center') ? event.center.color : event.eventType.color }">

            </span>

            <div>
              Du <b>{{ event.startDate|moment('dddd DD MMMM YYYY') }}</b>
              au <b>{{ event.endDate|moment('dddd DD MMMM YYYY') }}</b>
            </div>

            <span class="ml-2 mr-2">|</span>

            <div class="type-data d-flex">
              <b-icon class="ml-2" icon="tag" font-scale="1.5" variant="dark"></b-icon>
              <div class="ml-2">
                Type d'événement :
                <span :style="{color: (event.eventType.slug === 'center') ? event.center.color : event.eventType.color }">
                  {{ (event.eventType) ? event.eventType.name : '' }}
                </span>
              </div>
            </div>

            <div class="type-data d-flex" v-if="event.center">
              <span class="ml-2 mr-2">|</span>
              <b-icon class="ml-2" icon="geo-alt" font-scale="1.5" variant="dark"></b-icon>
              <div class="ml-2">Centre : <b>{{ event.center.name }}</b></div>
            </div>

            <div class="shops-data d-flex" v-if="event.shops && event.shops.length > 0">
              <span class="ml-2 mr-2">|</span>
              <b-icon class="mr-2" icon="shop" font-scale="1.5" variant="dark"></b-icon> Magasin(s) :
              <div class="ml-2" v-for="(shop, index) in event.shops" :key="index">
                <b-badge class="p-2 c-pointer ml-2" variant="dark">
                  {{ shop.name }} | {{ shop.center.name }}
                </b-badge>
              </div>
            </div>

            <span class="ml-2 mr-2">|</span>

            <div class="comment-data d-flex">
              <b-icon class="ml-2" icon="chat-square" font-scale="1.5" variant="dark"></b-icon>
              <div class="ml-2">Commentaire : <b>{{ (event.comment) ? event.comment : '' }}</b></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-else>
      <div class="card">
        <div class="card-body text-center h-25">Aucun événements</div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Legend: () => import('@/components/calendarEvent/Legend'),
    Datepicker: () => import('vuejs-datepicker'),
    Loading: () => import('vue-loading-overlay')
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      period: {
        oneLessYear: null,
        date: {
          start: null,
          end: null,
        },
        currentMonth: null,
        dateStr: null,
        indexMonth: 0
      },
      datepicker: {
        language: fr,
        defaultDate: null,
        year: null,
        disabledDates: {
          from: new Date(new Date().getFullYear() -1, 0, 1),
        }
      },
      events: [],
    }
  },
  mounted() {
    // Get date One less year
    this.period.oneLessYear = moment(this.$route.params.date).subtract(1, 'year')
    this.preparePeriod(this.period.oneLessYear.format('YYYY-MM-DD'), 0)

    // Set default value for datepicker
    this.datepicker.defaultDate = this.handleDate(1, 'YYYY-MM')
    this.datepicker.year = Number(this.handleDate(1, 'YYYY-MM'))

    // Fetch Events
    this.$store.dispatch('fetchEvents', { period: this.period })
      .then(response => {
        this.events = response
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- DATE ------------------*/
    handleDate($yearToSubtract, format)
    {
      let currentDate = moment().format(format)
      let newDate = moment(currentDate).subtract($yearToSubtract, 'year')

      return newDate.format(format)
    },
    /*----------------- DATEPICKER ------------------*/
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      return moment(date).format('MMMM yyyy');
    },
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value)
    {
      // Pass str to date Obj
      var dateObj = new Date(value)

      this.showLoader()

      this.period.date.start = moment(dateObj).startOf('month').add(this.period.indexMonth, 'month').format('YYYY-MM-DD')
      this.period.date.end = moment(dateObj).endOf('month').add(this.period.indexMonth, 'month').format('YYYY-MM-DD')
      this.period.dateStr = moment(dateObj).add(this.period.indexMonth, 'month').format('MMMM YYYY')

      // Set default value for datepicker
      this.datepicker.defaultDate = moment(dateObj).startOf('month').add(this.period.indexMonth, 'month').format('YYYY-MM-DD')
      this.datepicker.year = Number(moment(dateObj).startOf('month').add(this.period.indexMonth, 'month').format('YYYY'))

      // Fetch Events
      this.$store.dispatch('fetchEvents', { period: this.period })
        .then(response => {
          this.events = response
          this.hideLoader()
        })
    },
    /*----------------- PERIOD ------------------*/
    preparePeriod(date, indexMonth) {
      this.period.date.start = moment(date).startOf('month').add(indexMonth, 'month').format('YYYY-MM-DD')
      this.period.date.end = moment(date).endOf('month').add(indexMonth, 'month').format('YYYY-MM-DD')
      this.period.dateStr = moment(date).add(indexMonth, 'month').format('MMMM YYYY')
    },
    changeMonth(indexMonth) {
      this.preparePeriod(this.period.oneLessYear, indexMonth)

      // Fetch Events
      this.$store.dispatch('fetchEvents', { period: this.period })
        .then(response => {
          this.events = response
        })
    },
  },
  computed: {
  },
}
</script>

import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get sale rating
   *
   * @returns {*}
   */
  getSaleRating(model) {
    return AxiosService.callApi().get('/' + model.centerId + '/sale/rating/' + model.shopId + '/' + model.month + '/' + model.year)
  },
  /**
   * Create sale rating
   *
   * @param model
   * @returns {*}
   */
  create(model) {
    return AxiosService.callApi().post('/' + model.centerId + '/sale/rating', model.ratingModel)
  },
  /**
   * Edit sale rating
   *
   * @param model
   * @returns {*}
   */
  edit(model) {
    return AxiosService.callApi().put('/' + model.centerId + '/sale/rating/' + model.ratingModel.id, model.ratingModel)
  },
}

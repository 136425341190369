<template>
  <div class="container" id="page-error">
    <div class="row h-100">
      <div class="col-8 mx-auto my-auto text-center">
        <h1 class="pt-5 pb-5">Une erreur est survenue, merci de contacter un administrateur !</h1>

        <div class="text-center mt-3 mb-3">
          <a class="m-auto" href="/">Retour à la page de connexion ?</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Error'
}

</script>

<template>
  <div id="page-user-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block">
      <div class="p-3 d-md-flex justify-content-between align-items-center">
        <div class="w-100">
          <h5 class="w-100 mx-auto text-center text-primary"><strong>{{ dataPage.title }}</strong></h5>
        </div>
        <div>
          <router-link
              class="btn btn-outline-primary mt-2 mt-md-0"
              :to="{ name: 'user-list' }"
          >
            Retour
          </router-link>
        </div>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudUser
        :user="user"
        :actionForm="'create'"
        :selected-shop="0"
        from="crud"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataPage: {
        title: 'Créer un utilisateur'
      },
      user: {
        isActive: true
      }
    }
  },
  components : {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>

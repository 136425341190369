<template>
  <div id="page-shop-edit">
    {{ /* Heading page */ }}
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <div class="header-pages-block app-box-shadow mx-auto bg-white">
        <div class="p-3 d-md-flex justify-content-between">
          <div class="w-100">
            <h5 class="w-100 mx-auto text-center mb-4 text-primary"><strong>{{ ShopModule.shop.name }}</strong></h5>
            <h6 class="text-main-color"><strong>Centre :</strong> {{ ShopModule.shop.center.name }}</h6>
            <h6 class="text-main-color"><strong>Catégorie :</strong> {{ ShopModule.shop.category.name }}</h6>
          </div>
          <div>
            <router-link
                class="btn btn-outline-primary mt-2 mt-md-0"
                :to="{ name: 'shop-list' }"
            >
              Retour
            </router-link>
          </div>
        </div>
      </div>

      {{ /* Form component */ }}
      <FormCrudShop
          :shop="ShopModule.shop"
          :id="ShopModule.shop.id"
          :actionForm="'edit'"
          :selected-company="getSelectedCompany"
          :selected-category="getSelectedCategory"
          :selected-center="getSelectedCenter"
          :selected-date-limit-sale="getSelectedDateLimitSale"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        isLoading: false,
      },
      dataPage: {
        title: 'Edition du commerce : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchShop', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    /**
     * Set var selectedCompany
     *
     * @returns {*|number}
     */
    getSelectedCompany()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.company) ? this.ShopModule.shop.company.id : null
    },
    /**
     * Set var selectedCategory
     *
     * @returns {*|number}
     */
    getSelectedCategory()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.category) ? this.ShopModule.shop.category.id : null
    },
    /**
     * Set var selectedCenter
     *
     * @returns {*|number}
     */
    getSelectedCenter()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.center) ? this.ShopModule.shop.center.id : null
    },
    /**
     * Set var selectedDateLimitSale
     *
     * @returns {*|number}
     */
    getSelectedDateLimitSale()
    {
      return (this.ShopModule.shop && this.ShopModule.shop.dateLimitSale) ? this.ShopModule.shop.dateLimitSale : null
    },
    ...mapState(['ShopModule'])
  },
  components: {
    FormCrudShop: () => import('@/components/pages/shop/FormCrudShop'),
  }
}
</script>

<template>
  <div id="page-export-marketing">
    {{ /* Loader */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-arrow-down" font-scale="1.5" variant="primary"></b-icon>
        {{ dataPage.title }}
      </h3>

      <div>
        <button class="btn btn-outline-danger mt-2" v-on:click="resetConf">
          Réinitialiser
        </button>
      </div>
    </div>

    <hr />

    {{/* TABS */}}
    <b-card no-body>
      <b-tabs card>
        {{/* TAB PERIOD */}}
        <b-tab :active="tabIndex === 'tab-period'">
          <template #title>
            Périodes
          </template>

          <div class="d-flex">
            <div class="col-6">
              <FormPeriod
                  :title="config.periods.first.title"
                  :is-first-period="true"
                  @emit-on-change-start-date="onChangePeriodStartDate"
                  @emit-on-change-end-date="onChangePeriodEndDate"
              />
            </div>
            <div class="col-6">
              <FormPeriod
                  :title="config.periods.second.title"
                  :is-first-period="false"
                  @emit-on-change-start-date="onChangePeriodStartDate"
                  @emit-on-change-end-date="onChangePeriodEndDate"
              />
            </div>
          </div>
        </b-tab>
        {{/* TAB TYPE EXPORT */}}
        <b-tab
            v-if="config.periods.first.dates.end && config.periods.second.dates.end"
            :active="tabIndex === 'tab-type-export'"
        >
          <template #title>
            Type d'export
          </template>

          {{/* SELECT TYPE EXPORT */}}
          <div class="d-flex justify-content-between align-items-center mt-2 p-3">
            <div class="col-4 p-0">
              <SelectTypeExport @emit-on-change-select-type="onChangeSelect"/>
            </div>
            <div class="col-6 p-0" v-if="config.selectTypeExport === 'center'">
              <SelectCenter class="ml-4" @emit-select-center="onChangeSelectCenter"/>
            </div>
            <div class="col-6 p-0" v-if="config.selectTypeExport === 'category'">
              <SelectCategory class="ml-4" @emit-select-category-center="onChangeSelectCategoryCenter" @emit-select-category="onChangeSelectCategory"/>
            </div>
            <div class="col-6 p-0" v-if="config.selectTypeExport === 'shop'">
              <SelectShop class="ml-4" @emit-list-shops="emitListShops"/>
            </div>
          </div>
        </b-tab>
        {{/* TAB TYPE CA */}}
        <b-tab
            v-if="(config.selectCenter || config.selectCategory.category || config.listShop.length > 0)  && areExportFieldsSet"
            :active="tabIndex === 'tab-type-export-sales'"
        >
          <template #title>
            Type de CA
          </template>

          {{/* SELECT TYPE CA */}}
          <div class="col-6 p-0">
            {{/* SELECT TYPE CA */}}
            <div class="card p-1 card-border mt-4">
              <div class="card-body">
                <select class="form-control" v-model="config.selectTypeSales">
                  <option disabled :selected="!config.selectTypeSales" :value="null">Choisir un type de CA</option>
                  <option
                      v-for="(obj, index) in config.listSelectTypeSales" :key="index"
                      class="d-flex p-2 c-pointer"
                      :value="obj.slug"
                  >
                    {{ obj.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end w-100" v-if="config.selectTypeSales">
            <button class="btn btn-success" v-on:click="validateConf">
              Exporter
            </button>
          </div>

        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ExportService from '@/services/ExportService'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
        color: '#28a745'
      },
      dataPage: {
        title: 'Export(s) Marketing'
      },
      tabIndex: 'tab-period',
      isReset: false,
      config: {
        periods: {
          first: {
            title: 'Période A',
            dates: {
              start: null,
              end: null,
              year: null
            }
          },
          second: {
            title: 'Période B',
            dates: {
              start: null,
              end: null,
              year: null
            }
          }
        },
        selectTypeExport: null,
        selectCenter: null,
        selectCategory: {
          category: null,
          isParent: null,
          filter: null
        },
        listShop: [],
        listSelectTypeIsMonthlySales: [
          { name: 'Mensuel', slug: 'monthly' },
          { name: 'Journalier', slug: 'daily' },
        ],
        selectTypeIsMonthlySales: null,
        listSelectTypeSales: [
          { name: 'CA Brut', slug: 'gross' },
          { name: 'CA Comparable', slug: 'compare' }
        ],
        selectTypeSales: null,
      },
      areCompareDatesSet: false,
      areExportFieldsSet: false
    }
  },
  methods: {
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    resetConfigFields() {
      this.config.listShop = []
      this.config.selectCategory = {
        category: null,
        isParent: null,
        filter: null
      }
      this.config.selectCenter = null
    },
    /*----------------- ON CHANGE PERIOD ------------------*/
    onChangePeriodStartDate(model)
    {
      if (model.isFirstPeriod) {
        this.config.periods.first.dates.start = model.startDate
      } else {
        this.config.periods.second.dates.start = model.startDate
      }
      this.areCompareDatesSet = !!(this.config.periods.first.dates.year && this.config.periods.second.dates.year)
    },
    onChangePeriodEndDate(model)
    {
      if (model.isFirstPeriod) {
        this.config.periods.first.dates.end = model.endDate
        this.config.periods.first.dates.year = model.year
      } else {
        this.config.periods.second.dates.end = model.endDate
        this.config.periods.second.dates.year = model.year
        this.tabIndex = 'tab-type-export'
      }
      this.areCompareDatesSet = !!(this.config.periods.first.dates.year && this.config.periods.second.dates.year)
    },
    /*----------------- EMIT ON CHANGE SELECT TYPE ------------------*/
    onChangeSelect($event)
    {
      this.resetConfigFields()
      this.config.selectTypeExport = $event
    },
    /*----------------- EMIT ON CHANGE SELECT CENTER ------------------*/
    onChangeSelectCenter($event)
    {
      // Reset
      this.resetConfigFields()

      this.config.selectCenter = $event.target.value
      this.areExportFieldsSet = this.checkIfExportFieldsSetAreSet()
      this.tabIndex = 'tab-type-export-sales'
    },
    /*----------------- EMIT ON CHANGE SELECT CATEGORY ------------------*/
    onChangeSelectCategory(model)
    {
      // Reset
      this.resetConfigFields()

      this.config.selectCategory.category = model.category
      this.config.selectCategory.isParent = model.isParent
      this.areExportFieldsSet = this.checkIfExportFieldsSetAreSet()
      this.tabIndex = 'tab-type-export-sales'
    },
    onChangeSelectCategoryCenter(event) {
      this.config.selectCategory.filter = event.center
    },
    /*----------------- EMIT LIST SHOPS ------------------*/
    emitListShops(listShop)
    {
      this.$bvToast.toast('Liste des magasins mise à jour', {
        title: 'Changement de la liste magasin',
        variant: 'info',
        solid: true
      })
      // Reset
      this.config.selectCenter = null
      this.resetConfigFields()

      this.config.listShop = listShop
      this.tabIndex = 'tab-type-export-sales'
      this.areExportFieldsSet = this.checkIfExportFieldsSetAreSet()
    },
    /*----------------- VALIDATE CONF ------------------*/
    validateConf()
    {
      this.showLoader()
      ExportService.exportMarketingXlsx(this.prepareModelForPostRequest())
        .then(() => {
          this.hideLoader()
        })
        .catch(() => {
          this.hideLoader()
        })
    },
    prepareModelForPostRequest()
    {
      let model = {
        period: {
          date: this.config.periods.first.dates,
          comparativeDate: this.config.periods.second.dates
        },
        typeExport: this.config.selectTypeExport,
        typeIsMonthlySales: (this.config.selectTypeIsMonthlySales === 'monthly'),
        typeSales: this.config.selectTypeSales,
        entity: null,
        listShops: null
      }
      if (this.config.selectCenter) {
        model.entity = parseInt(this.config.selectCenter)
      } else if (this.config.selectCategory.category) {
        model.entity = this.config.selectCategory
      } else if (this.config.listShop.length > 0) {
        model.listShops = this.config.listShop
      }

      return model
    },
    /*----------------- VALIDATE CONF ------------------*/
    resetConf()
    {
      // Reload page
      window.location.reload()
    },
    checkIfExportFieldsSetAreSet() {
      return (
          this.config.selectTypeExport &&
          (
              (this.config.selectCategory && this.config.selectCategory.category) ||
              this.config.selectCenter ||
              this.config.listShop.length > 0
          )
      );
    }
  },
  created() {
  },
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule']),
    ...mapState(['ExportModule']),
    ...mapState(['UserModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormPeriod: () => import('../../../components/exportMarketing/FormPeriod'),
    SelectTypeExport: () => import('../../../components/exportMarketing/SelectExportType'),
    SelectCenter: () => import('../../../components/exportMarketing/SelectCenter'),
    SelectCategory: () => import('../../../components/exportMarketing/SelectCategory'),
    SelectShop: () => import('../../../components/exportMarketing/SelectShops')
  }
}
</script>

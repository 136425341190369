// JWT DECODE
import VueJwtDecode from 'vue-jwt-decode'
// SERVICE
import AxiosService from '@/services/AxiosService'
import UserService from '@/services/UserService'

export default {
  state: {
    token: null,
    currentUser: null,
    currentCenter: null
  },
  mutations: {
    SET_USER_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('auth.userToken', token)
    },
    SET_CURRENT_USER(state, data) {
      state.currentUser = data
      setLocalState('auth.user', data)
    }
  },
  actions: {
    login({ state, commit, dispatch }, { username, password }) {
      var data = {
        username: username,
        password: password
      }

      // Call axios
      return AxiosService.getToken(data)
        .then((response) => {
          // Set in store and local storage token
          commit('SET_USER_TOKEN', response.data.token)
          // Destroy temp user
          commit('SET_TEMP_USER', null)

          // Get user ID from token
          var dataToken = VueJwtDecode.decode(state.token)

          // Get user form token
          return Promise.resolve(dispatch('getUserFromToken', dataToken))
        })
        .catch((error) => {
          if (error && error.code === 401) {
            commit('SET_USER_TOKEN', null)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error.response.data)
        })
    },
    /**
     * Fetch in state a user
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCurrentUser({ commit }, id)
    {
      UserService.getUser(id)
        .then(response => {
          commit('SET_CURRENT_USER', response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Get user by token (id)
     *
     * @param commit
     * @param dataToken
     * @returns {*}
     */
    getUserFromToken({commit}, dataToken)
    {
      // Call axios (user)
      return UserService
        .getUser(dataToken.id)
        .then((response) => {
          if(response.data.roles[0] === 'ROLE_SHOP'){
            commit('SET_CURRENT_USER', null)
          } else {
            commit('SET_CURRENT_USER', response.data)
          }
        })
        .catch((error) => {
          if (error && error.code === 401) {
            commit('SET_CURRENT_USER', null)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error)
        })
    },
    // Logs out the current user.
    logout({ commit }, isRedirect) {
      commit('SET_USER_TOKEN', null)
      commit('SET_CURRENT_USER', null)

      // Redirect login
      if(isRedirect) {
        window.location.href = 'login'
      }
      // Clear local storage
      window.localStorage.clear()

      // Clear all state
      if(isRedirect) {
        let clearState = {};
        Object.keys(this.$state).forEach(key => {
          clearState[key] = null
        });
        this.$store.replaceState(clearState)
      }
    }
  },
  getters: {
    /**
     * Whether the user is currently logged in.
     *
     * @param state
     * @returns {boolean}
     */
    loggedIn(state) {
      return !!state.currentUser
    },
    /**
     * Get current user
     *
     * @param state
     * @returns {null}
     */
    getCurrentUser(state) {
      return state.currentUser
    },
    /**
     * check if user is admin
     *
     * @param state
     * @returns {boolean}
     */
    userIsAdmin(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_ADMIN');
    },
    /**
     * check if user has role marketing
     *
     * @param state
     * @returns {boolean}
     */
    userIsMarketing(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_MARKETING');
    }
  }
}

// ===
// Private helpers
// ===

/**
 * Set local state
 *
 * @param key
 * @param state
 */
function setLocalState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

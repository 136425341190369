import CnccService from '../../services/CnccService'

export default {
  state: {
    cncc: [],
    cnccCumulative: {},
    csvUrl: null
  },
  mutations: {
    SET_CNCC(state, cncc) {
      state.cncc = cncc
    },
    SET_CNCC_CUMULATIVE(state, cnccCumulative) {
      state.cnccCumulative = cnccCumulative
    },
    SET_CSV_URL(state, csvUrl) {
      state.csvUrl = csvUrl
    }
  },
  actions: {
    /**
     * Fetch in state list of Cncc
     *
     * @param commit
     * @param routeParams
     */
    fetchCncc({ commit }, routeParams) {
      return CnccService.getCnccPeriod(routeParams)
        .then(response => {
          commit('SET_CNCC', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    },
    /**
     * Fetch in state Cncc cumulative
     *
     * @param commit
     * @param routeParams
     */
    fetchCnccCumulative({ commit }, routeParams) {
      return CnccService.getCnccCumulativePeriod(routeParams)
          .then(response => {
            commit('SET_CNCC_CUMULATIVE', response.data)

            return Promise.resolve(response.data)
          })
          .catch(error => {
          })
    },
    /**
     * Create or update Cncc
     *
     * @param commit
     * @param model
     */
    createOrUpdateCncc({ commit }, model)
    {
      if(model.userId !== null) {
        return CnccService.create(model)
          .then((response) => {
            // Check if has errors on form
            if(response.data.error) {
              return Promise.reject(response.data.error)
            }

            commit('SET_CNCC', model)
            return Promise.resolve()
          })
      }
    },
    csvCncc({ commit }, model) {
      return CnccService.csvCncc(model)
        .then((response) => {
          commit('SET_CSV_URL', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    }
  },
  getters: {
    getCountCncc: state => {
      return state.cncc.length
    }
  }
}

<template>
  <div id="page-user-edit">
    <div class="loader-spinner d-flex justify-content-center flex-column align-items-center text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      {{ /* Heading page */ }}
      <div class="header-pages-block app-box-shadow col-10 mx-auto bg-white">
        <div class="p-3 d-md-flex justify-content-between">
          <div class="w-100">
            <h5 class="w-100 mx-auto text-center text-primary"><strong>{{ UserModule.user.firstname }} {{ UserModule.user.lastname }} </strong></h5>
            <h6 class="text-main-color" v-if="UserModule.user.shop"><strong>Commerce :</strong> {{ UserModule.user.shop.name }}</h6>
          </div>
          <div>
            <router-link
                class="btn btn-outline-primary mt-2 mt-md-0"
                :to="{ name: 'user-list' }"
            >
              Retour
            </router-link>
          </div>
        </div>
      </div>

      {{ /* Form component */ }}
      <FormCrudUser
          :user="UserModule.user"
          :id="UserModule.user.id"
          :actionForm="'edit'"
          :selected-shop="getSelectedShop"
          :selected-center="getSelectedCenter"
          from="crud"
      />
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      dataPage: {
        title: 'Edition de l\'utilisateur : ',
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  async created() {
    this.showLoader()

    await this.$store.dispatch('fetchUser', this.$route.params.id)
      .then(() => {
        setTimeout(function () { this.hideLoader() }.bind(this), 250)
      })
  },
  computed: {
    /**
     * Set var selectedShop
     *
     * @returns {*|number}
     */
    getSelectedShop()
    {
      return (this.UserModule.user && this.UserModule.user.shop) ? this.UserModule.user.shop.id : null
    },
    getSelectedCenter()
    {
      return (this.UserModule.user && this.UserModule.user.center) ? this.UserModule.user.center.id : null
    },
    ...mapState(['UserModule'])
  },
  components: {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>

import AxiosService from '@/services/AxiosService'
import UrlBuilder from '@/helper/urlBuilder'

export default {
  /**
   * Get list of user
   *
   * @param centerId => center
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getUsers(centerId, perPage, page, order, search) {
    return AxiosService.callApi().get(UrlBuilder('users', centerId, {
      perPage : perPage,
      page: page,
      order: order,
      search: search
    }))
  },
  /**
   * Get user by id
   *
   * @param userId
   * @returns {*}
   */
  getUser(userId) {
    return AxiosService.callApi().get('/users/' + userId)
  },
  /**
   * Create User
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/users', data)
  },
  /**
   * Edit User
   *
   * @param user
   * @returns {*}
   */
  edit(user) {
    return AxiosService.callApi().put('/users/' + user.id, user)
  },
  /**
   * Edit User is Active
   *
   * @param user
   * @returns {*}
   */
  editIsActive(user) {
    return AxiosService.callApi().put('/users/active/' + user.id, user)
  }
}

import CenterService from '@/services/CenterService'

export default {
    state: {
        center: {},
        centers: [],
        total: null,
        totalRequest: null,
    },
    mutations: {
        ADD_CENTER(state, center) {
            state.centers.push(center)
        },
        SET_CENTERS(state, centers) {
            state.centers = centers
        },
        SET_CENTERS_TOTAL(state, total) {
            state.total = total
        },
        SET_CENTERS_TOTAL_REQUEST(state, totalRequest) {
            state.totalRequest = totalRequest
        },
        SET_CENTER(state, center) {
            state.center = center
        }
    },
    actions: {
        /**
         * Fetch in state list of centers
         *
         * @param commit
         * @param perPage
         * @param page
         * @param order
         * @param search
         */
        fetchCenters({commit}, {perPage, page, order, search}) {
            return CenterService.getCenters(
                (perPage) ? perPage : null,
                (page) ? page : null,
                (order) ? order : null,
                (search) ? search : null)
                .then(response => {
                    commit('SET_CENTERS', response.data.list)
                    commit('SET_CENTERS_TOTAL', response.data.total)
                    commit('SET_CENTERS_TOTAL_REQUEST', response.data.totalRequest)

                    return Promise.resolve(response.data.list)
                })
                .catch(error => {
                })
        },
        /**
         * Fetch in state a center
         *
         * @param commit
         * @param getters
         * @param id
         */
        fetchCenter({commit}, id) {
            CenterService.getCenter(id)
                .then(response => {
                    commit('SET_CENTER', response.data)
                })
                .catch((error) => {
                })
        },
        /**
         * Create center
         *
         * @param commit
         * @param center
         */
        createCenter({commit}, center) {
            return CenterService.create(center)
                .then((response) => {
                    // Check if has errors on form
                    if (response.data.errors) {
                        return Promise.reject(response.data.errors)
                    }

                    commit('ADD_CENTER', center)
                    return Promise.resolve()
                })
        },
        /**
         * Update center
         *
         * @param commit
         * @param id
         * @param center
         * @returns {*}
         */
        updateCenter({commit}, {id, center}) {
            return CenterService.edit(id, center)
                .then((response) => {
                    if (response.data.errors) {
                        return Promise.reject(response.data.errors)
                    }

                    commit('SET_CENTER', response.data)
                })
        }
    },
    getters: {
        /**
         * Get center by id in list centers
         *
         * @param state
         * @returns {function(*): *}
         */
        getCenterById: state => id => {
            return state.centers.find(center => center.id === id)
        },
        getAllCenters: state => {
            return state.centers
        }
    }
}

import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get total sales full year on shop
   * @param model
   */
  getTotalSales(model)
  {
    return AxiosService.callApi().get('/sale/certified/totalSales/' + model.shopId + '/' + model.year)
  },
  /**
   * Get sale Certified
   *
   * @returns {*}
   */
  getSaleCertified(model)
  {
    return AxiosService.callApi().get('/sale/certified/' + model.shopId + '/' + model.year)
  },
  /**
   * Create sale Certified
   *
   * @param saleCertified
   * @returns {*}
   */
  create(saleCertified)
  {
    return AxiosService.callApi().post('/sale/certified', saleCertified)
  },
  /**
   * Edit sale Certified
   *
   * @param saleCertified
   * @returns {*}
   */
  edit(saleCertified)
  {
    return AxiosService.callApi().put('/sale/certified/' + saleCertified.shopId, saleCertified)
  },
}

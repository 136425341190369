import AxiosService from '@/services/AxiosService'
import UrlBuilder from '@/helper/urlBuilder'

export default {
  /**
   * Get list of Category
   *
   * @returns {*}
   */
  getCategories() {
    return AxiosService.callApi().get('/categories')
  },
  /**
   * Get parent list Category
   */
  getParentCategories() {
    return AxiosService.callApi().get('/categories/parent')
  },
  /**
   * Get export parent list Category
   */
  getExportParentCategories(centerId) {
    return AxiosService.callApi().get(UrlBuilder('categories/export/parent', centerId, null))
  },
  /**
   * Get category by id
   *
   * @param id
   * @returns {*}
   */
  getCategory(id) {
    return AxiosService.callApi().get('/categories/' + id)
  },
  /**
   * Create category
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/categories', data)
  },
  /**
   * Edit category
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/categories/' + id, data)
  }
}

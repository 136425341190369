import EventService from '@/services/EventService'

export default {
  state: {
    event: {},
    events: [],
    eventTypes: []
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.events.push(event)
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_EVENT(state, event) {
      state.event = event
    },
    DELETE_EVENT(state, index) {
      state.events.splice(state.events.indexOf(index), 1)
    },
    ADD_EVENT_TYPE(state, eventType) {
      state.eventTypes.push(eventType)
    },
    SET_EVENT_TYPES(state, eventTypes) {
      state.eventTypes = eventTypes
    },
  },
  actions: {
    /**
     * Fetch in state list of events
     *
     * @param commit
     * @param params
     */
    fetchEvents({ commit }, params) {
      return EventService.getEvents(params)
        .then(response => {
          commit('SET_EVENTS', response.data)
          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    },
    /**
     * Fetch in state list of event types
     *
     * @param commit
     * @param params
     */
    fetchEventTypes({ commit }, params) {
      return EventService.getEventTypes(params)
        .then(response => {
          commit('SET_EVENT_TYPES', response.data)
          return Promise.resolve(response.data)
        })
        .catch(error => {
        })
    },
    /**
     * Fetch in state a event
     *
     * @param commit
     * @param getters
     * @param eventId
     */
    fetchEvent({ commit }, eventId)
    {
      return EventService.getEvent(eventId)
        .then(response => {
          commit('SET_EVENT', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
        })
    },
    /**
     * Create event
     *
     * @param commit
     * @param model
     */
    createEvent({ commit }, model)
    {
      return EventService.create(model)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_EVENT', model.event)
          return Promise.resolve()
        })
    },
    /**
     * Update dates on move or resize event on calendar
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateDateEvent({ commit }, model) {
      return EventService.changeDateEvent(model)
        .then((response) => {
          commit('SET_EVENT', response.data)

          return Promise.resolve(response.data)
        })
    },
    /**
     * Update event
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateEvent({ commit }, model)
    {
      return EventService.edit(model)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_EVENT', response.data)
        })
    },
    /**
     * Delete event
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    deleteEvent({ commit } , model)
    {
      return EventService.delete(model)
        .then(() => {
          commit('DELETE_EVENT', model.index)
        })
    }
  },
  getters: {
    /**
     * GetEvent by id into list load by fetchEvents
     *
     * @param state
     * @returns {function(*): * | undefined}
     */
    getEventById: state => id => {
      return state.events.find(event => event.id === id)
    }
  }
}
